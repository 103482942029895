import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import EnhetTypeahead from 'components/EnhetTypeahead';
import SlettKjedeModal from './SlettKjedeModal';

import KjedeApi from 'services/KjederegisteretAdminApi/KjedeApi';
import { GetKjedeByIdResponse, UpdateKjedeCommand } from 'services/KjederegisteretAdminApi/KjedeApi/types';

import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';

import Style from './index.module.css';

interface Props {
  kjede: GetKjedeByIdResponse;
  kanLeggedNed: boolean;
  kanSlettes: boolean;
  onKjedeDeleted?: () => void;
}

const KjedeInfo: React.FC<Props> = props => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toaster = useToaster();

  const initialData: UpdateKjedeCommand = {
    id: props.kjede.kjedeID,
    kjedenavn: props.kjede.kjedenavn,
    kortnavn: props.kjede.kortnavn,
    enhetId: props.kjede.enhetID,
    enhetnavn: props.kjede.enhet && props.kjede.enhet.name,
    kjedenr: props.kjede.kjedenr,
  };

  const validate = (values: UpdateKjedeCommand) => {
    const errors: any = {};
    if (!values.kjedenavn) errors.kjedenavn = 'Kjedenavn mangler!';
    if (!values.kortnavn) errors.kortnavn = 'Kortnavn mangler!';
    if (!values.enhetId) errors.enhetId = 'Hovedkontor er ikke satt!';
    return errors;
  };

  const onSubmit = async (command: UpdateKjedeCommand) => {
    try {
      await KjedeApi.updateKjede(command.id, command);
      toaster.success('Suksess!', 'Kjede er oppdatert.');
    } catch (err) {
      toaster.error('Feil', HttpErrorHelper.handleErrorMessage(err));
    }
  };

  const { touched, errors, values, handleChange, handleSubmit, submitDisabled, updateValues, resetForm } = useForm(initialData, validate, onSubmit);

  const onChangeEnhet = (pickedEnhet: { id: number; name: string }) => {
    if (pickedEnhet.id) {
      const copy = { ...values };
      copy.enhetId = pickedEnhet.id;
      copy.enhetnavn = pickedEnhet.name;
      updateValues(copy);
    } else if (props.kjede) {
      const copy = { ...values };
      copy.enhetId = props.kjede.enhetID;
      copy.enhetnavn = props.kjede.enhet && props.kjede.enhet.name;
      resetForm(copy);
    }
  };

  useEffect(() => {
    resetForm(initialData);
  }, [props.kjede]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Hovedkontor (enhet):</Form.Label>
        <EnhetTypeahead showFilters enhettype="HOVEDKONTOR" onChange={e => onChangeEnhet(e)} />
      </Form.Group>
      <Form.Group>
        <Form.Control
          isInvalid={touched.enhetId && errors.enhetId}
          isValid={touched.enhetId && !errors.enhetId}
          type="text"
          value={values.enhetnavn}
          readOnly
        />
        <Form.Control.Feedback type="invalid">{errors.enhetId}</Form.Control.Feedback>
        <Form.Text>
          Gå til enhet:{' '}
          {values.enhetId && values.enhetnavn && (
            <Button
              className={Style.linkToEnhetBtn}
              size="sm"
              variant="link"
              onClick={() => window.open(`enheter/${values.enhetId}`, '_blank')}
            >
              {values.enhetnavn}
            </Button>
          )}
        </Form.Text>
      </Form.Group>
      <hr />
      {values.kjedenr && (
        <Form.Group>
          <Form.Label>Kjedenr</Form.Label>
          <Form.Control name="kjedenr" type="text" value={values.kjedenr} readOnly />
        </Form.Group>
      )}

      <Form.Group>
        <Form.Label>Kjedenavn</Form.Label>
        <Form.Control
          name="kjedenavn"
          type="text"
          autoComplete="false"
          value={values.kjedenavn}
          onChange={e => handleChange('kjedenavn', e.target.value)}
          isInvalid={touched.kjedenavn && errors.kjedenavn}
          isValid={
            touched.kjedenavn && !errors.kjedenavn && values.kjedenavn !== initialData.kjedenavn
          }
        />
        <Form.Control.Feedback type="invalid">{errors.kjedenavn}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Kortnavn</Form.Label>
        <Form.Control
          maxLength={20}
          name="kortnavn"
          type="text"
          value={values.kortnavn}
          onChange={e => handleChange('kortnavn', e.target.value)}
          isInvalid={touched.kortnavn && errors.kortnavn}
          isValid={touched.kortnavn && !errors.kortnavn && values.kortnavn !== initialData.kortnavn}
        />
        <Form.Control.Feedback type="invalid">{errors.kortnavn}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group style={{ marginTop: '1rem' }}>
        <Button
          type="submit"
          disabled={submitDisabled || JSON.stringify(values) === JSON.stringify(initialData)}
        >
          Lagre
        </Button>
        <Button
          variant="outline-danger"
          type="button"
          style={{ marginLeft: '0.5rem' }}
          disabled={!props.kanSlettes}
          title="Hvis kjeden er blitt opprettet ved en feil og det er ikke knyttet enheter til den kan den slettes!"
          onClick={() => setShowDeleteModal(true)}
        >
          Slett
        </Button>
      </Form.Group>

      <SlettKjedeModal
        kjedeId={props.kjede.kjedeID}
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        onKjedeDeleted={props.onKjedeDeleted}
      />
    </Form>
  );
};

export default KjedeInfo;
