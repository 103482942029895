import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button, Col, ListGroup, FormCheck, Row, Modal, Alert, Spinner } from 'react-bootstrap';
import GrupperingPicker from 'components/GrupperingPicker';
import { IMappedGrupperingNode } from 'components/GrupperingPicker/types';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import EnhetsgrupperingApi from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi';
import { GrupperingForMedlemsgruppe } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';
import GrupperingSuggestion from './GrupperingSuggestion';
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';
import DatePicker from 'components/DatePicker';
import useForm from 'utils/hooks/useForm';
import Style from './index.module.css';

interface Props {
  enhetId: number;
  isFetchingGrupperinger: boolean;
  onUpdateEnhet: () => void;
  setIsShowForm: (show: boolean) => void;
  skalBetraktesSomKjedeMedlemskapDefaultChecked: boolean;
  onUpdated: () => void;
}

export interface GrupperingCommand {
  enhetsgrupperingId: number;
  fraOgMedDato: Date;
  kundenr: string;
  skalBetraktesSomKjedemedlemskap: boolean;
  idOver: number;
  suggestions: GrupperingForMedlemsgruppe[];
  label: string;
  nr: number;
  enhetgrupperingType: string;
}

const AddToGrupperingForm: React.FC<Props> = props => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSuggestionHelpText, setShowSuggestionHelpText] = useState<boolean>(false);

  const isMountedRef = useIsMountedRef();
  const toaster = useToaster();
  const initialData: GrupperingCommand[] = [];

  const validate = (values: GrupperingCommand[]) => {
    let errors: any = {};
    return errors;
  };

  const onSubmit = async (values: GrupperingCommand[]) => {
    setIsLoadingSubmit(true);
    const payload = {
      enhetId: props.enhetId,
      grupperinger: values.map(v => ({
        enhetsgrupperingId: v.enhetsgrupperingId,
        fraOgMedDato: v.fraOgMedDato,
        kundenr: v.kundenr,
        skalBetraktesSomKjedemedlemskap: v.skalBetraktesSomKjedemedlemskap,
      }))
    };

    try {
      await EnhetApi.knyttTilFlereEnhetsgrupperinger(props.enhetId, payload);
      toaster.success('Suksess!', 'Enheten er tilknyttet til enhetsgrupperingen!');
      props.onUpdateEnhet();
      props.onUpdated();
      setIsLoadingSubmit(false);
      resetForm(initialData);
      props.setIsShowForm(false);
    } catch (err) {
      setIsLoadingSubmit(false);
      toaster.error('Feil', HttpErrorHelper.handleErrorMessage(err));
    }
  };

  const { values, updateValues, handleSubmit, resetForm } = useForm(
    initialData,
    validate,
    onSubmit,
  );

  const onGrupperingSelectionChanged = useCallback((grupperinger: IMappedGrupperingNode[]) => {
    const indexOfFirstISGruppering = grupperinger.findIndex(o => o.enhetgrupperingType === 'IS');
    const selected: GrupperingCommand[] = grupperinger?.map((x: IMappedGrupperingNode, index: number) => ({
      enhetsgrupperingId: x.id,
      fraOgMedDato: null,
      kundenr: null,
      skalBetraktesSomKjedemedlemskap: props.skalBetraktesSomKjedeMedlemskapDefaultChecked && index === indexOfFirstISGruppering ? true : false,
      suggestions: [],
      idOver: x.idOver,
      label: x.label,
      nr: x.nr,
      enhetgrupperingType: x.enhetgrupperingType
    }));
    updateValues(selected);
  }, []);

  const getSuggestions = async (id: number) => {
    if (id) {
      const res = await EnhetsgrupperingApi.getGrupperingWithMedlemsgruppeById(id);
      if (res.length > 0) {
        const copy = [...values];
        const index = copy.findIndex(x => x.enhetsgrupperingId === id);
        copy[index].suggestions = res;
        updateValues(copy);
      } else {
        const copy = [...values];
        const index = copy.findIndex(x => x.enhetsgrupperingId === id);
        copy[index].suggestions = undefined; // settes til undefined for å motvirke loop på fetch om suggestions er tomt.
        updateValues(copy);
      }
    }
  }

  const handleSuggestionClicked = (selectedId: number, label: string, idOver: number, nr: number, typekode: string) => {
    if (selectedId) {
      if (values.filter(x => x.enhetsgrupperingId === selectedId).length > 0) {
        const newSelected = values.filter(y => y.enhetsgrupperingId !== selectedId);
        updateValues(newSelected);
      } else {
        var betraktSomKjedemedlemskap: boolean = props.skalBetraktesSomKjedeMedlemskapDefaultChecked && (values.filter(x => x.enhetgrupperingType === 'IS').length === 0);
        if (values.filter(z => z.idOver === idOver || z.enhetsgrupperingId === idOver).length > 0) { // hvis allerede valgt med samme idOver.
          const newSelected = values.filter(x => x.idOver !== idOver);
          newSelected.push({ enhetsgrupperingId: selectedId, label: label, suggestions: [], fraOgMedDato: null, kundenr: null, skalBetraktesSomKjedemedlemskap: betraktSomKjedemedlemskap, idOver: idOver, nr: nr, enhetgrupperingType: typekode });
          updateValues(newSelected);
        } else {
          updateValues([...values, { enhetsgrupperingId: selectedId, label: label, suggestions: [], fraOgMedDato: null, kundenr: null, skalBetraktesSomKjedemedlemskap: betraktSomKjedemedlemskap, idOver: idOver, nr: nr, enhetgrupperingType: typekode }]);
        }
      }
    }
  };

  const handleGrupperingAsKjedemedlemskapClicked = (id: number) => {
    const copy = [...values];
    copy.map((x: GrupperingCommand) => x.skalBetraktesSomKjedemedlemskap = false);
    if (id) {
      const index = copy.findIndex(y => y.enhetsgrupperingId === id);
      copy[index].skalBetraktesSomKjedemedlemskap = true;
    }

    updateValues(copy);
  }

  const handleFraOgMedChange = (id: number, date: Date) => {
    const copy = [...values];
    const index = copy.findIndex(x => x.enhetsgrupperingId === id);
    copy[index].fraOgMedDato = date;
    updateValues(copy);
  }

  const handleKundenummerChange = (id: number, value: string) => {
    const copy = [...values];
    const index = copy.findIndex(x => x.enhetsgrupperingId === id);
    copy[index].kundenr = value;
    updateValues(copy);
  }

  useEffect(() => {
    if (isMountedRef.current) {
      values?.map(x => {
        if (x.suggestions?.length < 1 && x.suggestions !== undefined) {
          getSuggestions(x.enhetsgrupperingId);
        }
      });
    }
  }, [values]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <h4>Knytt til enhetsgrupperinger</h4>
      </Row>

      <Row>
        <Form.Group as={Col} controlId="formEnhetgrupperingForeldre">
          <Form.Label>Enhetsgruppering *</Form.Label>
          <GrupperingPicker
            treeSelectMode="hierarchical"
            onSelectionChanged={onGrupperingSelectionChanged}
            disabled={props.isFetchingGrupperinger}
            closeOnSelect={true}
          //disableTopNodes
          />
        </Form.Group>
      </Row>

      {values && (values.filter(x => x.suggestions && x.suggestions.length > 0 &&
        x.suggestions !== undefined).length > 0) && (
          <Alert key={Math.random() + '-alert'} variant="info">
            Valgt enhetgruppering er medlemsgruppe i andre grupperinger ({values.filter(x => x.suggestions).length}).
            <Alert.Link onClick={() => setShowModal(true)}> Se forslag.</Alert.Link>
          </Alert>
        )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Forslag til enhetsgruppering</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            <span>Huk av for grupperinger du ønsker å legge til </span>
            <IcebergIcon cursor='pointer' icon="info" onClick={() => setShowSuggestionHelpText(!showSuggestionHelpText)} />
          </h6>
          {showSuggestionHelpText && (
            <p className="text-muted">
              For å gjøre det enklere å huske på å melde en enhet inn i enhetgruppering,
              får du forslag til enhetsgruppering basert på valgt enhetgruppering (OI).
              Forslag genereres basert på hvilken enhetsgruppering valgt enhetgruppering(OI) er medlemsgruppe i.
              Dersom det finnes en undergruppering med samme navn som valgt enhetgruppering(OI), vises denne først i listen.
            </p>
          )}
          {values.map(gruppering => (
            <GrupperingSuggestion
              key={gruppering.enhetsgrupperingId}
              onSetSelectedGrupperinger={handleSuggestionClicked}
              selected={gruppering}
              selectedIDs={values?.map(x => x.enhetsgrupperingId)}
            />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Lukk
          </Button>
        </Modal.Footer>
      </Modal>

      <Row>
        <Form.Group as={Col} controlId="formGrupperingKnr">
          <ListGroup className={Style.selectedGrupperingFormListGroup}>
            {values.map(x => (
              <ListGroup.Item key={x.enhetsgrupperingId} className={Style.selectedGrupperingFormListGroupItem}>
                <Row>
                  <Col>
                    <Form.Label>{x.label}</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Form.Label>Fra og med dato<span style={{ color: 'red' }}>*</span></Form.Label>
                    <DatePicker
                      selected={x.fraOgMedDato}
                      placeholderText="dd.mm.yyyy"
                      onChange={date => handleFraOgMedChange(x.enhetsgrupperingId, date)}
                      adjustTimeForTimezoneOffset={true}
                    />
                    {!x.fraOgMedDato && (
                      <span className="text-muted">
                        Mangler fra og med dato!
                      </span>
                    )}
                  </Col>
                  <Col md="4">
                    <Form.Label>Medlemsnummer</Form.Label>
                    <Form.Control
                      type="text"
                      name="medlemsnummer"
                      placeholder=""
                      value={x.kundenr || ''}
                      onChange={e => handleKundenummerChange(x.enhetsgrupperingId, e.target.value)}
                    />
                    {x && x.kundenr && (x.kundenr.toString().match(/^[0-9]+$/) === null) && (
                      <span style={{ color: 'red' }}>
                        Kun siffer!
                      </span>
                    )}
                  </Col>
                  {
                    x.enhetgrupperingType === 'IS' &&
                    <Col md="4" className={Style.kjedemedlemskapCol}>
                      <Form.Label>Vis som kjedemedlemskap i fileksport (Gjelder kun 'IS' enhetsgrupperinger)
                      </Form.Label>
                      <FormCheck
                        type="checkbox"
                        className={Style.checkbox}
                        checked={x.skalBetraktesSomKjedemedlemskap}
                        onChange={() => handleGrupperingAsKjedemedlemskapClicked(x.skalBetraktesSomKjedemedlemskap ? undefined : x.enhetsgrupperingId)}
                      />
                      <Form.Text muted>
                        Default avhuket hvis enhet ikke har kjede eller gruppemedlemskap
                      </Form.Text>
                    </Col>
                  }

                </Row>
              </ListGroup.Item>
            ))}

          </ListGroup>
        </Form.Group>
      </Row>

      <Row>
        <Button style={{ width: 'fit-content', margin: '.75em' }} type="submit" disabled={values?.filter(x => !x.fraOgMedDato || (x.kundenr && x.kundenr.toString().match(/^[0-9]+$/) === null)).length > 0 || values.length < 1}>
          {isLoadingSubmit && (
            <Spinner
              style={{ marginRight: '0.5em' }}
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          <span>Lagre</span>
        </Button>
      </Row>

    </Form>
  );
};

export default AddToGrupperingForm;
