import React, { useState, useEffect } from "react";
import { Button, Collapse, Row, Form, InputGroup, OverlayTrigger, Col, Tooltip } from "react-bootstrap";

import cx from "classnames";

import CompareIcon from "../CompareIcon";
import QAAddressMap from "./components/QAAddressMap";

import CopyToClipboardIcon from "components/CopyToClipboardIcon";
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import AddressTypeahead from "components/AddressTypeahead";

import { IAdresse } from 'components/AddressTypeahead/types';

import AdresseApi from "services/KjederegisteretAdminApi/AdresseApi";
import { AddressSearchResult } from "services/KjederegisteretAdminApi/AdresseApi/types";

import { Coordinates } from "models/Coordinates";

import useIsMountedRef from "utils/hooks/useIsMountedRef";
import { formatAddressAsOneLiner } from "utils/AddressHelper";
import { qaCompareSimpleValue } from "../../utils";

import Style from "./index.module.css";

interface Props {
  changeRequestAddress: IAdresse;
  improvedAddress: IAdresse;
  onImprovedAddressChanged: (address: IAdresse) => void;
  isCustomAddress: boolean;
  changeRequestId: string;
  postadresse?: IAdresse;
  fakturaadresse?: IAdresse;
  leveringsadresse?: IAdresse;
  copyPostAdresse?: boolean;
  setCopyPostAdresse?: (checked: boolean) => void;
}

const QAAddressForm: React.FC<Props> = props => {
  const isMountedRef = useIsMountedRef();

  const [addressMapVisible, setAddressMapVisible] = useState(false);
  const [lookupCrResults, setLookupCrResults] = useState<AddressSearchResult[]>([]);
  const [isChangeRequestAddressValid, setIsChangeRequestAddressValid] = useState<boolean>(false);
  const [validatedChangeRequestAddress, setValidatedChangeRequestAddress] = useState<AddressSearchResult>();
  const [isExpandRow, setIsExpandRow] = useState<boolean>(true);
  const [copyTouched, setCopyTouched] = useState<boolean>(false);

  let improvedCoordinates =
    props.improvedAddress && props.improvedAddress?.latitude && props.improvedAddress?.longitude
      ? new Coordinates(props.improvedAddress?.latitude, props.improvedAddress?.longitude)
      : undefined;

  const crCoordinatesValidated =
    validatedChangeRequestAddress && validatedChangeRequestAddress.latitude && validatedChangeRequestAddress.longitude
      ? new Coordinates(validatedChangeRequestAddress.latitude, validatedChangeRequestAddress.longitude)
      : undefined;

  const transferAddress = () => {
    if (props.changeRequestAddress && isChangeRequestAddressValid) {
      setCopyTouched(prev => false);
      props.onImprovedAddressChanged(props.changeRequestAddress);
    }
  };

  const lookupChangeRequestAddress = async (q: string) => {
    try {
      const foundAddresses = await AdresseApi.search(q);
      if (isMountedRef.current) {
        setLookupCrResults(foundAddresses);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (props.changeRequestAddress && isMountedRef.current) {
      lookupChangeRequestAddress(formatAddressAsOneLiner(props.changeRequestAddress));
    }
  }, [props.changeRequestAddress?.gateadresse]);

  useEffect(() => {
    if (!copyTouched && !props.copyPostAdresse && props.changeRequestAddress && props.postadresse && props.improvedAddress
      && props.changeRequestAddress.formattedForOneLine === props.postadresse.formattedForOneLine
      && props.improvedAddress.formattedForOneLine !== props.changeRequestAddress.formattedForOneLine) {
      props.setCopyPostAdresse(true);
    }
  }, [copyTouched, props.copyPostAdresse, props.changeRequestAddress, props.postadresse, props.improvedAddress, props.setCopyPostAdresse])

  useEffect(() => {
    if (props.changeRequestAddress && props.changeRequestAddress.formattedForOneLine && lookupCrResults.length > 0 && lookupCrResults.findIndex(o => o.formattedForOneLine === formatAddressAsOneLiner(props.changeRequestAddress)) > -1) {
      const i = lookupCrResults.findIndex(o => o.formattedForOneLine === formatAddressAsOneLiner(props.changeRequestAddress));
      if (isMountedRef.current) {
        setValidatedChangeRequestAddress(lookupCrResults[i]);
        setIsChangeRequestAddressValid(true);
      }
    } else if (props.changeRequestAddress && props.changeRequestAddress.formattedForOneLine && lookupCrResults.length <= 0) {
      if (isMountedRef.current) {
        setIsChangeRequestAddressValid(false);
      }
    }
  }, [lookupCrResults, props.changeRequestAddress])

  const postadresseCheckboxVisible = props.improvedAddress && (!props.postadresse || props.postadresse.formattedForOneLine !== props.improvedAddress.formattedForOneLine);

  return (
    <div className={Style.addressFormContainer}>
      <div className={cx(Style.changeRequestRowHeaderContainer, { [Style.isNotExpandedRowContainer]: !isExpandRow })}
        onClick={() => setIsExpandRow(!isExpandRow)}>
        <h6 className={Style.changeRequestRowHeader}>
          <span className={Style.changeRequestRowHeaderText}>Besøksadresse</span>
        </h6>
        <Button variant="link" size="sm">
          <IcebergIcon icon={isExpandRow ? 'chevron-up' : 'chevron-down'} bold />
        </Button>
      </div>
      <Collapse in={isExpandRow}>
        <div>
          <Row className={Style.addressFormRow}>
            <div className={Style.formBesoksadresseContainer}>
              <Form.Group className={Style.formGroup}>
                <InputGroup size="sm">
                  <Form.Control
                    readOnly
                    isValid={isChangeRequestAddressValid}
                    isInvalid={props.changeRequestAddress && props.changeRequestAddress.formattedForOneLine && !isChangeRequestAddressValid}
                    value={formatAddressAsOneLiner(props.changeRequestAddress)}
                  />
                  <InputGroup.Text>
                    <CopyToClipboardIcon val={formatAddressAsOneLiner(props.changeRequestAddress)} />
                  </InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    Adresse ble ikke funnet (kjederegisteret)
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid">
                    Adresse funnet og verifisert (kjederegisteret)
                  </Form.Control.Feedback>
                </InputGroup>

              </Form.Group>
            </div>
            <div className={Style.compareIconContainer}>
              {props.changeRequestAddress && isChangeRequestAddressValid ? (
                <div>
                  <CompareIcon
                    compareStatus={
                      qaCompareSimpleValue(
                        props.changeRequestAddress.formattedForOneLine,
                        props.improvedAddress?.formattedForOneLine
                      ) // compare with controlled query input value if no selected
                    }
                    onClick={transferAddress}
                  />
                </div>
              ) : (
                <OverlayTrigger
                  key="tooltip-top"
                  placement="top"
                  overlay={<Tooltip id="tooltip">Adresse er ikke gyldig og kan derfor ikke overføres.</Tooltip>}>
                  <div>
                    <IcebergIcon icon="arrow-right" backgroundColor="grey" color="#fff" rounded bold />
                  </div>
                </OverlayTrigger>
              )}
            </div>
            <div className={Style.formBesoksadresseContainer}>
              <Form.Group>
                <InputGroup size="sm">
                  {!props.improvedAddress ? (
                    <InputGroup.Text style={{ lineHeight: 0, paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                      <IcebergIcon icon="search" />
                    </InputGroup.Text>
                  ) : (
                    <InputGroup.Text style={{ padding: '.15rem .15rem' }}>
                      <IcebergIcon icon="check" />
                    </InputGroup.Text>
                  )}
                  <AddressTypeahead
                    selected={props.improvedAddress}
                    clearTypeaheadInitiator={props.changeRequestId}
                    allowNew={true}
                    onChange={adresse => { setCopyTouched(prev => false); props.onImprovedAddressChanged(adresse); }}
                    size="sm"
                  />
                </InputGroup>
                {props.isCustomAddress && props.improvedAddress && (
                  <Form.Text className="text-muted">Egendefinert og gyldig adresse.</Form.Text>
                )}
                {!props.isCustomAddress && props.improvedAddress && (
                  <Form.Text className="text-muted">Adresse er funnet i kjederegisteret.</Form.Text>
                )}

              </Form.Group>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  disabled={!props.changeRequestAddress && !props.improvedAddress}
                  className={Style.showMapBtn}
                  variant="link"
                  size="sm"
                  onClick={() => setAddressMapVisible(!addressMapVisible)}
                >
                  {addressMapVisible ? 'Lukk kart' : 'Vis på kart'}
                </Button>
              </div>
              <div>
                {props.leveringsadresse && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ height: '25px', whiteSpace: 'nowrap' }}>
                    <strong>Leveringsadresse: {props.improvedAddress && props.changeRequestAddress &&
                      props.changeRequestAddress.formattedForOneLine === props.leveringsadresse.formattedForOneLine && props.improvedAddress.formattedForOneLine !== props.leveringsadresse.formattedForOneLine
                      ? props.improvedAddress.formattedForOneLine : props.leveringsadresse.formattedForOneLine}
                    </strong>
                  </span>
                </div>}
                {props.postadresse && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ height: '25px', whiteSpace: postadresseCheckboxVisible ? 'normal' : 'nowrap' }}>
                    <strong>Postadresse: {props.copyPostAdresse && props.improvedAddress ? props.improvedAddress.formattedForOneLine : props.postadresse.formattedForOneLine}</strong>
                  </span>
                  {postadresseCheckboxVisible &&
                    <Form.Group controlId={`$postadresseCheckbox${props.changeRequestId}`} >
                      <Form.Check
                        className={Style.checkbox}
                        type="checkbox"
                        label="Kopier besøksadresse"
                        checked={props.copyPostAdresse}
                        onChange={() => { setCopyTouched(prev => true); props.setCopyPostAdresse(!props.copyPostAdresse); }}
                      />
                    </Form.Group>}
                </div>}
                {!props.postadresse && postadresseCheckboxVisible && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Form.Group controlId={`$postadresseCheckbox${props.changeRequestId}`} >
                    <Form.Check
                      className={Style.checkbox}
                      type="checkbox"
                      label="Kopier til postadresse"
                      checked={props.copyPostAdresse}
                      onChange={() => { setCopyTouched(prev => true); props.setCopyPostAdresse(!props.copyPostAdresse); }}
                    />
                  </Form.Group>
                </div>}
                {props.fakturaadresse && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ height: '25px', whiteSpace: 'nowrap' }}>
                    <strong>Fakturaadresse: {props.fakturaadresse.formattedForOneLine}</strong>
                  </span>
                </div>}
              </div>
            </div>
          </Row>
        </div>
      </Collapse>
      {addressMapVisible && (
        <Row className={Style.addressMapsRow}>
          {props.changeRequestAddress && (
            <Col>
              <QAAddressMap
                coordinates={
                  crCoordinatesValidated && crCoordinatesValidated.isValid()
                    ? crCoordinatesValidated
                    : undefined
                }
                poststed={props.changeRequestAddress.poststed}
              />
              {crCoordinatesValidated?.isValid() && (
                <h6>Viser lokasjon for eksisterende adresse (venstre felt).</h6>
              )}
              {!crCoordinatesValidated?.isValid() && props.changeRequestAddress.poststed && (
                <h6>
                  Endringsmeldingens adresse (venstre felt) har ikke koordinater. Viser utsnitt av
                  poststed fra endringsmelding (venstre felt).
                </h6>
              )}
              {!crCoordinatesValidated?.isValid() && !props.changeRequestAddress.poststed && (
                <h6>Ingen lokasjon tilgjengelig.</h6>
              )}
            </Col>
          )}
          {props.improvedAddress && (
            <Col>
              <QAAddressMap
                coordinates={
                  improvedCoordinates && improvedCoordinates.isValid()
                    ? improvedCoordinates
                    : undefined
                }
                poststed={props.improvedAddress?.poststed}
              />
              {improvedCoordinates?.isValid() && (
                <h6>Viser lokasjon for eksisterende adresse (høyre felt).</h6>
              )}
              {!improvedCoordinates?.isValid() && props.improvedAddress?.poststed && (
                <h6>
                  Eksisterende adresse (høyre felt) har ikke koordinater. Viser utsnitt av
                  poststed fra adressen (høyre felt).
                </h6>
              )}
              {!improvedCoordinates?.isValid() && !props.improvedAddress?.poststed && (
                <h6>Ingen lokasjon tilgjengelig.</h6>
              )}
            </Col>
          )}
        </Row>
      )}
    </div>
  )
}

export default QAAddressForm;