/*IMPORTS */

/*React & React module dependencies */
import React, { useState, useCallback, useEffect } from 'react';
import { Form, Button, Spinner, Row, Col } from 'react-bootstrap';

/*Shared Components */
import BransjePicker, { PickedBransje } from 'components/BransjePicker';
import MarkedsKjedePicker from 'components/MarkedsKjedePicker';
import { PickerMode, PickedKjede } from 'components/KjedePicker';

/*Hooks */
import useToaster from 'utils/hooks/useToaster';
import useForm from 'utils/hooks/useForm';

/*Services */
import abonnementApi from 'services/KjederegisteretAdminApi/AbonnementApi';
import { AbonnementContent, CreateAbonnementCommand } from 'services/KjederegisteretAdminApi/AbonnementApi/types';

/*Utils */
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';

/*Styling */
import Style from './index.module.css';

/*IMPORTS END */


interface Props {
  setShowCreate: (show: boolean) => void;
  loadAbonnement: (id: number) => void;
}

const CreateAbonnement: React.FC<Props> = props => {

  const toaster = useToaster();
  const isMountedRef = useIsMountedRef();

  /*useState properties */
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [selectedBransjer, setSelectedBransjer] = useState<PickedBransje[]>([]);
  const [selectedKjeder, setSelectedKjeder] = useState<PickedKjede[]>([]);
  const [ekskluderKjeder, setEkskluderKjeder] = useState<boolean>(false);

  const initialValues: CreateAbonnementCommand = {
    name: '',
    bransjer: [],
    kjeder: [],
    mottakere: [],
  };


  /*Functions */
  const validate = (values: CreateAbonnementCommand) => {
    const errors: any = {};
    if (!values.name) errors.name = 'Navn kan ikke være tomt.';
    return errors;
  };

  const onSubmit = async (command: CreateAbonnementCommand) => {
    if (isMountedRef.current) {
      setIsCreating(true);
    }
    try {
      if (isMountedRef.current) {
        const result = await abonnementApi.createAbonnement(command);
        setIsCreating(false);
        props.setShowCreate(false);
        props.loadAbonnement(result.id);
        toaster.success('Suksess', 'Abonnement opprettet.');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setIsCreating(false);
        toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(error));
      }
    }
  };

  const { touched, errors, values, handleSubmit, handleChange, submitDisabled } = useForm( initialValues, validate, onSubmit );

  useEffect(() => {
    var bransjer: AbonnementContent[] = selectedBransjer.map(x => {
      var bransje: AbonnementContent = {
        id: x.id,
        isEkskluderende: false
      }
      return bransje;
    });
    handleChange('bransjer', bransjer);
  }, [selectedBransjer])

  useEffect(() => {
    var kjeder: AbonnementContent[] = selectedKjeder.map(x => {
      var kjede: AbonnementContent = {
        id: x.id,
        code: "",
        isEkskluderende: ekskluderKjeder
      }
      return kjede;
    });
    handleChange('kjeder', kjeder);
  }, [selectedKjeder])

  useEffect(() => {
    var kjeder = values.kjeder.map(x => 
      ({...x, isEkskluderende: ekskluderKjeder})
    );
    handleChange('kjeder', kjeder);
  }, [ekskluderKjeder])

  /* Content */
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className='mb-3'>
        <Form.Label>Abonnementnavn</Form.Label>
        <Form.Control
          name="name"
          type="text"
          disabled={false}
          value={values.name}
          onChange={e => handleChange('name', e.target.value)}
          isInvalid={touched.name && errors.name}
        />
        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Bransjer</Form.Label>
        <BransjePicker onSelectionChanged={e => setSelectedBransjer(e)} selected={selectedBransjer.map(x => x.id)} />
      </Form.Group>

      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Markedskjeder</Form.Label>
            <MarkedsKjedePicker pickerMode={PickerMode.Multi} onSelectionChanged={e => setSelectedKjeder(e)} selected={selectedKjeder.map(x => Number(x.kjedenr))} />     
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Ekskluder kjeder</Form.Label>
            <div>
              <Form.Check 
                style={{ display: 'inline-block', marginRight: '0.5rem' }}
                checked={ekskluderKjeder} 
                onChange={e => setEkskluderKjeder(e.target.checked)} />
              <span>
                Ved avhukning vil enheter med medlemskap i valgte kjeder filtreres bort for abonnenter
              </span>
            </div>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group style={{ marginTop: '1rem' }}>
        <Button
          type="submit"
          disabled={submitDisabled}>
          {isCreating && (
            <Spinner
              className={Style.isCreatingSpinner}
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Opprett
        </Button>
      </Form.Group>
    </Form>
  );
};

export default CreateAbonnement;
