import { isEqual } from 'lodash';

import { IAdresse } from "components/AddressTypeahead/types"

import { AdresseViewModel, EnhetViewModel, LeverandorKundenummerDto } from "services/KjederegisteretAdminApi/EnhetApi/types"
import { ChangeRequestCommand } from "services/KjederegisteretAdminApi/ChangeRequestApi/types"


export const toAdresseFromViewModel = (advm: AdresseViewModel): IAdresse | undefined => {
  return advm ?
    {
      gateadresse: advm.gateadresse,
      postnr: advm.postnr,
      poststed: advm.poststed,
      formattedForOneLine: advm.formattedForOneLine,
      longitude: advm.longitude,
      latitude: advm.latitude,
      landkode: advm.landkode || 'NO',
      customOption: false
    } :
    undefined
}

export const getChangeRequestCommandInitialValue = (leverandorKundenummerHosTsKundeId: string): ChangeRequestCommand =>
({
  gln: '', organisasjonsnr: '', isOrgnrValidated: false, lopenummer: '', juridisknavn: '', enhetnavn: '',
  telefon: '', epost: '', bransjeId: null, notat: '', leverandorKundenummer: [],
  leverandorTsKundeId: leverandorKundenummerHosTsKundeId, nedlagtDato: null, isInactive: null,
  besoksadresse: {
    gateadresse: '', postnr: '', poststed: '', formattedForOneLine: '', longitude: null,
    latitude: null, adressetype: 'Besøksadresse',
  },
  copyPostAdresse: false
});

export const getChangeRequestCommandUpdateValue =
  (
    enhet: EnhetViewModel,
    leverandorKundenummerHosTsKundeId: string,
    copyPostadresse: boolean,
    kundenummer?: LeverandorKundenummerDto[]
  ): ChangeRequestCommand =>
  ({
    enhetId: enhet.id,
    lopenummer: enhet.lopenr,
    gln: enhet.firma.gln,
    organisasjonsnr: enhet.firma.organisasjonsnr,
    isOrgnrValidated: false,
    juridisknavn: enhet.firma.juridisknavn,
    enhetnavn: enhet.enhetnavn,
    telefon: enhet.firma.telefon,
    epost: enhet.firma.epost,
    bransjeId: enhet.bransje?.id,
    notat: enhet.notat,
    leverandorKundenummer: kundenummer?.map(o => o.kundenummer) || [],
    leverandorTsKundeId: leverandorKundenummerHosTsKundeId,
    nedlagtDato: enhet.firma.nedlagtDato,
    isInactive: enhet.isInactive,
    besoksadresse: {
      gateadresse: enhet.besoksadresse?.gateadresse || '',
      postnr: enhet.besoksadresse?.postnr || '',
      poststed: enhet.besoksadresse?.poststed || '',
      formattedForOneLine: enhet.besoksadresse?.formattedForOneLine || '',
      longitude: enhet.besoksadresse?.longitude || undefined,
      latitude: enhet.besoksadresse?.latitude || undefined,
      adressetype: 'Besøksadresse',
    },
    copyPostAdresse: copyPostadresse,
    hovedGrossist: enhet.grossist ? {
      kundenummerHosGrossist: enhet.grossist.kundenummerHosGrossist,
      grossistEnhetNavn: enhet.grossist.grossistEnhetNavn,
      grossistGln: enhet.grossist.grossistGln
    } : null,
    andreGrossister: enhet.grossister && enhet.grossister.length > 0 ? enhet.grossister.filter(x => !x.erHovedgrossist).map(grossist => 
      ({
        kundenummerHosGrossist: grossist.kundenummerHosGrossist,
        grossistEnhetNavn: grossist.grossistEnhetNavn,
        grossistGln: grossist.grossistGln
      })
    ) : null
  })

export class CompareStatus {
  static get ny() {
    return 'ny';
  }

  static get ignorer() {
    return 'ignorer';
  }

  static get lik() {
    return 'lik';
  }

  static get ulik() {
    return 'ulik';
  }
}


export const qaCompareSimpleValue = (a: string, b: string): string => {
  if (a && !b) {
    return CompareStatus.ny;
  }

  if (!a && b) {
    return CompareStatus.ignorer;
  }

  if (!a && !b) {
    return CompareStatus.ignorer;
  }

  if (a === b) {
    return CompareStatus.lik;
  }

  return CompareStatus.ulik;
};

export const qaContainsInText = (textToSearch: string, value: string): string => {
  if (!textToSearch) {
    return CompareStatus.ignorer;
  }

  if (textToSearch && !value) {
    return CompareStatus.ny;
  }

  if (textToSearch.indexOf(value) > -1) {
    return CompareStatus.lik;
  } else {
    return CompareStatus.ulik;
  }
};

export const qaExistInCommaSeparatedString = (textToSearch: string, value: string) => {
  if (!textToSearch && !value) {
    return CompareStatus.ignorer;
  }

  if (textToSearch && !value) {
    return CompareStatus.ignorer;
  }

  if (!textToSearch && value) {
    return CompareStatus.ny;
  }

  let separatedValues: string[] = textToSearch.split(',').map((item: string) => item.trim());
  if (separatedValues && value && separatedValues.some(x => x === value)) {
    return CompareStatus.lik;
  } else {
    return CompareStatus.ulik;
  }
};

export const qaCompareObjectValues = (obj1: Object, obj2: Object, overrideValueAndSetEqual: boolean) => {
  if (!Object.values(obj1).every(x => x === null) && Object.values(obj2).every(y => y === null)) {
    return CompareStatus.ny;
  }

  if (Object.values(obj1).every(x => x === null) && !Object.values(obj2).every(y => y === null)) {
    return CompareStatus.ignorer;
  }

  if (Object.values(obj1).every(x => x === null) && Object.values(obj2).every(y => y === null)) {
    return CompareStatus.ignorer;
  }

  if (overrideValueAndSetEqual || isEqual(obj1, obj2)) {
    return CompareStatus.lik;
  }

  return CompareStatus.ulik;
}