import React, { useState, useEffect } from 'react';
import { ListGroup, Collapse, Button, Row, Col, Badge } from 'react-bootstrap';
import CopyToClipboardIcon from 'components/CopyToClipboardIcon';
import { format } from 'date-fns';
import KjedeListItem from './KjedeListItem';
import Style from './index.module.css';
import { Enhetsgruppering, EnhetTypeViewModel, EnhetViewModel, KontonrOgFakturaAdresseSjekkResponse, LatestGrupperingMedlemskap } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import PostnummerRegisterApi, { PostnummerRegisterEntry } from 'services/KjederegisteretAdminApi/PostnummerRegisterApi';

interface Props {
  enhet: EnhetViewModel;
  setLinkedForm: (form: LinkedForm) => void;
  kontonrOgFakturaAdresseSjekkResponse?: KontonrOgFakturaAdresseSjekkResponse;
}

interface LinkedForm {
  tab: string;
  field: string;
}

const EnhetOverview = (props: Props) => {
  const [expandTyper, setExpandTyper] = useState(false);
  const [expandRegionalKjeder, setExpandRegionalKjeder] = useState<boolean>(false);
  const [expandMarkedKjeder, setExpandMarkedKjeder] = useState<boolean>(false);
  const [expandGrupper, setExpandGrupper] = useState<boolean>(false);

  let fakturering: string[] = [];

  if (props.enhet.firma.kanMottaEfaktura) {
    fakturering.push('Efaktura')
  }
  if (props.enhet.firma.fakturaEpost && props.enhet.firma.fakturaEpost.length > 0) {
    fakturering.push('Epost')
  }
  if (props.enhet.fakturaAdresse) {
    fakturering.push('Post')
  }

  const formatDate = (date: Date) => {
    if (!(date instanceof Date)) {
      return "";
    }
    return format(date, 'dd.MM.yyyy');
  };

  const checkItemStatus = (val: string | Date | number | EnhetTypeViewModel) => {
    if (!val) {
      return 'info';
    }
  };

  const [postnummerInfo, setPostnummerInfo] = useState<PostnummerRegisterEntry>();

  const loadPostnummerinfo = async (postnr: string) => {
    const info = await PostnummerRegisterApi.getByPostkode(postnr);
    setPostnummerInfo(info);
  }

  useEffect(() => {
    if (props.enhet && props.enhet.besoksadresse && props.enhet.besoksadresse.postnr) {
      loadPostnummerinfo(props.enhet.besoksadresse.postnr);
    }
  }, [props.enhet]);

  const checkKjedeStatus = (item: { isActive: boolean, isFuture: boolean, isHistoric: boolean }) => {
    if (item?.isActive) {
      return <Badge pill bg="success">Aktiv</Badge>;
    } else if (item?.isFuture) {
      return <Badge pill bg="info" text="dark">Fremtidig</Badge>;
    } else if (item?.isHistoric) {
      return <Badge pill bg="warning">Nedlagt</Badge>;
    }
  };

  const checkGrupperingStatus = (item: Enhetsgruppering | LatestGrupperingMedlemskap) => {
    if (new Date() >= new Date(item.fraOgMedDato) &&
      (!item.tilOgMedDato || new Date() < new Date(item.tilOgMedDato))) {
      return <Badge className={Style.statusBadge} pill bg="success">Aktiv</Badge>;
    }

    if (new Date() < new Date(item?.fraOgMedDato) && !item?.tilOgMedDato) {
      return <Badge className={Style.statusBadge} pill bg="info">Fremtidig</Badge>;
    }

    if (new Date(item.tilOgMedDato)) {
      return <Badge className={Style.statusBadge} pill bg="warning">Nedlagt</Badge>;
    }
  };

  const checkGlnStatus = (nedlagtDato?: Date) => {
    if (nedlagtDato && (new Date() > nedlagtDato)) {
      return <Badge className={Style.statusBadge} pill bg="warning">Nedlagt</Badge>;
    } else {
      return <Badge className={Style.statusBadge} pill bg="success">Aktiv</Badge>;
    }
  };

  return (
    <div className={Style.container}>
      <Row className={Style.listgroupRow}>
        <Col>
          <ListGroup variant="flush">
            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.juridisknavn)}>
              <span>
                <strong>Juridisk navn:</strong> {props.enhet.firma?.juridisknavn ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'basis', field: 'juridiskinfo' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.firma.juridisknavn} />
            </ListGroup.Item>
            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.organisasjonsnr)} >
              <span>
                <strong>Organisasjonsnr:</strong> {props.enhet.firma?.organisasjonsnr ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'basis', field: 'juridiskinfo' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.firma.organisasjonsnr} />
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.juridisknavnBedrift)}>
              <span>
                <strong>Juridisk navn bedrift:</strong> {props.enhet.firma?.juridisknavnBedrift ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'basis', field: 'juridiskbedriftinfo' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.firma.juridisknavnBedrift} />
            </ListGroup.Item>
            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.bedriftsnr)} >
              <span>
                <strong>Bedriftsnr:</strong> {props.enhet.firma?.bedriftsnr ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'basis', field: 'juridiskbedriftinfo' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.firma.bedriftsnr} />
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.gln)} >
              <span>
                <strong>GLN:</strong>
                {props.enhet.firma?.gln && (
                  <span>
                    <span>{props.enhet.firma?.gln}</span>
                    <span style={{ marginLeft: '.3em' }}>{checkGlnStatus(props.enhet.firma.glnNedlagtDato)}</span>
                    <span> ({props.enhet.firma?.eierGLNNavn})</span>
                  </span>
                )}
                {!props.enhet.firma?.gln &&
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'gln', field: '' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.firma.gln} />
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.oppstartsdato)} >
              <span>
                <strong>Oppstartdato:</strong> {formatDate(props.enhet.firma.oppstartsdato) ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'basis', field: 'oppstartdato' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={formatDate(props.enhet.firma.oppstartsdato)} />
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem}>
              <span>
                <strong>Post f.o.m dato:</strong> {formatDate(props.enhet.firma.postFraOgMedDato) || 'Reservert mot post'}
              </span>
              {props.enhet.firma.postFraOgMedDato && (
                <CopyToClipboardIcon val={formatDate(props.enhet.firma.postFraOgMedDato)} />
              )}
            </ListGroup.Item>

            {props.enhet.firma.nedlagtDato && (
              <ListGroup.Item className={Style.listGroupItem} variant="danger" >
                <span>
                  <strong>Nedlagtdato:</strong> {formatDate(props.enhet.firma.nedlagtDato)}
                </span>
                <CopyToClipboardIcon val={formatDate(props.enhet.firma.nedlagtDato)} />

              </ListGroup.Item>
            )}

            <ListGroup.Item variant={checkItemStatus(props.enhet.enhetType)}>
              <div className={Style.typerItem}>
                <span>
                  <strong>Type(r):</strong> {props.enhet.enhetType?.kode ||
                    <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'basis', field: 'enhettyper' })}>Legg til?</Button>}

                  {props.enhet.enhetTyper.length > 1 &&
                    <Button
                      size="sm"
                      variant="link"
                      aria-expanded={expandTyper}
                      onClick={() => setExpandTyper(!expandTyper)}
                      style={{ textDecoration: 'none', margin: '0', textAlign: 'center', paddingLeft: '.5em', paddingBottom: '.5em' }}
                    >
                      {!expandTyper ? `+${props.enhet.enhetTyper?.length - 1}` : `-${props.enhet.enhetTyper?.length - 1}`}
                    </Button>
                  }
                </span>
                <CopyToClipboardIcon val={props.enhet.enhetType?.kode} />
              </div>
              <Collapse in={expandTyper}>
                <ListGroup variant="flush">
                  {props.enhet.enhetTyper.map((type) => {
                    return (type.kode !== props.enhet.enhetType?.kode) && <ListGroup.Item>{type.kode}</ListGroup.Item>
                  })}
                </ListGroup>
              </Collapse>
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.bransje?.name)} >
              <span>
                <strong>Bransje:</strong> {props.enhet.bransje?.name ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'basis', field: 'bransje' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.bransje?.name} />
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.grossist?.name)} >
              <span>
                <strong>Grossist:</strong> {props.enhet.grossist?.name ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'grossist', field: '' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.grossist?.name} />
            </ListGroup.Item>
            <ListGroup.Item className={Style.listGroupItem} >
              <span>
                <strong>Leverandører:</strong> {props.enhet.leverandorKundenummer?.length > 0 ? props.enhet.leverandorKundenummer.length + " kundenummer" :
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'leverandorKundenummer', field: '' })}>Legg til?</Button>}
              </span>
            </ListGroup.Item>
            {/*  */}
            <ListGroup.Item>
              {props.enhet && props.enhet.grupper && props.enhet.grupper.length > 0 ? (
                <div className={Style.expandableItemContent}>
                  <div className={Style.content}>
                    <span><strong>Grupper:</strong></span>
                    <span style={{ marginLeft: '.3em' }}>{props.enhet.sisteGrupperingMedlemskap?.name} - {props.enhet && props.enhet.sisteGrupperingMedlemskap?.enhetgrupperingtypekode}</span>
                    <span className={Style.statusBadge}>{checkGrupperingStatus(props.enhet.sisteGrupperingMedlemskap)}</span>

                    {props.enhet.grupper?.length > 1 &&
                      <Button
                        className={Style.expandableItemShowMoreLinkBtn}
                        size="sm"
                        variant="link"
                        aria-expanded={expandGrupper}
                        onClick={() => setExpandGrupper(!expandGrupper)}
                      >
                        {!expandGrupper ? `+${props.enhet.grupper?.length - 1}` : `-${props.enhet.grupper?.length - 1}`}
                      </Button>
                    }
                  </div>

                  <CopyToClipboardIcon val={props.enhet && props.enhet.grupper[0].enhetsgrupperingnavn} />
                </div>
              ) : (
                <div>
                  <span><strong>Grupper:</strong></span>
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'grupper', field: '' })}>Legg til?</Button>
                </div>
              )}

              <Collapse in={expandGrupper}>
                <ListGroup variant="flush">
                  {props.enhet && props.enhet.grupper && props.enhet.grupper.map(gruppe => {
                    return (gruppe.enhetgrupperingId !== props.enhet.sisteGrupperingMedlemskap?.enhetgrupperingId) &&
                      <ListGroup.Item key={gruppe.enhetgrupperingId} className={Style.historicListgroupItem}>
                        <div className={Style.extraItemsContainer}>
                          <span className={Style.itemValue}>
                            {gruppe.enhetsgrupperingnavn} - {gruppe.enhetgrupperingtypekode}
                          </span>
                          <span className={Style.statusBadge}>{checkGrupperingStatus(gruppe)}</span>
                        </div>
                        <CopyToClipboardIcon val={gruppe.enhetsgrupperingnavn} />
                      </ListGroup.Item>
                  })}
                </ListGroup>
              </Collapse>
            </ListGroup.Item>
            {/*  */}
            <ListGroup.Item>
              {props.enhet && props.enhet.regionalkjedeHistorikk?.length > 0 ? (
                <div className={Style.expandableItemContent}>
                  <div className={Style.content}>
                    <span><strong>Regionalkjede:</strong></span>
                    <div style={{ marginLeft: '.3em' }}>
                      <span>{props.enhet.sisteRegionalkjedeMedlemskap?.name} {props.enhet.sisteRegionalkjedeMedlemskap?.number && `(avd.nr: ${props.enhet.sisteRegionalkjedeMedlemskap.number})`}</span>
                      <span style={{ marginLeft: '.3em' }}>{checkKjedeStatus(props.enhet.sisteRegionalkjedeMedlemskap)}</span>
                    </div>

                    {props.enhet.regionalkjedeHistorikk?.length > 1 &&
                      <Button
                        className={Style.expandableItemShowMoreLinkBtn}
                        size="sm"
                        variant="link"
                        aria-expanded={expandRegionalKjeder}
                        onClick={() => setExpandRegionalKjeder(!expandRegionalKjeder)}
                      >
                        {!expandRegionalKjeder ? `+${props.enhet.regionalkjedeHistorikk?.length - 1}` : `-${props.enhet.regionalkjedeHistorikk?.length - 1}`}
                      </Button>
                    }
                  </div>
                  <CopyToClipboardIcon val={props.enhet.sisteRegionalkjedeMedlemskap?.name} />
                </div>
              ) : (
                <div>
                  <span><strong>Regionalkjede:</strong></span>
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'kjede', field: '' })}>Legg til?</Button>
                </div>
              )}
              <Collapse in={expandRegionalKjeder}>
                <ListGroup variant="flush">
                  {props.enhet && props.enhet.regionalkjedeHistorikk?.map(hist => {
                    return (hist.kjedeId !== props.enhet.sisteRegionalkjedeMedlemskap?.id) &&
                      <ListGroup.Item key={hist.kjedeId}>
                        <KjedeListItem
                          item={hist}
                          checkKjedeStatus={checkKjedeStatus}
                        />
                      </ListGroup.Item>
                  })}
                </ListGroup>
              </Collapse>
            </ListGroup.Item>

            <ListGroup.Item>
              {props.enhet && props.enhet.markedskjedeHistorikk?.length > 0 ? (
                <div className={Style.expandableItemContent}>
                  <div className={Style.content}>
                    <span><strong>Markedskjede:</strong></span>
                    <div style={{ marginLeft: '.3em' }}>
                      <span>{props.enhet.sisteMarkedskjedeMedlemskap?.name} {props.enhet.sisteMarkedskjedeMedlemskap?.number && `(avd.nr: ${props.enhet.sisteMarkedskjedeMedlemskap.number})`}</span>
                      <span style={{ marginLeft: '.3em' }}>{checkKjedeStatus(props.enhet.sisteMarkedskjedeMedlemskap)}</span>
                    </div>
                    {props.enhet.markedskjedeHistorikk?.length > 1 &&
                      <Button
                        className={Style.kjedeShowMoreLinkBtn}
                        size="sm"
                        variant="link"
                        aria-expanded={expandMarkedKjeder}
                        onClick={() => setExpandMarkedKjeder(!expandMarkedKjeder)}
                      >
                        {!expandMarkedKjeder ? `+${props.enhet.markedskjedeHistorikk?.length - 1}` : `-${props.enhet.markedskjedeHistorikk?.length - 1}`}
                      </Button>
                    }
                  </div>
                  <CopyToClipboardIcon val={props.enhet.sisteMarkedskjedeMedlemskap?.name} />
                </div>
              ) : (
                <div>
                  <span><strong>Markedskjede:</strong></span>
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'kjede', field: '' })}>Legg til?</Button>
                </div>
              )}
              <Collapse in={expandMarkedKjeder}>
                <ListGroup variant="flush">
                  {props.enhet && props.enhet.markedskjedeHistorikk?.map((hist) => {
                    return (hist.kjedeId !== props.enhet.sisteMarkedskjedeMedlemskap?.id) &&
                      <ListGroup.Item key={hist.kjedeId}>
                        <KjedeListItem
                          item={hist}
                          checkKjedeStatus={checkKjedeStatus}
                        />
                      </ListGroup.Item>
                  })}
                </ListGroup>
              </Collapse>
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.besoksadresse?.formattedForOneLine)} >
              <span>
                <strong>Besøksadresse: </strong>
                {props.enhet.besoksadresse?.formattedForOneLine || <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'adresser', field: '' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.besoksadresse?.formattedForOneLine} />
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} >
              <span>
                <strong>Kommune: </strong>
                {postnummerInfo ? postnummerInfo.kommunenavn + ' (' + postnummerInfo.kommunenummer + ')' : ''}
              </span>
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} >
              <span>
                <strong>Fylke: </strong>
                {postnummerInfo ? postnummerInfo.fylkenavn + ' (' + postnummerInfo.fylkenr + ')' : ''}
              </span>
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.fakturaAdresse?.formattedForOneLine)} >
              <span>
                <strong>Fakturaadresse:</strong> {props.enhet.fakturaAdresse?.formattedForOneLine ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'adresser', field: '' })}>Legg til?</Button>}
              </span>

              <CopyToClipboardIcon val={props.enhet.fakturaAdresse?.formattedForOneLine} />
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.postadresse?.formattedForOneLine)} >
              <span>
                <strong>Postadresse:</strong> {props.enhet.postadresse?.formattedForOneLine ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'adresser', field: '' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.postadresse?.formattedForOneLine} />
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.leveringsadresse?.formattedForOneLine)}>
              <span>
                <strong>Leveringsadresse:</strong> {props.enhet.leveringsadresse?.formattedForOneLine ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'adresser', field: '' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.leveringsadresse?.formattedForOneLine} />
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.epost)} >
              <span>
                <strong>E-post:</strong> {props.enhet.firma?.epost ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'basis', field: 'epost' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.firma.epost} />
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.fakturaEpost)} >
              <span>
                <strong>Faktura E-post:</strong> {props.enhet.firma?.fakturaEpost ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'basis', field: 'fakturaepost' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.firma.fakturaEpost} />
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.telefon)} >
              <span>
                <strong>Telefon:</strong> {props.enhet.firma?.telefon ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'basis', field: 'telefon' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.firma.telefon} />

            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.kontonr)} >
              <span>
                <strong>Kontonummer:</strong> {props.enhet.firma?.kontonr ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'basis', field: 'kontonummer' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.firma.kontonr} />
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem}>
              <span>
                <strong>Fakturering:</strong> {fakturering.length > 0 ? fakturering.join(", ") : ""}
              </span>
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.omsetning?.omsetningMillioner)} >
              <span>
                <strong>Omsetning (millioner):</strong> {props.enhet.omsetning?.omsetningMillioner ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'basis', field: 'omsetning' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.omsetning?.omsetningMillioner?.toString()} />
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.salgsArealKvadratmeter)} >
              <span>
                <strong>Salgsareal (kvadratmeter):</strong> {props.enhet.firma?.salgsArealKvadratmeter ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'basis', field: 'salgsareal' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.firma?.salgsArealKvadratmeter?.toString()} />
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} >
              <span>
                <strong>Er sesongdrift:</strong> {props.enhet.firma?.erSesongdrift ? 'JA' : 'NEI'}
              </span>
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} >
              <span>
                <strong>Er offentlig:</strong> {props.enhet.firma.erOffentlig ? 'JA' : 'NEI'}
              </span>
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.landkode)} >
              <span>
                <strong>Land:</strong> {props.enhet.firma?.landkode ||
                  <Button size="sm" variant="link" onClick={() => props.setLinkedForm({ tab: 'basis', field: 'landkode-picker' })}>Legg til?</Button>}
              </span>
              <CopyToClipboardIcon val={props.enhet.firma?.landkode} />
            </ListGroup.Item>
            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.produl)}>
              <span>
                <strong>Produl:</strong> {props.enhet.firma?.produl}
              </span>
              <CopyToClipboardIcon val={props.enhet.firma?.produl?.toString()} />
            </ListGroup.Item>

            <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.statistikknr)}>
              <span>
                <strong>Statistikknummer:</strong> {props.enhet.firma.statistikknr}
              </span>
              <CopyToClipboardIcon val={props.enhet.firma?.statistikknr?.toString()} />
            </ListGroup.Item>

            {props.enhet.firma.forrigeLopenr &&
              <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.produl)}>
                <span>
                  <strong>Forrige løpenummer:</strong> {props.enhet.firma.forrigeLopenr}
                </span>
                <CopyToClipboardIcon val={props.enhet.firma?.forrigeLopenr?.toString()} />
              </ListGroup.Item>
            }
            {props.enhet.firma.nesteLopenr &&
              <ListGroup.Item className={Style.listGroupItem} variant={checkItemStatus(props.enhet.firma.produl)}>
                <span>
                  <strong>Neste løpenummer:</strong> {props.enhet.firma.nesteLopenr}
                </span>
                <CopyToClipboardIcon val={props.enhet.firma?.nesteLopenr?.toString()} />
              </ListGroup.Item>
            }
          </ListGroup>
        </Col>
      </Row>
    </div>

  );
};

export default EnhetOverview;
