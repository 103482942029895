import React, { useEffect, useState } from 'react';
import KjedeApi from 'services/KjederegisteretAdminApi/KjedeApi';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import DropdownTreeSelect, { TreeData, TreeNode, TextProps } from 'react-dropdown-tree-select';
import { Spinner } from 'react-bootstrap';
import 'react-dropdown-tree-select/dist/styles.css';
import { KjedeType } from 'services/KjederegisteretAdminApi/KjedeApi/types';

export interface KjedeTreNode {
  label: string;
  id: number;
  kjedenr: number;
  name: string;
  enhetId: string;
  nameHierarchy: string[];
  hierarchy: {
    nr: number;
    name: string;
    enhetId: number;
    id: number;
  }[];
  checked?: boolean;
  children?: KjedeTreNode[];
  disabled?: boolean;
  parent?: KjedeTreNode;
}

export interface PickedKjede {
  id: number;
  name: string;
  kjedenr: string;
  enhetId: number;
  hierarchy: PickedKjede[];
}

export enum PickerMode {
  Multi, Single
}

const KjedeTreHelper = {
  mapKjedeTre: (kjedeTre: KjedeTreNode[], selectedKjedeNr: number[]): TreeData => {
    return kjedeTre.map(o => KjedeTreHelper.mapNode(o, selectedKjedeNr));
  },

  mapNode: (node: KjedeTreNode, selected: number[]) => {
    const isChecked = (selected?.includes(node.kjedenr)) ? true : false;

    const item: KjedeTreNode = {
      label: node.name,
      name: node.name,
      id: node.id,
      kjedenr: node.kjedenr,
      enhetId: node.enhetId,
      children: [],
      checked: isChecked,
      hierarchy: node.hierarchy || [],
      nameHierarchy: node.nameHierarchy || [],
    };

    if (node.children) {
      node.children.forEach((child: KjedeTreNode) => {
        item.children.push(KjedeTreHelper.mapNode(child, selected));
      });
    }

    return item;
  }
};

interface Props {
  kjedeType?: string;
  pickerMode: PickerMode;
  customTexts?: TextProps;
  disabled?: boolean;
  disableTopNodes?: boolean;
  selected?: number[];
  onSelectionChanged: (kjeder: PickedKjede[]) => void;
}

const KjedePicker = (props: Props) => {
  const isMountedRef = useIsMountedRef();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [kjedeTreData, setKjedeTreData] = useState<KjedeTreNode[]>([]);

  const loadKjeder = async () => {
    setIsLoading(true);
    if (isMountedRef.current) {
      const res = props.kjedeType === KjedeType.Region ? await KjedeApi.getRegionTre() : await KjedeApi.getMarkedsTre();
      setKjedeTreData(res);
    }
    setIsLoading(false);
  };

  const handleOnChange = (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
    if (Array.isArray(selectedNodes)) {
      const selectedKjeder = selectedNodes.map(o => (
        {
          id: o.id,
          name: o.label,
          kjedenr: o.kjedenr,
          enhetId: o.enhetId,
          hierarchy: o.hierarchy,
        }));
      props.onSelectionChanged(selectedKjeder);
    }
  };

  useEffect(() => {
    if (isMountedRef.current) {
      loadKjeder();
    }
  }, []);

  const data = KjedeTreHelper.mapKjedeTre(kjedeTreData, props.selected);

  return (
    <>
      <DropdownTreeSelect
        className="reactDropdownMultiPicker"
        mode={props.pickerMode === PickerMode.Multi ? 'multiSelect' : 'radioSelect'}
        texts={{
          placeholder: 'Velg...',
          noMatches: 'Ingen treff',
        }}
        data={data || []}
        keepTreeOnSearch
        keepChildrenOnSearch
        showPartiallySelected
        onChange={handleOnChange}
        disabled={props.disabled || false}

      />
      {isLoading && (
        <div>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          <span> Henter data... </span>
        </div>
      )}
    </>
  );
};

export default React.memo(KjedePicker); 
