/*IMPORTS */

/*React & React module dependencies */
import React from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';

/*Shared Components */
import AbonnementInfo from './components/AbonnementInfo';
import EditBransjer from './components/EditBransjer';
import EditKjeder from './components/EditKjeder';

/*Services */
import { Abonnement } from 'services/KjederegisteretAdminApi/AbonnementApi/types';

/*Styling */
import Style from './index.module.css';

/*IMPORTS END */


interface Props {
  abonnement: Abonnement;
  onAbonnementDeleted?: () => void;
  loadAbonnement?: () => void;
}

const AbonnementCard: React.FC<Props> = props => {

  /*Content */
  return (
    <Row>
      <Col>
        <Tabs defaultActiveKey="abonnement">
          <Tab eventKey="abonnement" title="Info" className={Style.tab}>
            <AbonnementInfo abonnement={props.abonnement} onUpdated={props.loadAbonnement} onDeleted={props.onAbonnementDeleted} />
          </Tab>
          <Tab eventKey="bransjer" title="Bransjer" className={Style.tab}>
            <EditBransjer abonnement={props.abonnement} onUpdated={props.loadAbonnement} />
          </Tab>
          <Tab eventKey="kjeder" title="Kjeder" className={Style.tab}>
            <EditKjeder abonnement={props.abonnement} onUpdated={props.loadAbonnement} />
          </Tab>
        </Tabs>
      </Col>
    </Row>
  );
};

export default AbonnementCard;


