import axios from 'axios';
import { getQaUrlWithAccessKey } from '../KjederegisteretAdminApi/AzureFunctions';
import { BehandletStatistikk, Statistikk, Filter, SearchFilter, EndringsMelding, BehandletAction, BehandlesAction, UpsertFilterAction } from './types';

import { getQAApiUrl } from 'services';

const baseUrl = getQAApiUrl();

// customzied azure function based method
async function get(path: string, query?: string) {
  let url = await getQaUrlWithAccessKey(baseUrl, path);
  if (query) {
    url += query;
  }
  const response = await axios.get(url);
  return response.data;
}

async function post(path: string, payload?: any) {
  const url = await getQaUrlWithAccessKey(baseUrl, path);
  const response = await axios.post(url, payload);
  return response.data;
}

async function deleteItem(path: string) {
  const url = await getQaUrlWithAccessKey(baseUrl, path);
  const response = await axios.delete(url);
  return response.data;
}

async function getBehandletStatistikk(tilOgMedDato?: string): Promise<BehandletStatistikk> {
  if (tilOgMedDato) {
    return await get(`BehandletStatestikk`, `&tilOgMedDato=${tilOgMedDato}`);
  }
  return await get(`BehandletStatestikk`);
}

async function getStatistikk(): Promise<Statistikk> {
  return get('Statestikk');
}

async function getFilter(): Promise<Filter[]> {
  return await get('Filter');
}

async function deleteFilter(id: string) {
  return await deleteItem(`Filter/${id}`);
}

async function upsertFilter(payload: UpsertFilterAction) {
  return await post('Filter', payload);
}

async function getUrlWithCode(path: string): Promise<string> {
  return await getQaUrlWithAccessKey(baseUrl, path);
}

async function getEndringsMelding(id: string): Promise<EndringsMelding> {
  return get(`endringsmeldinger/${id}`);
}

async function getEndringsMeldinger(filter: SearchFilter): Promise<EndringsMelding[]> {
  let query = '';
  if (filter.status) {
    query = `&filter=${filter.status}`;
  }

  if (filter.tags && filter.tags.length > 0) {
    query = `&tags=${filter.tags.join(',')}`;
  }

  if (filter.query) {
    if (/^\d+$/.test(filter.query.trim())) {
      query += `&q=${filter.query.trim()}`;
    } else {
      query += `&q=${filter.query}`;
    }
  }

  if (filter.source) {
    query += `&source=${filter.source}`;
  }
  if (filter.orderBy) {
    query += `&orderBy=${filter.orderBy}`;
  }

  if (filter.take) {
    query += `&take=${filter.take}`;
  }

  if (filter.leverandorKundenummerHosTsKundeId) {
    query += `&leverandorKundenummerHosTsKundeId=${filter.leverandorKundenummerHosTsKundeId}`;
  }

  if (filter.grossistKundenummerHosGln && filter.grossistKundenummerHosGln.length > 0) {
    query += `&grossistKundenummerHosGln=${filter.grossistKundenummerHosGln.join(',')}`;
  }

  const data = await get('endringsmeldinger', query);
  return data.map((o: EndringsMelding) => {
    o.changes.nedlagtDato = o.changes.nedlagtDato ? new Date(o.changes.nedlagtDato) : null;
    o.changesMightBeRelatedToEnhetId = o.changesMightBeRelatedToEnhetId
      ? parseInt(o.changesMightBeRelatedToEnhetId.toString())
      : null;
    if (o.folgesOppAv) {
      o.folgesOppAv.statusChangedAt = new Date(o.folgesOppAv.statusChangedAt);
    }
    return o;
  });
}

async function avvis(id: string, changedBy: string, comment: string) {
  const payload = {
    comment,
    changedBy,
  };
  return post(`Endringsmeldinger/${id}/Avvis`, payload);
}

async function frigi(changeRequestId: string, name: string) {
  const payload = {
    changedBy: name,
    comment: '',
  };
  return post(`Endringsmeldinger/${changeRequestId}/frigi`, payload);
}

async function settTilBehandles(action: BehandlesAction) {
  const payload = {
    changedBy: action.approvedByusername,
    comment: '',
  };
  return post(`Endringsmeldinger/${action.id}/behandles`, payload);
}

async function settTilBehandlet(action: BehandletAction) {
  const payload = {
    changedBy: action.changedBy,
    lopenummer: action.lopenummer,
    enhetId: action.enhetId,
    comment: '',
  };
  return post(`Endringsmeldinger/${action.id}/Behandlet`, payload);
}

async function startVaskEndringsmelding(id: string) {
  return post(`Vasking/${id}/Vask`);
}

async function stansOppfolging(id: string, changedBy: string) {
  const payload = {
    changedBy,
  };
  return post(`Endringsmeldinger/${id}/StansOppfolging`, payload);
}

async function folgOpp(id: string, changedBy: string, comment: string) {
  const payload = {
    comment,
    changedBy,
  };
  return post(`Endringsmeldinger/${id}/FolgesOpp`, payload);
}

const QAApi = {
  getBehandletStatistikk,
  getStatistikk,
  getFilter,
  deleteFilter,
  upsertFilter,
  getUrlWithCode,
  getEndringsMelding,
  getEndringsMeldinger,
  avvis,
  frigi,
  settTilBehandles,
  settTilBehandlet,
  startVaskEndringsmelding,
  stansOppfolging,
  folgOpp
};

export default QAApi;