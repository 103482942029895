import { IconButton } from "@tradesolution/iceberg-ui-react";
import DatePicker from "components/DatePicker";
import { PickedKjede, PickerMode } from "components/KjedePicker";
import RegionalkjedePicker from "components/RegionalkjedePicker";
import { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import EnheterBulkApi from "services/KjederegisteretAdminApi/EnheterBulkApi";
import { MeldEnheterInnKjedeCommand } from "services/KjederegisteretAdminApi/EnheterBulkApi/types";
import { GetKjedeByIdResponse } from "services/KjederegisteretAdminApi/KjedeApi/types";
import useToaster from "utils/hooks/useToaster";

interface Props {
    kjede: GetKjedeByIdResponse;
}

const Admin = (props: Props) => {

    const toaster = useToaster();

    const [command, setCommand] = useState<MeldEnheterInnKjedeCommand | undefined>({
        flyttFraKjedeNummer: props.kjede.kjedenr,
        flyttTilKjedeNummer: 0,
        fraOgMedDato: new Date(),
        skalOverforeKundenummer: true
    });

    // pass på å oppdatere fraKjede om en velger en annen kjede
    useEffect(() => {
        setCommand({ ...command, flyttFraKjedeNummer: props.kjede.kjedenr, fraOgMedDato: new Date(), flyttTilKjedeNummer: 0, skalOverforeKundenummer: true });
    }, [props.kjede.kjedenr]);

    const handleKjedeSelected = (pickedKjeder: PickedKjede[]) => {
        if (pickedKjeder.length > 0) {
            setCommand({ ...command, flyttTilKjedeNummer: parseInt(pickedKjeder[0].kjedenr) });
        } else {
            setCommand({ ...command, flyttTilKjedeNummer: undefined });
        }
    };

    const handleDateSelected = (date: Date) => {
        setCommand({ ...command, fraOgMedDato: date });
    };

    const handleKundenrChecked = () => {
        setCommand({ ...command, skalOverforeKundenummer: !command.skalOverforeKundenummer });
    }

    const [isLoading, setIsLoading] = useState(false);

    const canSubmit = command && command.flyttTilKjedeNummer && command.flyttFraKjedeNummer && command.fraOgMedDato && !isLoading;

    const submit = async () => {
        setIsLoading(true);
        try {
            const result = await EnheterBulkApi.meldEnheterInnKjede(command);

            toaster.success("Suksess", "Medlemmer flyttet");
        } catch (error) {
            toaster.error("Feil", "Kunne ikke flytte medlemmer: " + error.message || "");
        }
        setIsLoading(false);
    };

    return (
        <div>
            <h4>Admin</h4>
            <Card>
                <Card.Body>
                    <Card.Title>Flytt alle medlemmer inn i ny kjede</Card.Title>
                    <Form>
                        <Form.Group>
                            <Form.Label>Ny kjede</Form.Label>
                            <RegionalkjedePicker selected={[command.flyttTilKjedeNummer]} pickerMode={PickerMode.Single} onSelectionChanged={handleKjedeSelected} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Fra dato</Form.Label>
                            <div>
                                <DatePicker onChange={handleDateSelected} selected={command.fraOgMedDato} adjustTimeForTimezoneOffset={true} />
                            </div>
                        </Form.Group>
                        <Form.Group style={{ marginTop: "1rem" }}>
                            <Form.Check type="checkbox" checked={command.skalOverforeKundenummer} onChange={handleKundenrChecked} label="Avdelingsnr skal følge med" />
                        </Form.Group>
                        <Form.Group style={{ marginTop: "1rem" }}>
                            <IconButton icon="disk" disabled={!canSubmit} onClick={submit}>
                                Lagre
                            </IconButton>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    )
};

export default Admin;