import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap';

import { format } from 'date-fns';
import * as _ from "lodash";

import MedlemskapEdit from './MedlemskapEdit';
import AvsluttGruppeMedlemskapModal from './AvsluttGruppeMedlemskapModal';

import ConfirmButton from 'components/ConfirmButton';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import EnhetsgrupperingApi from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi';
import { Medlemsgruppe, Enhetgruppering, FjernMedlemsgruppeCommand } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';

import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';

import Style from './index.module.css';

interface Props {
  gruppering: Enhetgruppering;
  reload: () => void;
  setMedlemsCount: (count: number) => void;
}

const MedlemsgrupperTab: React.FC<Props> = props => {
  const isMountedRef = useIsMountedRef();
  const toaster = useToaster();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [medlemmer, setMedlemmer] = useState<Medlemsgruppe[]>(props.gruppering.medlemsgrupper || []);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | undefined>();
  const [queryText, setQueryText] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("kjedeNavn");


  const loadMedlemer = async () => {
    setIsLoading(true);
    const result = await EnhetsgrupperingApi.getMedlemsgrupper(props.gruppering.enhetgrupperingID);
    setMedlemmer(result);
    setIsLoading(false);
  };

  const onFjernConfirmed = async (enhetgrupperingGrupperingID: number) => {
    const command: FjernMedlemsgruppeCommand = {
      enhetgrupperingGrupperingID: enhetgrupperingGrupperingID,
    };

    EnhetsgrupperingApi.fjernMedlemsgruppe(props.gruppering.enhetgrupperingID, command)
      .then(() => {
        toaster.success('Suksess!', 'Gruppe ble fjernet.');
      })
      .catch(err => {
        toaster.error('Feil! Kunne ikke melde ut av gruppe.', HttpErrorHelper.handleErrorMessage(err));
      })
      .finally(() => {
        loadMedlemer();
      });
  };

  const handleShowModal = (id: number) => {
    if (isMountedRef.current) {
      setIsShowModal(true);
      setSelectedId(id);
    }
  };
  const handleCloseModal = () => {
    if (isMountedRef.current) {
      setIsShowModal(false);
      setSelectedId(null);
      loadMedlemer();
    }
  };

  useEffect(() => {
    setMedlemmer(props.gruppering.medlemsgrupper.filter(o => o.enhetgrupperingNavn.toLowerCase().includes(queryText.toLowerCase())));
  }, [queryText]);

  useEffect(() => {
    switch (sortBy) {
      case "kjedeNavn":
        setMedlemmer(_.orderBy(props.gruppering.medlemsgrupper, o => o.enhetgrupperingNavn));
        break;
      case "innmeldt":
        setMedlemmer(_.orderBy(props.gruppering.medlemsgrupper, o => o.fraOgMedDato));
        break;
      case "innmeldt_desc":
        setMedlemmer(_.orderBy(props.gruppering.medlemsgrupper, o => -o.fraOgMedDato));
        break;
      case "utmeldt":
        setMedlemmer(_.orderBy(props.gruppering.medlemsgrupper, o => o.tilOgMedDato));
        break;
      case "utmeldt_desc":
        setMedlemmer(_.orderBy(props.gruppering.medlemsgrupper, o => -o.tilOgMedDato));
        break;
    }

  }, [sortBy]);

  useEffect(() => {
    const active = medlemmer.filter(x => x.isActive);
    props.setMedlemsCount(active.length);
  }, [medlemmer]);

  return (
    <Card>
      <Card.Body>
        <div className={Style.headerContainer}>
          <h6>{props.gruppering.enhetsgrupperingnavn} sine medlemsgrupper</h6>
          <Button size="sm" variant={isEdit ? 'secondary' : 'outline-primary'}
            onClick={() => setIsEdit(!isEdit)}>
            <IcebergIcon icon={isEdit ? "close" : "plus"} bold />
            {isEdit ? <span> Avbryt</span> : <span> Nytt medlemskap</span>}
          </Button>
        </div>
        {isEdit && (
          <div className={Style.paddingBottom}>
            <MedlemskapEdit setIsEdit={setIsEdit} gruppering={props.gruppering} onDone={loadMedlemer} />
          </div>
        )}
        <Form style={{ marginBottom: '0.5rem' }}>
          <Row>
            <Col sm={8}>
              <Form.Control
                size="sm"
                placeholder="Søk"
                value={queryText}
                onChange={e => setQueryText(e.target.value)}
              />
            </Col>
            <Col>
              <Form.Control size="sm" placeholder="Sorter" as="select" value={sortBy || 'postnr'}
                onChange={e => setSortBy(e.target.value)}>
                <option value="kjedeNavn">Navn</option>
                <option value="innmeldt">Innmeldt dato - tidligste først</option>
                <option value="innmeldt_desc">Innmeldt dato - nyligste først</option>
                <option value="utmeldt">Utmeldt dato - tidligste først</option>
                <option value="utmeldt_desc">Utmeldt dato - nyligste først</option>
              </Form.Control>
            </Col>
          </Row>
        </Form>
        <Table striped>
          <thead>
            <tr>
              <th>Navn (Enhetgrupperingnr / Kjedenr)</th>
              <th>Periode</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {medlemmer.length > 0 ? (
              medlemmer.map(k => (
                <tr key={k.enhetgrupperingGrupperingID}>
                  <td>{k.enhetgrupperingNavn} ({k.enhetsgrupperingNummer + " / " + k.kjedenr})</td>
                  <td>
                    {format(k.fraOgMedDato, 'dd.MM.yyyy')} -{' '}
                    {!!k.tilOgMedDato ? format(k.tilOgMedDato, 'dd.MM.yyyy') : ''}
                  </td>
                  <td className="float-right">
                    {!k.tilOgMedDato && (
                      <Button onClick={() => handleShowModal(k.enhetgrupperingGrupperingID)}
                        variant="outline-danger" size="sm">
                        Meld ut
                      </Button>
                    )}
                    &nbsp;
                    <ConfirmButton
                      onClick={() => onFjernConfirmed(k.enhetgrupperingGrupperingID)}
                      size="sm"
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr key={Math.random()}>
                {isLoading ? (
                  <td colSpan={3}>
                    <div>
                      <Spinner animation="border" />
                      <span>Henter medlemer..</span>
                    </div>
                  </td>
                ) : (
                  <td colSpan={3}>
                    <h6>Ingen medlemer</h6>
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </Table>
        <AvsluttGruppeMedlemskapModal
          enhetgrupperingGrupperingID={selectedId}
          isShowModal={isShowModal}
          handleCloseModal={handleCloseModal}
          enhetsgruppeId={props.gruppering.enhetgrupperingID}
        />
      </Card.Body>
    </Card>
  );
};

export default MedlemsgrupperTab;
