import { useState, useCallback, useEffect } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { format } from 'date-fns';

import BransjePicker, { PickedBransje } from 'components/BransjePicker';
import GrossistPicker, { PickedGrossist } from 'components/GrossistPicker';
import GrupperingPicker from 'components/GrupperingPicker';
import { IMappedGrupperingNode } from 'components/GrupperingPicker/types';
import KjedePicker, { PickedKjede, PickerMode } from 'components/KjedePicker';
import OmrådePicker from 'components/OmrådePicker';
import { PickedOmråde } from 'components/OmrådePicker/types';
import EnhetTypeMultiPicker from 'components/EnhetTypeMultiPicker';
import DatePicker from 'components/DatePicker';
import BrukerPicker, { IMappedBruker } from 'components/BrukerPicker';

import { EnhetSearchFilter } from 'services/KjederegisteretAdminApi/EnhetSearchApi/types';
import { KjedeType } from 'services/KjederegisteretAdminApi/KjedeApi/types';

import AktivFilterRadio from './AktivFilterRadio';
import OtherFilters from './OtherFilters';
import { PickedType } from 'components/EnhetTypeMultiPicker/types';

interface Props {
    onFilterChanged: (filter: EnhetSearchFilter) => void;
    filter: EnhetSearchFilter;
}

const FilterSearch = (props: Props) => {
    const [showOpprettetDateInputs, setShowOpprettetDateInputs] = useState<boolean>(props.filter.opprettetFilter === "custom");
    const [customFrom, setCustomFrom] = useState<Date>();
    const [customTo, setCustomTo] = useState<Date>();

    const handleOtherFiltersChanged = (value, checked) => {
        switch (value) {
            case 'IkkeGln':
                props.onFilterChanged({ ...props.filter, glnFilter: checked ? 'UtenGln' : 'All' });
                break;
            case 'IkkeHovedBransje':
                props.onFilterChanged({
                    ...props.filter,
                    utenHovedbransjeFilter: checked ? 'UtenHovedbransje' : 'All',
                });
                break;
            case 'IkkeKomplett':
                props.onFilterChanged({
                    ...props.filter,
                    komplettTidspunktFilter: checked ? 'UtenKomplettTidspunkt' : 'All',
                });
                break;
            case 'IkkePublisert':
                props.onFilterChanged({
                    ...props.filter,
                    ikkePublisertFilter: checked ? 'IkkePublisert' : 'All',
                });
                break;
            default:
                throw new Error('Ukjent option!');
        }
    };

    const handleActiveFilterChanged = (value: string) => {
        props.onFilterChanged({ ...props.filter, aktivFilter: value });
    };

    // workaround because the picker component does not handle beeing a controlled component great
    const [selectedRegionalKjeder, setSelectedRegionalKjeder] = useState<PickedKjede[]>([]);
    const handleRegionalkjedeSelected = useCallback((kjedeList: PickedKjede[]) => {
        setSelectedRegionalKjeder(kjedeList);
    }, []);

    useEffect(() => {
        props.onFilterChanged({ ...props.filter, regionKjedeFilter: selectedRegionalKjeder.map((o: PickedKjede) => parseInt(o.kjedenr)) });
    }, [selectedRegionalKjeder]);

    const [selectedMarkedsKjeder, setSelectedMarkedsKjeder] = useState<PickedKjede[]>([]);
    const handleMarkedskjedeSelected = useCallback((kjedeList: PickedKjede[]) => {
        setSelectedMarkedsKjeder(kjedeList);
    }, []);

    useEffect(() => {
        props.onFilterChanged({ ...props.filter, salgsOgMarkedsforingKjedeFilter: selectedMarkedsKjeder.map((o: PickedKjede) => parseInt(o.kjedenr)) });
    }, [selectedMarkedsKjeder]);

    const [selectedBransjer, setSelectedBransjer] = useState<PickedBransje[]>([]);
    const handleBransjeSelectionChanged = useCallback((bransjeList: PickedBransje[]) => {
        setSelectedBransjer(bransjeList);
    }, []);

    useEffect(() => {
        props.onFilterChanged({ ...props.filter, bransjeFilter: selectedBransjer.map(o => o.id) });
    }, [selectedBransjer]);

    const [selectedOmråder, setSelectedOmråder] = useState<PickedOmråde[]>([]);
    const handleOmrådeSelectionChanged = useCallback((områdeList: PickedOmråde[]) => {
        setSelectedOmråder(områdeList);
    }, []);

    useEffect(() => {
        props.onFilterChanged({
            ...props.filter,
            fylker: selectedOmråder.filter(o => o.type === "fylke").map(o => o.name),
            kommuner: selectedOmråder.filter(o => o.type === "kommune").map(o => o.name),
            postnr: selectedOmråder.filter(o => o.type === "postnr").map(o => o.nr)
        });
    }, [selectedOmråder]);

    const findPreselectedOmrader = useCallback(() => {
        return {
            fylker: props.filter.fylker,
            kommuner: props.filter.kommuner,
            postnr: props.filter.postnr
        }
    }, []);

    const [selectedEnhetTyper, setSelectedEnhetTyper] = useState<PickedType[]>([]);
    const onEnhetTypeSelectionChanged = (pickedTyper: PickedType[]) => {
        setSelectedEnhetTyper(pickedTyper);
    };
    useEffect(() => {
        props.onFilterChanged({ ...props.filter, enhetTypeFilter: selectedEnhetTyper.map(o => o.kode) });
    }, [selectedEnhetTyper]);

    const [selectedGrossists, setSelectedGrossists] = useState<PickedGrossist[]>([]);
    const handleGrossistSelectionChanged = useCallback((pickedGrossists: PickedGrossist[]) => {

        console.log(pickedGrossists)
        setSelectedGrossists(pickedGrossists);
    }, []);

    useEffect(() => {
        props.onFilterChanged({ ...props.filter, grossistFilter: selectedGrossists.map(o => o.nr) });
    }, [selectedGrossists]);

    const [selectedBrukere, setSelectedBrukere] = useState<IMappedBruker[]>([]);
    const handleSistEndretAvChanged = useCallback((pickedBrukere: IMappedBruker[]) => {
        setSelectedBrukere(pickedBrukere);
    }, []);

    useEffect(() => {
        props.onFilterChanged({ ...props.filter, sistEndretAv: selectedBrukere.map(x => x.brukerId) });
    }, [selectedBrukere]);

    const handleEnhetsgrupperingSelectionChanged = useCallback((selectedGrupperinger: IMappedGrupperingNode[]) => {
        props.onFilterChanged({ ...props.filter, enhetsgrupperingFilter: selectedGrupperinger.map(x => x.nr) });
    }, []);

    const onCreatedChanged = (value: string) => {
        if (value === "custom") {
            setShowOpprettetDateInputs(true);
            props.onFilterChanged({ ...props.filter, opprettetFilter: value });
        } else {
            setShowOpprettetDateInputs(false);
            props.onFilterChanged({ ...props.filter, opprettetFilter: value });
        }
    };

    const handleCustomFilterClicked = () => {
        const value = format(customFrom, 'dd.MM.yyyy') + '-' + format(customTo, 'dd.MM.yyyy');
        props.onFilterChanged({ ...props.filter, customOpprettetFilter: value });
    };

    const findPreselectedNumericValueInFilter = (picker: string) => {
        if (picker === 'gruppering') {
            return props.filter.enhetsgrupperingFilter;
        }

        if (picker === 'grossister') {
            return props.filter.grossistFilter;
        }
    };


    const findPreselectedStringValueInFilter = useCallback((picker: string) => {
        if (picker === 'bruker') {
            return props.filter.sistEndretAv;
        }

    }, []);

    return (
        <div style={{ maxWidth: '100%' }}>
            <AktivFilterRadio
                title="Aktiv / Nedlagt"
                options={[
                    { value: 'All', label: 'Alle' },
                    { value: 'Active', label: 'Aktive' },
                    {
                        value: 'Closed',
                        label: 'Nedlagte',
                    },
                    {
                        value: 'NotOpenedYet',
                        label: 'Fremtidig oppstart',
                    },
                ]}
                onOptionChanged={handleActiveFilterChanged}
                value={props.filter.aktivFilter}
            />

            <Card className="mb-3">
                <Card.Header>Sist endret av</Card.Header>
                <Card.Body data-private>
                    <BrukerPicker
                        treeSelectMode="multiSelect"
                        onSelectionChanged={handleSistEndretAvChanged}
                        findPreselectedInFilter={findPreselectedStringValueInFilter}
                    />
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <Card.Header>Opprettet</Card.Header>
                <Card.Body data-private>
                    <Form.Control as="select" value={props.filter.opprettetFilter} onChange={e => onCreatedChanged(e.target.value)}>
                        <option value="">Velg...</option>
                        <option value="today">I dag</option>
                        <option value="yesterday">I går</option>
                        <option value="week">Siste 7 dager</option>
                        <option value="month">Sist måned</option>
                        <option value="custom">Custom</option>
                    </Form.Control>
                    {showOpprettetDateInputs && (
                        <div className='mt-3'>
                            <Form.Group>
                                <Form.Label>Fra</Form.Label> <small style={{ color: "red" }}>*</small>
                                <DatePicker selected={customFrom} onChange={val => setCustomFrom(val)} adjustTimeForTimezoneOffset={true} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Til</Form.Label> <small style={{ color: "red" }}>*</small>
                                <DatePicker selected={customTo} onChange={val => setCustomTo(val)} adjustTimeForTimezoneOffset={true} />
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Button size="sm" style={{ float: "right" }} variant='outline-primary' onClick={handleCustomFilterClicked}>Filtrer</Button>
                            </Form.Group>
                        </div>
                    )}
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <Card.Header>Enhettype</Card.Header>
                <Card.Body>
                    <EnhetTypeMultiPicker
                        onSelectionChanged={onEnhetTypeSelectionChanged}
                        selectedNodes={props.filter.enhetTypeFilter}
                        treeSelectMode="multiSelect"
                    />
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <Card.Header>Bransje</Card.Header>
                <Card.Body>
                    <BransjePicker
                        onSelectionChanged={handleBransjeSelectionChanged}
                        selected={props.filter.bransjeFilter}
                        disabled={false}
                        canSelectNedlagt={true}
                    />
                </Card.Body>
            </Card>


            <Card className="mb-3">
                <Card.Header>Område</Card.Header>
                <Card.Body>
                    <OmrådePicker
                        onSelectionChanged={handleOmrådeSelectionChanged}
                        findPreselectedNodes={findPreselectedOmrader}
                    />
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <Card.Header>Regionalkjeder</Card.Header>
                <Card.Body>
                    <KjedePicker
                        kjedeType={KjedeType.Region}
                        pickerMode={PickerMode.Multi}
                        onSelectionChanged={handleRegionalkjedeSelected}
                        selected={props.filter.regionKjedeFilter}
                    />
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <Card.Header>Markedskjeder</Card.Header>
                <Card.Body>
                    <KjedePicker
                        kjedeType={KjedeType.Marked}
                        pickerMode={PickerMode.Multi}
                        onSelectionChanged={handleMarkedskjedeSelected}
                        selected={props.filter.salgsOgMarkedsforingKjedeFilter}
                    />
                    <div>
                        <Form.Check
                            style={{ display: 'inline-block', marginRight: '0.5rem' }}
                            checked={props.filter.ekskluderMarkedskjeder}
                            onChange={e => props.onFilterChanged({ ...props.filter, ekskluderMarkedskjeder: e.target.checked })} />
                        <span>Ekskluder valgte</span>
                    </div>
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <Card.Header>Enhetsgruppering</Card.Header>
                <Card.Body>
                    <GrupperingPicker
                        treeSelectMode='multiSelect'
                        onSelectionChanged={handleEnhetsgrupperingSelectionChanged}
                        findPreselectedNodesByNumber={findPreselectedNumericValueInFilter}
                        customTexts={{ placeholder: "Enhetgruppering", noMatches: "Ingen treff" }}
                    />
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <Card.Header>Grossister</Card.Header>
                <Card.Body>
                    <GrossistPicker
                        onSelectionChanged={handleGrossistSelectionChanged}
                        findPreselectedNodesByNR={findPreselectedNumericValueInFilter}
                    />
                </Card.Body>
            </Card>
            <OtherFilters
                title="Andre filtre"
                options={[
                    {
                        value: 'IkkeGln',
                        label: 'Ikke GLN',
                        checked: props.filter.glnFilter !== 'All',
                    },
                    {
                        value: 'IkkeHovedBransje',
                        label: 'Ikke hovedbransje',
                        checked: props.filter.utenHovedbransjeFilter !== 'All',
                    },
                    {
                        value: 'IkkeKomplett',
                        label: 'Ikke komplett',
                        checked: props.filter.komplettTidspunktFilter !== 'All',
                    },
                    {
                        value: 'IkkePublisert',
                        label: 'Ikke publisert',
                        checked: props.filter.ikkePublisertFilter !== 'All',
                    },
                ]}
                onOptionChanged={handleOtherFiltersChanged}
            />
        </div>
    );
};

export default FilterSearch;
