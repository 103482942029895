import { useState, useEffect, Fragment } from 'react';
import { orderBy } from 'lodash';
import { Table, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import SortingIcon from 'components/SortingIcon';
import GrossistModal from './components/GrossistModal';
import PopoverMenu from './components/PopoverMenu';
import { Grossist } from 'services/KjederegisteretAdminApi/GrossisterApi/types';
import DateHelper from 'utils/DateHelper';
import Style from './index.module.css';
import GrossistEdit from './components/EditGrossist';

export interface Props {
  data: Grossist[];
  loadGrossister: (filter: string) => void;
}

const GrossistTable = (props: Props) => {
  const [tableData, setTableData] = useState<Grossist[]>(props.data);
  const [sortCol, setSortCol] = useState<string>('grossistnr');
  const [reverseSort, setReverseSort] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedGrossist, setSelectedGrossist] = useState<Grossist>(null);


  useEffect(() => {
    setTableData(props.data);
    handleColSortClicked('grossistnr');
  }, [props.data]);

  useEffect(() => {
    sortTableColumn(sortCol, reverseSort);
  }, [sortCol, reverseSort]);

  const sortTableColumn = (col: string, reverse: boolean) => {
    const copy = [...tableData];
    const sorted = orderBy(copy, [col], reverse ? 'desc' : 'asc');
    setTableData(sorted);
  };

  const handleColSortClicked = (col: string) => {
    if (col === sortCol) {
      setReverseSort(!reverseSort);
    } else {
      setSortCol(col);
      setReverseSort(false);
    }
  };

  const goToEnhetClicked = (grossist: Grossist) => {
    const url = `/enheter/${grossist.grossistEnhetId}`;
    window.open(url, '_blank');
  };

  const handleShowLeggNedModal = (grossist: Grossist) => {
    const copy = tableData.map(o =>
      o.grossistnr === grossist.grossistnr ? { ...o, leggNed: !o.leggNed } : o,
    );
    setTableData(copy);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    const copy = tableData.map(o => ({ ...o, leggNed: false }));
    setTableData(copy);
    setShowModal(false);
  };

  const handleEditGrossist = (grossist: Grossist) => {
    setSelectedGrossist(grossist);
  };

  return (
    <Table striped>
      <thead>
        <tr>
          <th scope="col" className="d-none d-lg-table-cell" onClick={() => handleColSortClicked('grossistnr')}>
            <div className={Style.grossistnrColumn}>
              <span className={Style.sortableColumnHeader}>Grossistnummer</span>
              <SortingIcon sortDirection={sortCol !== 'grossistnr' ? '' : (reverseSort ? 'desc' : 'asc')} />
            </div>
          </th>
          <th scope="col" onClick={() => handleColSortClicked('kortnavn')}>
            <div className={Style.kortnavnColumn}>
              <span className={Style.sortableColumnHeader}>Kortnavn (EnhetId)</span>
              <SortingIcon sortDirection={sortCol !== 'kortnavn' ? '' : (reverseSort ? 'desc' : 'asc')} />
            </div>
          </th>
          <th scope="col" onClick={() => handleColSortClicked('kortnavn')}>
            <div className={Style.kortnavnColumn}>
              <span className={Style.sortableColumnHeader}>Navn</span>
              <SortingIcon sortDirection={sortCol !== 'kortnavn' ? '' : (reverseSort ? 'desc' : 'asc')} />
            </div>
          </th>
          <th scope="col" onClick={() => handleColSortClicked('nedlagtdato')}>
            <div className={Style.nedlagtColumn}>
              <span className={Style.sortableColumnHeader}>Nedlagt dato</span>
              <SortingIcon sortDirection={sortCol !== 'nedlagtdato' ? '' : (reverseSort ? 'desc' : 'asc')} />
            </div>
          </th>
          <th scope='col' onClick={() => handleColSortClicked('grossistgruppe')}>
            <div className={Style.grossistColumn}>
              <span className={Style.sortableColumnHeader}>Grossistgruppe</span>
              <SortingIcon sortDirection={sortCol !== 'grossistgruppe' ? '' : (reverseSort ? 'desc' : 'asc')} />
            </div>
          </th>
          <th className={Style.actionBtnTableHeader}>
            <div />
          </th>
        </tr>
      </thead>

      <tbody className={Style.tableBody}>
        {
          tableData.map(grossist => (
            <Fragment key={`${grossist.grossistnr}-${Math.random()}`}>
              {grossist.leggNed && showModal && (
                <GrossistModal
                  grossist={grossist}
                  handleClose={handleCloseModal}
                  show={showModal}
                  loadGrossister={props.loadGrossister} />
              )}
              <tr>
                <td className="d-none d-lg-table-cell">
                  <p className={Style.kortnavnText}>{grossist.grossistnr}</p>
                </td>
                <td>
                  <div>
                    <OverlayTrigger placement="top" delay={{ show: 300, hide: 200 }}
                      overlay={<Tooltip id="naviger-to-enhet-tooltip">Gå til underliggende enhet</Tooltip>}>
                      <Button variant="link" size="sm" onClick={() => goToEnhetClicked(grossist)}>
                        {`${grossist.kortnavn} (${grossist.grossistEnhetId})`}
                      </Button>
                    </OverlayTrigger>
                  </div>
                </td>
                <td className={Style.tableData}>
                  <p className={Style.kortnavnText}>
                    {grossist.navn}
                  </p>
                </td>
                <td className={Style.tableData}>
                  <p className={Style.kortnavnText}>
                    {DateHelper.formatDate(grossist.nedlagtdato)}
                  </p>
                </td>
                <td className={Style.tableData}>
                  <p className={Style.kortnavnText}>
                    {grossist.grossistgruppe}
                  </p>
                </td>
                <td className={Style.menuCell}>
                  <PopoverMenu
                    grossist={grossist}
                    handleShowLeggNedModal={handleShowLeggNedModal}
                    handleEdit={handleEditGrossist} />
                </td>
              </tr>
              {
                selectedGrossist && (
                  <GrossistEdit
                    grossist={selectedGrossist}
                    onUpdated={props.loadGrossister}
                    onClose={() => setSelectedGrossist(null)}
                  />
                )}
            </Fragment>
          ))}
      </tbody>
    </Table>
  )
}

export default GrossistTable;
