import Spinner from './Spinner';

interface Props {
  color?: string
  small?: boolean;
  size?: number;
}

const HollowDotSpinner = ({ color, small, size }: Props) => {
  return (
    <Spinner color={color ? color : '#3498db'} small={small} size={size} />
  );
}

export default HollowDotSpinner;