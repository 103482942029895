/*IMPORTS */

/*React & React module dependencies */
import React, { useEffect, useState, useCallback } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap';

/*Third-party modules */
import { format } from 'date-fns'

/*Shared components */
import DatePicker from '../DatePicker';

/*Components */
import BarChart from './components/BarChart'

/*Services */
import { BehandletStatistikk } from 'services/QAApi/types';
import QaApi from 'services/QAApi';

/*Styling */
import Style from './index.module.css';

/*IMPORTS END */


/*Interface */
interface Props {
  includeMonthAndYear?: boolean;
}

const QAStatistics: React.FC<Props> = props => {

  /*useState properties */
  const [data, setData] = useState<BehandletStatistikk | undefined>();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState<boolean>(false)


  /*functions */
  const loadApiData = useCallback(async () => {
    setLoading(true);
    const response = await QaApi.getBehandletStatistikk(format(startDate, 'dd.MM.yyyy'));
    setData(response);
    setLoading(false);
  }, [startDate]);


  /*useEffect */
  useEffect(() => {
    loadApiData();
  }, [startDate, loadApiData]);


  /*Content */
  if (!data) {
    return <div style={{ marginLeft: '50%' }}>
      <Spinner animation="border" />
    </div> // Loading animation from Bootstrap!
  }
  return (
    <div className={Style.main}>
      <div className={Style.datePicker}><DatePicker selected={startDate} onChange={(date: Date) => setStartDate(date)} adjustTimeForTimezoneOffset={true} />
        {loading ? <Spinner animation="border" /> : null}
      </div>
      <Row className={Style.statRow}>
        {data.periods.filter(o => o.group === "daily").map(period => {
          return (
            <Col md={12} lg={6} className={Style.statCol} key={period.periodName}>
              <Row>
                <Col>
                  <div>
                    <h3>{period.periodName}</h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className={Style.statusStats}>
                  <div className={Style.statContainer}>
                    <h1 className={Style.statHeader}>{period.antallAvist}</h1>
                    <h2 className={Style.statNameHeader}>Avviste</h2>
                  </div>
                  <div className={Style.statContainer}>
                    <h1 className={Style.statHeader}>{period.antallBehandlet}</h1>
                    <h2 className={Style.statNameHeader}>Behandlet</h2>
                  </div>
                </Col>
              </Row>
              <div>
                <BarChart data={period.details} />
              </div>
            </Col>);
        })}
        {props.includeMonthAndYear && data.periods.filter(o => o.group === "monthly").map(period => {
          return (
            <Col md={12} lg={6} className={Style.statCol} key={period.periodName}>
              <Row>
                <Col>
                  <div>
                    <h3>{period.periodName}</h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className={Style.statusStats}>
                  <div className={Style.statContainer}>
                    <h1 className={Style.statHeader}>{period.antallAvist}</h1>
                    <h2 className={Style.statNameHeader}>Avviste</h2>
                  </div>
                  <div className={Style.statContainer}>
                    <h1 className={Style.statHeader}>{period.antallBehandlet}</h1>
                    <h2 className={Style.statNameHeader}>Behandlet</h2>
                  </div>
                </Col>
              </Row>
              <div>
                <BarChart data={period.details} />
              </div>
            </Col>);
        })}
        {props.includeMonthAndYear && data.periods.filter(o => o.group === "yearly").map(period => {
          return (
            <Col md={12} lg={6} className={Style.statCol} key={period.periodName}>
              <Row>
                <Col>
                  <div>
                    <h3>{period.periodName}</h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className={Style.statusStats}>
                  <div className={Style.statContainer}>
                    <h1 className={Style.statHeader}>{period.antallAvist}</h1>
                    <h2 className={Style.statNameHeader}>Avviste</h2>
                  </div>
                  <div className={Style.statContainer}>
                    <h1 className={Style.statHeader}>{period.antallBehandlet}</h1>
                    <h2 className={Style.statNameHeader}>Behandlet</h2>
                  </div>
                </Col>
              </Row>
              <div>
                <BarChart data={period.details} />
              </div>
            </Col>);
        })}
      </Row>
    </div>
  )
}

export default QAStatistics