import { useState, useEffect } from 'react';
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';

import PageWrapper from 'components/PageWrapper';
import SortableTree from 'components/SortableTree';
import HollowDotsSpinner from 'components/HollowDotsSpinner';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import BransjeEditTab from './BransjeEditTab';
import CreateBransje from './CreateBransje';

import BransjeApi from 'services/KjederegisteretAdminApi/BransjeApi';
import { Bransje, BransjeTree } from 'services/KjederegisteretAdminApi/BransjeApi/types';
import DangerzoneTab from './DangerzoneTab';

const Bransjer = () => {
  const [bransjetre, setBransjetre] = useState<BransjeTree[]>([]);
  const [selectedBransje, setSelectedBransje] = useState<Bransje | undefined>();
  const [isAddBransje, setIsAddBransje] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState('editinfo');

  const loadBransjeTre = async () => {
    setIsLoading(true);
    const result = await BransjeApi.getTree();
    setBransjetre(result);
    setIsLoading(false);
  };

  const onSelectBransje = async (bransje: any) => {
    const result = await BransjeApi.getById(bransje.id);
    setSelectedBransje(result);
    setIsAddBransje(false);
  };

  const toggleAddBransje = () => {
    if (!isAddBransje) {
      setSelectedBransje(null);
    }
    setIsAddBransje((prev) => !prev);
  };

  const handleBransjeDeleted = () => {
    setSelectedBransje(undefined);
    loadBransjeTre();
  };

  const handleBransjeCreated = (bransje: Bransje) => {
    setSelectedBransje(bransje);
    loadBransjeTre();
  };

  const handleBransjeUpdated = (bransje: Bransje) => {
    setSelectedBransje(bransje);
    loadBransjeTre();
  };

  const handleBransjeNedlagt = (bransje: Bransje) => {
    setSelectedBransje(bransje);
    loadBransjeTre();
  };

  useEffect(() => {
    loadBransjeTre();
  }, []);

  return (
    <PageWrapper header="Administrasjon av Bransjer">
      <Row className='my-3 py-3'>
        <Col md={5} style={{ height: '90vh' }}>
          {bransjetre && (
            <SortableTree
              isSearchable
              data={bransjetre}
              onNodeClick={id => onSelectBransje(id)}
            />
          )}
        </Col>

        <Col>
          <Row>
            <Col>
              {!isAddBransje ? (
                <Button
                  variant="outline-primary"
                  onClick={toggleAddBransje}
                  className="float-end mb-2"
                >
                  <IcebergIcon icon="plus" bold /> Ny bransje
                </Button>
              ) : (
                <Button
                  variant="outline-primary"
                  onClick={toggleAddBransje}
                  className="float-end mb-2"
                >
                  <IcebergIcon icon="close" bold /> Avbryt
                </Button>
              )}
              {isAddBransje && <CreateBransje onCreated={handleBransjeCreated} />}
            </Col>
          </Row>
          <Row>
            {selectedBransje && (
              <>
                {!isLoading ? (
                  <Tabs defaultActiveKey="editinfo" className="my-3" activeKey={key} onSelect={(k) => setKey(k)}>
                    <Tab eventKey="editinfo" title="Rediger Bransje">
                      <BransjeEditTab bransje={selectedBransje} onUpdated={handleBransjeUpdated} />
                    </Tab>
                    <Tab eventKey="danger" title="Danger Zone">
                      <DangerzoneTab
                        isTabSelected={key === 'danger'}
                        onDeleted={handleBransjeDeleted}
                        bransje={selectedBransje}
                        onNedlagt={handleBransjeNedlagt}
                      />
                    </Tab>
                  </Tabs>
                ) : (
                  <HollowDotsSpinner />
                )}
              </>
            )}
          </Row>
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default Bransjer;
