import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner, ListGroup, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';

import AbonnementApi from 'services/KjederegisteretAdminApi/AbonnementApi';
import { Abonnement, UpdateAbonnementCommand } from 'services/KjederegisteretAdminApi/AbonnementApi/types';

import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';

/*Styling */
import Style from './index.module.css';


interface Props {
  abonnement: Abonnement;
  onUpdated: () => void;
  onDeleted: () => void;
}

const AbonnementInfo: React.FC<Props> = props => {
  const toaster = useToaster();

  const [showDeleteAbbModal, setShowDeleteAbbModal] = useState<boolean>(false);

  const initialValues: UpdateAbonnementCommand = {
    name: props.abonnement.name,
  }

  const validate = (values: UpdateAbonnementCommand) => {
    let errors: any = {};

    return errors;
  }

  /*Functions */
  const onSubmit = async (command: UpdateAbonnementCommand) => {
    try {
      await AbonnementApi.updateAbonnement(props.abonnement.id, command);
      toaster.success('Abonnement oppdatert', `Abonnement med id '${props.abonnement.id}', ble oppdatert.`,);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  };

  const { values, errors, loading, handleChange, handleSubmit } = useForm(initialValues, validate, onSubmit);

  const onDeleteClicked = async (selectedId: number) => {
    const res = await AbonnementApi.deleteAbonnement(selectedId);
    if (res) {
      setShowDeleteAbbModal(false);
      toaster.success('Suksess', 'Abbonnement ble slettet.');
      // let parent component know that this abonnement was deleted so that it can refresh the list
      props.onDeleted();
    } else {
      toaster.success('Feil', 'Kunne ikke slette abbonnement.');
      setShowDeleteAbbModal(false);
    }
  };

  useEffect(() => {
    handleChange('name', props.abonnement.name);
  }, [props.abonnement.id])

  const validMottakere = props.abonnement.mottakere.filter(x => x.tilOgMedDato === null);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group className='mb-3'>
          <Form.Label>Navn</Form.Label>
          <Form.Control 
            name="name" 
            type="text"
            value={values.name}
            onChange={e => handleChange('name', e.target.value)}
            isInvalid={errors?.name} />
          <Form.Control.Feedback type="invalid">{errors?.name}</Form.Control.Feedback>
        </Form.Group>
        <ListGroup variant="flush">
          <span>Mottakere</span>
          {
            validMottakere.map(y => 
              <ListGroup.Item key={y.id}>
                <span>{y.name}</span>
              </ListGroup.Item>
          )}
          <hr />
        </ListGroup>
        <Form.Group>
          <Button variant="primary" type="submit" disabled={loading || errors?.name || JSON.stringify(values) === JSON.stringify(initialValues)}>
            {
              loading && 
              <Spinner
                size="sm"
                style={{ marginRight: '.3em' }}
                animation="border"
                role="status" />
            }
            <span>Lagre</span>
          </Button>
          <OverlayTrigger
            overlay={
              validMottakere.length > 0 ? (
                <Tooltip id="tooltip-disabled">
                  Abonnement har mottakere og kan ikke slettes.
                </Tooltip>
              ) : (
                <Tooltip id="tooltip-active">Slett abonnement.</Tooltip>
              )
            }>
            <span className="d-inline-block">
              <Button
                className={`${validMottakere.length > 0 ? Style.isMottakereDeleteBtn : ''}`}
                disabled={validMottakere.length > 0}
                variant="outline-danger"
                style={{ marginLeft: '0.5rem' }}
                onClick={() => setShowDeleteAbbModal(true)}>
                Slett
              </Button>
            </span>
          </OverlayTrigger>

        </Form.Group>
      </Form>
      <Modal show={showDeleteAbbModal} onHide={() => setShowDeleteAbbModal(false)}>
        <Modal.Body>Er du sikker på at du vil slette abonnement?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteAbbModal(false)}>
            Lukk
          </Button>
          <Button variant="danger" onClick={() => onDeleteClicked(props.abonnement.id)}>
            Slett
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AbonnementInfo;