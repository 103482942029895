import { useEffect, useRef, useState } from "react";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import AdresseApi from 'services/KjederegisteretAdminApi/AdresseApi';
import { AddressSearchResult } from "services/KjederegisteretAdminApi/AdresseApi/types";
import { parseAsAddress } from 'utils/AddressHelper';
import useIsMountedRef from "utils/hooks/useIsMountedRef";
import Style from './index.module.css';
import { IAdresse } from "./types";

interface Props {
  onChange: (address: IAdresse) => void;
  selected?: IAdresse;
  clearTypeaheadInitiator?: string | number;
  allowNew?: boolean;
  isRequired?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  size?: "sm" | "lg";
  disabled?: boolean;
  className?: string;
}

const AddressTypeahead = (props: Props) => {
  const [searchResult, setSearchResult] = useState<IAdresse[]>([]);
  const [queryText, setQueryText] = useState<string>(props.selected?.formattedForOneLine || '');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formSize = props.size || null;
  const typeaheadRef = useRef(null);
  const isMountedRef = useIsMountedRef();

  const searchAddresses = async (query: string) => {
    setIsLoading(true);
    const foundAddresses = await AdresseApi.search(query);
    const mapped: IAdresse[] = foundAddresses?.map((x: AddressSearchResult) => {
      return {
        gateadresse: x.gateadresse || '',
        postnr: x.postnr || '',
        poststed: x.poststed || '',
        formattedForOneLine: x.formattedForOneLine || '',
        longitude: x.longitude || undefined,
        latitude: x.latitude || undefined,
        landkode: x.landkode || 'NO',
        isCustom: false,
      }
    });
    if (isMountedRef.current) {
      setSearchResult(mapped);
    }
    setIsLoading(false);
  }

  const handleSelectedChange = (addresses: IAdresse[]) => {
    const address = addresses[0];
    if (address) {
      if (address?.customOption) {
        const parsedAddress: { gateadresse: string, postnr: string, poststed: string } = parseAsAddress(queryText);
        const customAddress: IAdresse = {
          formattedForOneLine: queryText,
          gateadresse: parsedAddress.gateadresse,
          postnr: parsedAddress.postnr,
          poststed: parsedAddress.poststed,
          landkode: 'NO',
          latitude: null,
          longitude: null,
          customOption: true,
        }
        props.onChange(customAddress);
      } else {
        if (address) {
          address.customOption = false;
        }
        props.onChange(address);
      }
    }
    else {
      props.onChange(null);
    }
  }

  // hvis tomt søkeresultat og søketekst kan parses som en adresse, bruk søketekst som adresse
  const isAllowNew = () => {
    if (isMountedRef.current && props.allowNew && searchResult && queryText && parseAsAddress(queryText)) {
      const filtered = searchResult.filter(x => x.formattedForOneLine === queryText);
      if (filtered.length === 0 || searchResult.length === 0) {
        return true;
      }
    }
    return false;
  };

  const clearTypeahead = () => {
    if (isMountedRef.current && typeaheadRef.current) {
      typeaheadRef.current.clear();
      typeaheadRef.current.setState({ text: '' });
    }
  };

  useEffect(() => {
    if (isMountedRef.current && props.selected) {
      setQueryText(props.selected.formattedForOneLine);
    }
  }, [props.selected]);

  // do a search whenever queryText changes
  useEffect(() => {
    if (isMountedRef.current) {
      searchAddresses(queryText);
    }
  }, [queryText]);


  useEffect(() => {
    if (isMountedRef.current) {
      clearTypeahead();
    }
  }, [props.clearTypeaheadInitiator]);

  return (
    <AsyncTypeahead
      inputProps={{ required: props.isRequired || undefined }}
      className={`${Style.asyncTypeahead} ${props.className ?? ''}`}
      id="qaAddressTypeahead"
      size={formSize}
      labelKey="formattedForOneLine" // MÅ være string når allowNew er true
      useCache={false}
      minLength={3}
      clearButton
      isLoading={isLoading}
      options={searchResult}
      searchText="Søker"
      placeholder="Søk etter adresse..."
      promptText="Skriv for å søke"
      emptyLabel="Fant ingen treff"
      maxResults={10}
      onChange={handleSelectedChange} // whenever a option is selected
      onSearch={setQueryText} // søketekst
      ref={typeaheadRef}
      selected={props.selected ? [props.selected] : []}
      isValid={props.isValid}
      isInvalid={props.isInvalid}
      allowNew={isAllowNew}
      newSelectionPrefix="Opprett ny: "
      disabled={props.disabled}
    />
  )
}

export default AddressTypeahead;
