import { useEffect, useState } from "react";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { debounce } from "lodash";
import { Mottaker } from "services/KjederegisteretAdminApi/MottakerApi/types";
import MottakerApi from "services/KjederegisteretAdminApi/MottakerApi";

interface Props {
    onHandleChange: (mottaker: Mottaker) => void;
    isInvalid?: boolean;
    placeholderText?: string;
}

const MottakerTypeahead = (props: Props) => {
    const [query, setQuery] = useState('');
    const [searchResult, setSearchResult] = useState<Mottaker[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMottaker, setSelectedMottaker] = useState<Mottaker | undefined>();

    const searchMottaker = async () => {
        setIsLoading(true);
        const response = await MottakerApi.get(query, true);
        setSearchResult(response);
        setIsLoading(false);
    };

    useEffect(() => {
        if (query && query.length > 2) {
            searchMottaker();
        }
    }, [query]);

    const handleQueryChanged = (e: Mottaker[]) => {
        if (e.length === 0) {
            props.onHandleChange(undefined);
            setSelectedMottaker(undefined);
        } else {
            props.onHandleChange(e[0]);
            setSelectedMottaker(e[0]);
        }
    };

    return (
        <AsyncTypeahead
            id='mottakerTypeahead'
            clearButton
            labelKey='name'
            minLength={0}
            delay={100}
            isLoading={isLoading}
            options={searchResult}
            searchText='Søker'
            placeholder={props.placeholderText || 'Søk etter mottaker'}
            promptText='Skriv inn for å søke'
            emptyLabel='Fant ingen treff'
            useCache={false}
            maxResults={10}
            onInputChange={debounce(e => setQuery(e), 300)}
            onChange={(e: any) => handleQueryChanged(e)}
            onSearch={debounce(q => setQuery(q), 300)}
            selected={selectedMottaker ? [selectedMottaker] : []}
            isInvalid={props.isInvalid}
        />
    );
};


export default MottakerTypeahead;