import axios from 'axios';
import { BulkJobb, JobbDetails, ByttAdresseCommand, ByttFakturaEpostCommand, ByttKontonummerCommand, FlyttEnheterTilNyHovedgrossistCommand, MeldEnheterInnKjedeCommand } from './types';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/enheterbulk';

async function getLastJobs(): Promise<BulkJobb[]> {
  const response = await axios.get(`${baseUrl}`);
  return response.data;
}

async function getById(id: string): Promise<JobbDetails> {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
}

async function byttAdresse(command: ByttAdresseCommand) {
  return await axios.post(`${baseUrl}/ByttAdresse`, command);
}

async function byttFakturaEpost(command: ByttFakturaEpostCommand) {
  return await axios.post(`${baseUrl}/ByttFakturaEpost`, command);
}

async function byttKontonummer(command: ByttKontonummerCommand) {
  return await axios.post(`${baseUrl}/ByttKontonummer`, command);
}

async function flyttTilNyHovedgrossist(command: FlyttEnheterTilNyHovedgrossistCommand) {
  return await axios.post(`${baseUrl}/FlyttEnheterTilNyHovedgrossist`, command);
}

async function meldEnheterInnKjede(command: MeldEnheterInnKjedeCommand) {
  return await axios.post(`${baseUrl}/MeldEnheterInnKjede`, command);
}

const EnheterBulkApi = {
  getLastJobs,
  getById,
  byttAdresse,
  byttFakturaEpost,
  byttKontonummer,
  flyttTilNyHovedgrossist,
  meldEnheterInnKjede
}

export default EnheterBulkApi;
