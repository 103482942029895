import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'components/DatePicker';
import Style from './index.module.css';

interface Props {
  setNedlagtDato: (date: Date) => void;
}

const LeggNedGrossist: React.FC<Props> = props => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (date) {
      props.setNedlagtDato(date);
    } else {
      props.setNedlagtDato(null);
    }
  }, [date]);

  return (
    <div className={Style.container}>
      <h5 className={Style.exampleHeader}>Legg ned hovedgrossist</h5>
      <p>Grossisten har ingen tilknyttede enheter og kan legges ned.</p>
      <Form>
        <Form.Group>
          <Form.Label>Dato for nedleggelse</Form.Label>
          <DatePicker placeholderText="Fra dato" selected={date} onChange={setDate} adjustTimeForTimezoneOffset={true} />
        </Form.Group>
      </Form>
    </div>
  );
};

export default LeggNedGrossist;
