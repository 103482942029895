export default class HttpErrorHelper {

  /**
   * function handleErrorMessage
   * 
   * Used to handle and correctly interpret errors, mainly through try/catch
   * @param msg received error object
   * @returns array of errors
   */
  static handleErrorMessage = (msg: any): string | { key: string; errors: string[] }[] => {
    let arr: { key: string; errors: string[] }[] = [];
    console.log(msg);
    if (msg && msg.response && msg.response.status === 400) {
      console.log('Error: ', msg.response);
      // errors returned from commandValidator
      if (msg.response.data.errors.length > 0) {
        for (const [key, value] of Object.entries(msg.response.data.errors)) {
          if (key !== 'DomainValidations') {
            if (Array.isArray(value)) {
              arr.push({ key: key, errors: value });
            } else if(value instanceof String) {
              arr.push({ key: key, errors: [value.toString()] });
            }
          }
        }
      }
      else // errors returned from commandhandler
      {
        for (const [key, value] of Object.entries(msg.response.data.errors)) {
          if (Array.isArray(value)) {
            arr.push({ key: '', errors: value });
          } else if(value instanceof String){
            arr.push({ key: '', errors: [value.toString()] });
          }
        }
      }
    } else {
      arr = [{ key: 'Serverfeil', errors: [msg.response.statusText] }];
    }

    return arr;
  };
}
