import React, { useState } from 'react';
import { Form, Modal, Button, Badge, Spinner } from 'react-bootstrap';
import EnhetTypeahead from 'components/EnhetTypeahead';
import { PickedEnhet } from 'components/EnhetTypeahead/types';
import Style from './index.module.css';

interface Props {
  show?: boolean;
  enhetnavn: string;
  handleClose: () => void;
  handleSave: (dublettAvId: number) => void;
  utforIsLoading: boolean;
  duplikatAvLopenummer: string;
}

const DuplikatModal: React.FC<Props> = props => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [dublettAvId, setDublettAvId] = useState<number | undefined>();
  const [dublettAvEnhetnavn, setDublettAvEnhetnavn] = useState<string | undefined>();

  const onChange = (enhet: PickedEnhet) => {
    if (enhet && enhet.id) {
      setIsSubmitEnabled(true);
      setDublettAvId(enhet.id);
      setDublettAvEnhetnavn(enhet.name);
    } else {
      setIsSubmitEnabled(false);
      setDublettAvId(null);
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Marker enhet som duplikat</Modal.Title>
        </Modal.Header>
        {!props.duplikatAvLopenummer && (
          <Modal.Body>
            <p>
              <Badge bg="warning">
                Duplikat markering kan ikke reverseres på en enkel måte enda, så vær AKTSOM
              </Badge>
            </p>
            <Form.Group>
              <Form.Label>Velg enheten som {props.enhetnavn} er duplikat av:</Form.Label>
              <EnhetTypeahead onChange={enhet => onChange(enhet)} />
              <Form.Text>
                {dublettAvId && dublettAvEnhetnavn && (
                  <div>
                    <span>Enhet: </span>
                    <Button
                      className={Style.linkToEnhetBtn}
                      size="sm"
                      variant="link"
                      onClick={() => window.open(`${dublettAvId}`, '_blank')}
                    >
                      {dublettAvEnhetnavn}
                    </Button>
                  </div>
                )}
              </Form.Text>
            </Form.Group>
          </Modal.Body>
        )}
        {props.duplikatAvLopenummer && (
          <Modal.Body>
            <Form>
              <Form.Label>
                {props.enhetnavn} er allerede markert som duplikat av løpenummer {props.duplikatAvLopenummer}.
                Ta kontakt med utvikling hvis det er ønskelig å endre dette (noe må utvikles eller
                fikses i DB).
              </Form.Label>
            </Form>
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Lukk
          </Button>
          <Button
            className={Style.spinnerBtn}
            variant="danger"
            disabled={!isSubmitEnabled}
            onClick={() => props.handleSave(dublettAvId)}>
            <span>Utfør</span>
            {props.utforIsLoading && (
              <Spinner
                className={Style.spinner}
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DuplikatModal;
