import React, { useState, useEffect } from 'react';
import { InputGroup, Form, Button, OverlayTrigger } from 'react-bootstrap';

import BrregTypeahead from 'components/BrregTypeAhead';
import BrregPopover from 'components/BrregPopover';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import { BrregEnhetResponse } from 'services/KjederegisteretAdminApi/BrregApi/types';

import useIsMountedRef from 'utils/hooks/useIsMountedRef';

import Style from './index.module.css';

interface Props {
  type: string;
  selectedJuridisk: { name: string; orgNr: string; }[];
  clearTypeaheadInitiator: string | number;
  id: string;
  setSelectedJuridisk: (juridisk: { name: string; orgNr: string; }[]) => void;
  onUpdateForm: (type: string, name: string, orgNr: string) => void;
  errors?: any;
}

const BrregInput: React.FC<Props> = props => {
  const isMountedRef = useIsMountedRef();
  const [isLoadingBrreg, setIsLoadingBrreg] = useState<boolean>(false);
  const [showNotFoundWarning, setShowNotFoundWarning] = useState<boolean>(false);
  const [brregInfo, setBrregInfo] = useState<BrregEnhetResponse | undefined>(undefined);

  const handleBrregSelectedChange = (e: BrregEnhetResponse) => {
    if (e) {
      if (e.name && e.orgNr) {
        setBrregInfo(e);
        props.onUpdateForm(props.type, e.name, e.orgNr);
        const selected: { name: string; orgNr: string } = {
          name: e.name.toUpperCase(),
          orgNr: e.orgNr,
        };
        if (e.name !== props.selectedJuridisk[0]?.name && e.orgNr !== props.selectedJuridisk[0]?.orgNr) {
          props.setSelectedJuridisk([selected]);
        }
      }
    } else {
      props.setSelectedJuridisk([]);
      setBrregInfo(undefined);
      props.onUpdateForm(props.type, null, null);
    }
  }

  useEffect(() => {
    if (isMountedRef.current && brregInfo && (brregInfo.finnesIkke || brregInfo.slettedato) && (props.selectedJuridisk && props.selectedJuridisk[0]) && !isLoadingBrreg) {
      setShowNotFoundWarning(true);
    } else {
      setShowNotFoundWarning(false);
    }
  }, [brregInfo, props.selectedJuridisk, isLoadingBrreg]);

  return (
    <>
      <Form.Label className={Style.juridiskFormLabel}>{props.type === 'bedriftsnr' ? 'Juridisk navn og bedriftsnummer' : 'Juridisk navn og organisasjonsnummer'}</Form.Label>
      <InputGroup id={props.id}>
        {!brregInfo ? (
          <InputGroup.Text style={{ paddingLeft: '.5rem', paddingRight: '.5rem' }}>
            <IcebergIcon icon="search" />
          </InputGroup.Text>
        ) : (
          <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={BrregPopover(brregInfo)}>
            {brregInfo.slettedato || brregInfo.finnesIkke ? (
              <Button variant="warning" id="button-addon1" style={{ paddingLeft: '.4rem', paddingRight: '.4rem' }}>
                <IcebergIcon cursor="pointer" icon="check" size="lg" bold />
              </Button>
            ) : (
              <Button variant="success" id="button-addon1" style={{ paddingLeft: '.4rem', paddingRight: '.4rem' }}>
                <IcebergIcon cursor="pointer" icon="check" size="lg" bold />
              </Button>
            )}
          </OverlayTrigger>
        )}
        <BrregTypeahead
          selected={(props.selectedJuridisk && props.selectedJuridisk.length > 0) ? props.selectedJuridisk : []}
          clearTypeaheadInitiator={props.clearTypeaheadInitiator}
          onBrregEnhetSelected={e => handleBrregSelectedChange(e)}
          setIsLoadingBrreg={setIsLoadingBrreg}
        />
      </InputGroup>
      {showNotFoundWarning && (<Form.Text className={Style.brregWarningFormText}>
        <span>Ingen funn på eksisterende juridisk navn og organisasjonsnummer kombinasjon. </span>
      </Form.Text>)}
      {props.errors?.bedriftsnr &&
        <Form.Text className={Style.brregErrorFormText}>
          {props.errors.bedriftsnr}
        </Form.Text>}
    </>
  )
}

export default BrregInput;
