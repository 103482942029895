import { useEffect, useState } from "react";
import { mapGrossistGruppeNode } from "./types";
import { Grossist } from "services/KjederegisteretAdminApi/GrossisterApi/types";
import DropdownTreeSelect, { Mode, TreeNode } from "react-dropdown-tree-select";
import GrossisterApi from "services/KjederegisteretAdminApi/GrossisterApi";
import { Spinner } from "react-bootstrap";
import Style from './index.module.css';

interface Props {
    onSelectionChanged: (grossistGln: string[]) => void;
    findSelectedGrossists: () => string[];
    disabled?: boolean;
    isSmall?: boolean;
    closeOnSelect?: boolean;
    mode?: Mode;
}

const GrossistgruppePicker = (props: Props) => {
    const [grossisterList, setGrossisterList] = useState<Grossist[]>([]);
    const [isFetchingGrossister, setIsFetchingGrossister] = useState(false);

    const loadGrossister = async () => {
        setIsFetchingGrossister(true);
        const grossister = await GrossisterApi.getGrossister(null, true);
        setGrossisterList(grossister);
        setIsFetchingGrossister(false);
    };

    useEffect(() => {
        loadGrossister();
    }, []);

    const handleSelectionChanged = (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
        const grossistGln: string[] = [];
        selectedNodes.forEach(element => {
            // er dette en gruppe? Vi sjekker om dette er et gln nummer eller ikke
            if (element.value && element.value.length != 13) {
                grossisterList.forEach(g => {
                    if (g.grossistgruppe === element.value) {
                        grossistGln.push(g.gln.toString());
                    }
                })
            } else {
                if (element.value) {
                    grossistGln.push(element.value);
                }
            }
        });
        props.onSelectionChanged(grossistGln);
    };

    const selectedGrossister = props.findSelectedGrossists();

    // map bransje tre til en struktur react-dropdown-tree-select skjønner
    const data = mapGrossistGruppeNode(grossisterList, selectedGrossister);

    return (
        <>
            <DropdownTreeSelect
                className={Style.dropdown}
                mode={props.mode || 'multiSelect'}
                texts={{
                    placeholder: 'Velg grossister',
                    noMatches: 'Ingen treff',
                }}
                data={data || []}
                keepTreeOnSearch
                keepChildrenOnSearch
                showPartiallySelected
                onChange={handleSelectionChanged}
                disabled={props.disabled || false}
            />
            {isFetchingGrossister && (
                <div>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    <span> Henter data... </span>
                </div>
            )}
        </>
    );
};

export default GrossistgruppePicker; 
