import { isNumber, isNaN } from 'lodash';

export class Coordinates {
  lat;

  lng;

  constructor(lat, lng) {
    this.lat = lat;
    this.lng = lng;
  }

  isValid() {
    if (!this.lat || !isNumber(this.lat) || isNaN(this.lat)) return false;
    if (!this.lng || !isNumber(this.lng) || isNaN(this.lng)) return false;
    return true;
  }

  isSame(coordinates) {
    return coordinates.lat === this.lat && coordinates.lng === this.lng;
  }

  static parse(data) {
    if (!data) {
      throw new Error('data is missing');
    }
    return new Coordinates(data.lat, data.lng);
  }
}
