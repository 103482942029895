import axios from 'axios';
import { 
  Abonnement, AbonnementQuery, CreateAbonnementCommand, CreateAbonnementResponse, 
  UpdateAbonnementCommand, UpdateAbonnementBransjerCommand, UpdateAbonnementKjederCommand,
  UpdateAbonnementResponse } from './types';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/abonnement';

async function getAbonnement(parameters: AbonnementQuery): Promise<Abonnement[]> {
  let url = `${baseUrl}?skip=${parameters.skip}&take=${parameters.take}`;
  if (parameters.q) {
    url += `&query=${parameters.q}`;
  }
  const response = await axios.get(url);
  return response.data;
};

async function createAbonnement(command: CreateAbonnementCommand): Promise<CreateAbonnementResponse> {
  const response = await axios.post(`${baseUrl}`, command);
  return response.data;
}

async function updateAbonnement(id: number, command: UpdateAbonnementCommand): Promise<UpdateAbonnementResponse> {
  const response = await axios.put(`${baseUrl}/${id}`, command);
  return response.data;
}

async function updateAbonnementBransjer(id: number, command: UpdateAbonnementBransjerCommand): Promise<UpdateAbonnementResponse> {
  const response = await axios.put(`${baseUrl}/${id}/Bransjer`, command);
  return response.data;
}

async function updateAbonnementKjeder(id: number, command: UpdateAbonnementKjederCommand): Promise<UpdateAbonnementResponse> {
  const response = await axios.put(`${baseUrl}/${id}/Kjeder`, command);
  return response.data;
}

async function deleteAbonnement(id: number) {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
}

const AbonnementApi = {
  getAbonnement,
  createAbonnement,
  updateAbonnement,
  updateAbonnementBransjer,
  updateAbonnementKjeder,
  deleteAbonnement,
}

export default AbonnementApi;