
/*IMPORTS */

/*React & React module dependencies */
import React, { useState, useEffect } from 'react';
import { Row, Col, InputGroup, ListGroup, FormControl, Button } from 'react-bootstrap';

/*Shared Components */
import PageWrapper from 'components/PageWrapper';
import HollowDotsSpinner from 'components/HollowDotsSpinner';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

/*other Abonnement Components */
import AbonnementCard from './components/AbonnementCard';
import CreateAbonnement from './components/CreateAbonnement';

/* hooks */
import useIsMountedRef from '../../utils/hooks/useIsMountedRef';

/*Services */
import { Abonnement, AbonnementQuery } from '../../services/KjederegisteretAdminApi/AbonnementApi/types';
import AbonnementApi from '../../services/KjederegisteretAdminApi/AbonnementApi';

/*Styling */
import Style from './index.module.css';


/*IMPORTS END */

const AbonnementPage = () => {

  /*useState properties */
  const [query, setQuery] = useState<string>('');
  const [selectedAbonnement, setSelectedAbonnement] = useState<Abonnement>(null);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [abonnementList, setAbonnementList] = useState<Abonnement[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isMountedRef = useIsMountedRef();

  /*FUNCTIONs */
  const loadAbonnementWithSpinner = async () => {
    setIsLoading(true);
    const result = await AbonnementApi.getAbonnement(new AbonnementQuery());
    setIsLoading(false);
    if (isMountedRef.current) {
      setAbonnementList(result);
    }
  }

  const loadAbonnementWithoutSpinner = async (id?: number) => {
    const result = await AbonnementApi.getAbonnement(new AbonnementQuery());
    if (isMountedRef.current) {
      setAbonnementList(result);
      if (id) {
        setSelectedAbonnement(result.find(x => x.id === id));
      }
    }
  };

  const handleOpprettClicked = () => {
    setIsCreate(!isCreate);
    setSelectedAbonnement(null);
  }

  const handleSelected = (abb: Abonnement) => {
    setIsCreate(false);
    if (isMountedRef.current) {
      setSelectedAbonnement(abb);
    }
  };

  const handleAbonnementDeleted = async () => {
    setSelectedAbonnement(null);
    await loadAbonnementWithoutSpinner();
  };

  /*useEffect */
  // on initial pageload we fetch the list of abonnement
  useEffect(() => {
    loadAbonnementWithSpinner();
  }, []);

  /* Content */

  return (
    <PageWrapper isFluid header="Abonnement">
      <Row>
        <Col md={12} lg={5} xl={5}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '1em' }}>
            <InputGroup className='mb-3'>
              <FormControl
                value={query}
                placeholder="Søk her.."
                disabled={isLoading}
                style={{ borderRight: 'hidden', borderRadius: '24px 0px 0px 24px' }}
                autoFocus
                onChange={e => setQuery(e.target.value)}
              />
              <InputGroup.Text style={{ background: 'white', borderLeft: 'hidden', borderRadius: '0px 24px 24px 0px' }}>
                {
                  query.length > 0 ?
                    <IcebergIcon icon='close' cursor='pointer'
                      onClick={() => setQuery('')} />
                    : <IcebergIcon icon='search' />
                }
              </InputGroup.Text>
            </InputGroup>
          </div>
          {isLoading ? (
            <div className={Style.isLoadingContainer}>
              <HollowDotsSpinner />
              <h6>Laster abonnementer...</h6>
            </div>
          ) : (
            <ListGroup defaultActiveKey={undefined} style={{ height: '80vh', overflowY: 'auto' }}>
              {abonnementList && abonnementList.filter(o => o.name.toLowerCase().includes(query.toLowerCase())).map((x: Abonnement) => (
                <ListGroup.Item key={x.id} onClick={() => handleSelected(x)} active={x.id === selectedAbonnement?.id}>
                  <Row>
                    <Col>
                      <span>{x.name}</span>
                    </Col>
                    <Col md={5}>
                      <span>
                        {
                          x.bransjer?.length > 0 &&
                          <span className={Style.desc}>{x.bransjer.length} {x.bransjer.length > 1 ? 'Bransjer' : 'Bransje'}</span>
                        }
                        {
                          x.kjeder?.length > 0 &&
                          <span className={Style.desc}>{x.bransjer?.length > 0 && ','} {x.kjeder.length} {x.kjeder.length > 1 ? 'Kjeder' : 'Kjede'}</span>
                        }
                      </span>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}

        </Col>
        <Col>
          <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '1em' }}>
            <Button
              variant="outline-primary"
              disabled={isLoading}
              onClick={() => handleOpprettClicked()}>
              {
                !isCreate ?
                  <>
                    <IcebergIcon icon='plus' bold />
                    <span style={{ paddingLeft: '0.3em' }}>Nytt abonnement</span>
                  </> :
                  'Avbryt'
              }
            </Button>
          </div>
          {
            isCreate &&
            <CreateAbonnement
              setShowCreate={setIsCreate}
              loadAbonnement={loadAbonnementWithoutSpinner} />
          }
          {
            selectedAbonnement &&
            <AbonnementCard
              abonnement={selectedAbonnement}
              onAbonnementDeleted={handleAbonnementDeleted}
              loadAbonnement={loadAbonnementWithoutSpinner} />
          }
        </Col>
      </Row>
    </PageWrapper>
  );
}

export default AbonnementPage;
