import BransjePicker, { PickedBransje } from 'components/BransjePicker';
import BrregInput from './BrregInput';
import CountryPicker from 'components/CountryPicker';
import DatePicker from 'components/DatePicker';
import EnhetTypeMultiPicker from 'components/EnhetTypeMultiPicker';
import { PickedType } from 'components/EnhetTypeMultiPicker/types';
import { addMinutes, format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import { UpdateEnhetCommand } from 'services/KjederegisteretAdminApi/EnhetApi/Commands';
import { EnhetTyperViewModel, EnhetViewModel, KontonrOgFakturaAdresseSjekkResponse } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import useForm from 'utils/hooks/useForm';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import RemoveSpaceAndDot from 'utils/RemoveSpaceAndDot';
import Style from './index.module.css';
import { validateKontonummerMod11 } from 'pages/EnhetSearch/utils';
import ElmaApi from 'services/KjederegisteretAdminApi/ElmaApi';


interface Props {
  enhet: EnhetViewModel;
  onUpdated: () => void;
  linkedForm: { tab: string, field: string };
  kontonrOgFakturaAdresseSjekkResponse?: KontonrOgFakturaAdresseSjekkResponse;
}

const Basis = (props: Props) => {
  const toaster = useToaster();
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [selectedJuridisk, setSelectedJuridisk] = useState<{ name: string; orgNr: string }[]>(
    props.enhet.firma.juridisknavn && props.enhet.firma.organisasjonsnr ? [
      { name: props.enhet.firma.juridisknavn, orgNr: props.enhet.firma.organisasjonsnr },
    ] : []);
  const [selectedJuridiskBedrift, setSelectedJuridiskBedrift] = useState<{ name: string; orgNr: string }[]>(
    props.enhet.firma.juridisknavnBedrift && props.enhet.firma.bedriftsnr ? [
      { name: props.enhet.firma.juridisknavnBedrift, orgNr: props.enhet.firma.bedriftsnr },
    ] : []);
  const [isBedriftsnummerInElma, setIsBedriftsnummerInElma] = useState<boolean | undefined>(undefined);
  const [postFrom, setPostFrom] = useState<string>(
    format(new Date(props.enhet.firma.postFraOgMedDato), 'dd.MM.yyyy'),
  );
  const [pickedBransjeID, setPickedBransjeID] = useState<number | undefined>(props.enhet.bransje?.id);
  const [pickedEnhetTypeKoder, setPickedEnhetTypeKoder] = useState<string[]>(props.enhet.enhetTyper?.map((x: EnhetTyperViewModel) => x.kode));
  const [pickerResetIndicator, setPickerResetIndicator] = useState<string>();
  const isMountedRef = useIsMountedRef();

  const [førsteMuligeOppstartDato, setFørsteMuligeOppstartDato] = useState<Date | undefined>();

  const loadFørsteMuligeOppstartDato = async (enhetId: number) => {
    const dato = await EnhetApi.førsteMuligeOppstartDato(enhetId);
    setFørsteMuligeOppstartDato(dato);
  };

  const defaultValues: UpdateEnhetCommand = {
    enhetnavn: props.enhet.enhetnavn,
    epost: props.enhet.firma.epost?.trim(),
    fakturaepost: props.enhet.firma.fakturaEpost?.trim(),
    erOffentlig: props.enhet.firma.erOffentlig,
    erSesongDrift: props.enhet.firma.erSesongdrift,
    reservertMotPost: props.enhet.firma.reservertMotPost,
    kanMottaEfaktura: props.enhet.firma.kanMottaEfaktura,
    id: props.enhet.id,
    juridisknavn: props.enhet.firma.juridisknavn,
    kontonr: props.enhet.firma.kontonr,
    landKode: props.enhet.firma.landkode,
    notat: props.enhet.notat,
    organisasjonsnummer: props.enhet.firma.organisasjonsnr,
    juridisknavnBedrift: props.enhet.firma.juridisknavnBedrift,
    bedriftsnummer: props.enhet.firma.bedriftsnr,
    telefon: props.enhet.firma.telefon,
    omsetningAar: props.enhet.omsetning?.year,
    omsetningMillioner: props.enhet.firma.omsetningMillioner,
    oppstartsdato: props.enhet.firma.oppstartsdato,
    postFraOgMedDato: props.enhet.firma.postFraOgMedDato,
    salgsArealKvadratmeter: props.enhet.firma.salgsArealKvadratmeter,
    bransjeId: props.enhet.bransje?.id,
    enhetTypeKoder: props.enhet.enhetTyper?.map((x: EnhetTyperViewModel) => x.kode),
    hovedEnhetTypeKode: props.enhet.enhetTyper?.find((x: EnhetTyperViewModel) => x.erHovedtype)?.kode,
  };

  const validate = () => {
    const errors: any = {};
    if (!formData.values?.enhetnavn) errors.enhetnavn = 'Enhetnavn mangler!';
    if (formData.values?.telefon?.length > 8) errors.telefon = 'Telefonnummer må være 8 tegn eller mindre';
    if (formData.values?.telefon && (!/^\d+$/.test(formData.values?.telefon))) errors.telefon = 'Telefonnummer kan kun bestå av tall';
    if (formData.values?.epost && !isEmailValid(formData.values?.epost)) errors.epost = 'Epost må være gyldig';
    if (formData.values?.fakturaepost && !isEmailValid(formData.values?.fakturaepost)) errors.fakturaepost = 'Epost må være gyldig';
    if (formData.values?.kontonr && !validateKontonummerMod11(formData.values?.kontonr)) errors.kontonr = 'Kontonummer må være gyldig';
    if ((selectedJuridisk && selectedJuridisk[0] && selectedJuridiskBedrift && selectedJuridiskBedrift[0] &&
      selectedJuridisk[0].orgNr.trim() === selectedJuridiskBedrift[0].orgNr.trim())) errors.bedriftsnr = 'Organisasjonsnummer og bedriftsnummer kan ikke være like';
    return errors;
  };

  const isEmailValid = (email: string) => {
    if (!email)
      return true;
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(email)) {
      return false;
    }
    return true;
  }

  const onSubmit = async (values: UpdateEnhetCommand) => {
    if (values.oppstartsdato) {
      values.oppstartsdato = addMinutes(values.oppstartsdato, -values.oppstartsdato.getTimezoneOffset());
    }

    if (values.postFraOgMedDato) {
      values.postFraOgMedDato = addMinutes(values.postFraOgMedDato, -values.postFraOgMedDato.getTimezoneOffset());
    }

    setIsSaveLoading(true);
    try {
      await EnhetApi.updateEnhet(values);
      toaster.success('Suksess', 'Enhet oppdatert!');
      setIsSaveLoading(false);
      formData.resetForm(values);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
      setIsSaveLoading(false);
    }
  };

  const updateFormBrregHandler = (type: string, name: string, orgNr: string) => {
    const copy = { ...formData.values };
    if (type === 'orgnr') {
      copy.organisasjonsnummer = orgNr;
      copy.juridisknavn = name ? name.toUpperCase() : null;
    } else if (type === 'bedriftsnr') {
      copy.bedriftsnummer = orgNr;
      copy.juridisknavnBedrift = name ? name.toUpperCase() : null;
    }
    formData.updateValues(copy);
  }

  // remove spaces and dots from number strings (kontonr, tlfnr)
  const handleInputChangeClean = (field: string, val: string) => {
    const result = RemoveSpaceAndDot(val);
    formData.handleChange(field, result);
  }

  const handleInputChange = (field: string, val: string) => {
    formData.handleChange(field, val);
  }

  const formData = useForm<UpdateEnhetCommand, any>(defaultValues, validate, onSubmit);

  const checkPostDate = () => {
    const dateOffset = (24 * 60 * 60 * 1000) * 14; //14 days in ms
    const oppstart = new Date(formData.values.oppstartsdato); // base on oppstart
    const postDate = new Date(oppstart.getTime() - dateOffset); // subtract offsett ms from oppstart ms

    if (formData.touched.oppstartsdato) {
      setPostFrom(format(postDate, 'dd.MM.yyyy'));
    }
  };

  // bransje
  const handlePickedBransjeChanged = useCallback((val: PickedBransje[]) => {
    if (val?.length > 0) {
      setPickedBransjeID(val[0].id);
    } else {
      setPickedBransjeID(undefined);
    }
  }, []);

  const handleOppstartDatoChange = (date: Date) => {
    const copy = { ...formData.values };
    copy.oppstartsdato = date || null;
    formData.updateValues(copy);
  }

  const handleSelectedTyperChanged = (vals: PickedType[]) => {
    setPickedEnhetTypeKoder(vals.map(x => x.kode) || []);
  };

  useEffect(() => {
    if (selectedJuridiskBedrift && selectedJuridiskBedrift.length === 1) {
      (async () => {
        const result = await ElmaApi.isRegistered(selectedJuridiskBedrift[0].orgNr);
        setIsBedriftsnummerInElma(result);
      })();
    }
    else {
      setIsBedriftsnummerInElma(undefined);
    }
  }, [selectedJuridiskBedrift])

  useEffect(() => {
    if (isMountedRef.current) {
      formData.handleChange('bransjeId', pickedBransjeID);
    }
  }, [pickedBransjeID]);

  useEffect(() => {
    if (defaultValues) {
      formData.resetForm(defaultValues);
      if (props.enhet.firma.juridisknavn && props.enhet.firma.organisasjonsnr) {
        if (selectedJuridisk[0]?.name !== props.enhet.firma.juridisknavn || selectedJuridisk[0]?.orgNr !== props.enhet.firma.organisasjonsnr) {
          setSelectedJuridisk([
            { name: props.enhet.firma.juridisknavn, orgNr: props.enhet.firma.organisasjonsnr },
          ])
        }
      } else {
        setSelectedJuridisk([]);
      }
      if (props.enhet.firma.juridisknavnBedrift && props.enhet.firma.bedriftsnr) {
        if (selectedJuridiskBedrift[0]?.name !== props.enhet.firma.juridisknavnBedrift && selectedJuridiskBedrift[0]?.orgNr !== props.enhet.firma.bedriftsnr) {
          setSelectedJuridiskBedrift([
            { name: props.enhet.firma.juridisknavnBedrift, orgNr: props.enhet.firma.bedriftsnr },
          ])
        }
      } else {
        setSelectedJuridiskBedrift([]);
      }
    }
    loadFørsteMuligeOppstartDato(props.enhet.id);
    setPickedBransjeID(props.enhet.bransje?.id);
  }, [props.enhet]);

  useEffect(() => {
    if (props.linkedForm.field) {
      document.getElementById(props.linkedForm.field).focus();
    }
  }, [props.linkedForm]);

  useEffect(() => {
    if (props.enhet.firma.reservertMotPost &&
      !props.enhet.firma.oppstartsdato &&
      formData.values.oppstartsdato &&
      formData.touched.oppstartsdato) {
      formData.handleChange('reservertMotPost', false);
    }
    checkPostDate();
  }, [formData.values.oppstartsdato]);

  useEffect(() => {
    if (formData.values.juridisknavn === defaultValues.juridisknavn && formData.values.organisasjonsnummer === defaultValues.organisasjonsnummer) {
      formData.resetValues(['juridisknavn', 'organisasjonsnummer']);
    }
  }, [formData.values.juridisknavn, formData.values.organisasjonsnummer]);

  useEffect(() => {
    if (isMountedRef.current) {
      formData.handleChange('enhetTypeKoder', pickedEnhetTypeKoder);
    }
  }, [pickedEnhetTypeKoder]);


  useEffect(() => {
    if (formData && formData.values?.enhetTypeKoder?.length === 1) {
      formData.handleChange('hovedEnhetTypeKode', formData.values.enhetTypeKoder[0]);
    }
  }, [formData.values.enhetTypeKoder]);

  useEffect(() => {
    setPickerResetIndicator(props.enhet.lopenr);
  }, [props.enhet.lopenr]);

  return (
    <>
      {formData.values ? (
        <Form onSubmit={formData.handleSubmit}>
          <Row>
            <Form.Group className={Style.formGroup} as={Col} sm={6}>
              <Form.Label>Enhetnavn</Form.Label>
              <Form.Control
                id='enhetnavn'
                type="text"
                name="text"
                value={formData.values.enhetnavn || ''}
                onChange={e => handleInputChange('enhetnavn', e.target.value)}
                isInvalid={formData.touched.enhetnavn && formData.errors.enhetnavn}
                isValid={formData.touched.enhetnavn && !formData.errors.enhetnavn}
              />
              <Form.Control.Feedback type="invalid">{formData.errors.enhetnavn}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className={Style.formGroup} as={Col} sm={6}>
              <Form.Label>Telefon</Form.Label>
              <Form.Control
                id='telefon'
                name="telefon"
                type="text"
                value={formData.values.telefon || ''}
                onChange={e => handleInputChangeClean('telefon', e.target.value)}
                isInvalid={formData.touched.telefon && formData.errors.telefon}
                isValid={formData.touched.telefon && !formData.errors.telefon}
              />
              <Form.Control.Feedback type="invalid">{formData.errors.telefon}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className={Style.formGroup} as={Col} sm={6}>
              <BrregInput
                id="juridiskinfo"
                type="orgnr"
                selectedJuridisk={selectedJuridisk}
                setSelectedJuridisk={setSelectedJuridisk}
                clearTypeaheadInitiator={props.enhet.id}
                onUpdateForm={updateFormBrregHandler}
              />
            </Form.Group>

            <Form.Group className={Style.formGroup} as={Col} sm={6}>
              <Form.Label>Epost</Form.Label>
              <Form.Control
                id='epost'
                type="text"
                name="epost"
                value={formData.values.epost || ''}
                onChange={e => handleInputChange('epost', e.target.value.trim())}
                isInvalid={formData.touched.epost && formData.errors.epost}
                isValid={formData.touched.epost && !formData.errors.epost}
              />
              <Form.Control.Feedback type="invalid">{formData.errors.epost}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className={Style.formGroup} as={Col} sm={6}>
              <BrregInput
                id="juridiskbedriftinfo"
                type="bedriftsnr"
                selectedJuridisk={selectedJuridiskBedrift}
                setSelectedJuridisk={setSelectedJuridiskBedrift}
                clearTypeaheadInitiator={props.enhet.id}
                onUpdateForm={updateFormBrregHandler}
                errors={formData.errors?.bedriftsnr}
              />
              {selectedJuridiskBedrift.length === 1 && !isBedriftsnummerInElma && (
                <Form.Text className="text-warning">Ikke registrert i ELMA</Form.Text>
              )}
            </Form.Group>

            <Form.Group className={Style.formGroup} as={Col} sm={6}>
              <Form.Label>Faktura Epost</Form.Label>
              <Form.Control
                id='fakturaepost'
                type="text"
                name="fakturaepost"
                value={formData.values.fakturaepost || ''}
                onChange={e => handleInputChange('fakturaepost', e.target.value.trim())}
                isInvalid={formData.touched.fakturaepost && formData.errors.fakturaepost}
                isValid={formData.touched.fakturaepost && !formData.errors.fakturaepost}
              />
              <Form.Control.Feedback type="invalid">{formData.errors.fakturaepost}</Form.Control.Feedback>
              {props.kontonrOgFakturaAdresseSjekkResponse && props.kontonrOgFakturaAdresseSjekkResponse.harSammeOrgNr && props.kontonrOgFakturaAdresseSjekkResponse.hovedKontorFakturaEpost?.length > 0 && props.kontonrOgFakturaAdresseSjekkResponse.fakturaEpostErForskjellig &&
                <Form.Text>
                  Faktura Epost til <a target="_blank" href={"/enheter/" + props.kontonrOgFakturaAdresseSjekkResponse.hovedkontorEnhetId}>hovedkontoret</a> er {props.kontonrOgFakturaAdresseSjekkResponse.hovedKontorFakturaEpost}.
                  <span> </span><a target="_blank" href='#' onClick={e => { e.preventDefault(); handleInputChangeClean('fakturaepost', props.kontonrOgFakturaAdresseSjekkResponse.hovedKontorFakturaEpost); }}>Bytte?</a>
                </Form.Text>}
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className={Style.formGroup} as={Col} sm={6}>
              <Form.Label>Oppstart dato</Form.Label>
              <div>
                <DatePicker
                  id="oppstartdato"
                  selected={formData.values.oppstartsdato}
                  onChange={value => handleOppstartDatoChange(value)}
                  minDate={førsteMuligeOppstartDato}
                  adjustTimeForTimezoneOffset={true}
                />
                {formData.values.oppstartsdato && formData.touched.oppstartsdato && !formData.values.reservertMotPost && (
                  <Form.Text className="text-info">Enheten får post fra: <strong>{postFrom}</strong></Form.Text>
                )}

                <Form.Control.Feedback type="invalid">
                  {formData.errors.oppstartsdato}
                </Form.Control.Feedback>
              </div>
            </Form.Group>

            <Form.Group className={Style.formGroup} as={Col}>
              <Form.Label>Reservert mot post</Form.Label>
              <Form.Check
                id="cb-1"
                type="checkbox"
                disabled={formData.values.oppstartsdato ? false : true}
                checked={formData.values.reservertMotPost}
                onChange={e => formData.handleChange('reservertMotPost', e.target.checked)}
              />
              <Form.Control.Feedback type="invalid">
                {formData.errors.reservertMotPost}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className={Style.formGroup} as={Col}>
              <Form.Label>Sesongdrift</Form.Label>
              <Form.Check
                type="checkbox"
                checked={formData.values.erSesongDrift}
                onChange={e => formData.handleChange('erSesongDrift', e.target.checked)}
              />
              <Form.Control.Feedback type="invalid">
                {formData.errors.erSesongdrift}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className={Style.formGroup} as={Col}>
              <Form.Label>Offentlig</Form.Label>
              <Form.Check
                type="checkbox"
                checked={formData.values.erOffentlig}
                onChange={e => formData.handleChange('erOffentlig', e.target.checked)}
              />
              <Form.Control.Feedback type="invalid">
                {formData.errors.erOffentlig}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className={Style.formGroup} as={Col}>
              <Form.Label>Kan motta efaktura</Form.Label>
              <Form.Check
                type="checkbox"
                disabled={true}
                checked={formData.values.kanMottaEfaktura}
              />
              <Form.Control.Feedback type="invalid">
                {formData.errors.kanMottaEfaktura}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className={Style.formGroup} as={Col} sm={6}>
              <Form.Label>Omsetning</Form.Label>
              <Form.Control
                id="omsetning"
                type="number"
                step="any"
                name="omsetningMillioner"
                value={formData.values.omsetningMillioner || ''}
                onChange={e => formData.handleChange('omsetningMillioner', e.target.value)}
                isInvalid={formData.touched.omsetningMillioner && formData.errors.omsetningMillioner}
                isValid={formData.touched.omsetningMillioner && !formData.errors.omsetningMillioner}
              />
              <Form.Text>I millioner</Form.Text>
              <Form.Control.Feedback type="invalid">
                {formData.errors.omsetningMillioner}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className={Style.formGroup} as={Col} sm={6}>
              <Form.Label>Omsetningsårstall</Form.Label>
              <Form.Control
                id="omsetningårstall"
                type="number"
                step="1"
                name="omsetningAar"
                value={formData.values.omsetningAar || ''}
                onChange={e => formData.handleChange('omsetningAar', e.target.value)}
                isInvalid={formData.touched.omsetningAar && formData.errors.omsetningAar}
                isValid={formData.touched.omsetningAar && !formData.errors.omsetningAar}
              />
              <Form.Text>kan ikke endres for eksisterende omsetninger</Form.Text>
              <Form.Control.Feedback type="invalid">
                {formData.errors.omsetningAar}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className={Style.formGroup} as={Col} sm={6}>
              <Form.Label>Kontonummer</Form.Label>
              <Form.Control
                id="kontonummer"
                type="text"
                name="kontonummer"
                value={formData.values.kontonr || ''}
                onChange={e => handleInputChangeClean('kontonr', e.target.value)}
                isInvalid={formData.touched.kontonr && formData.errors.kontonr}
                isValid={formData.touched.kontonr && !formData.errors.kontonr}
              />
              <Form.Control.Feedback type="invalid">{formData.errors.kontonr}</Form.Control.Feedback>
              {props.kontonrOgFakturaAdresseSjekkResponse && props.kontonrOgFakturaAdresseSjekkResponse.hovedkontorKontonr && props.kontonrOgFakturaAdresseSjekkResponse.harSammeOrgNr && props.kontonrOgFakturaAdresseSjekkResponse.kontonrErForskjellig &&
                <Form.Text>
                  Kontonummer til <a target="_blank" href={"/enheter/" + props.kontonrOgFakturaAdresseSjekkResponse.hovedkontorEnhetId}>hovedkontoret</a> er {props.kontonrOgFakturaAdresseSjekkResponse.hovedkontorKontonr}.
                  <span> </span><a target="_blank" href='#' onClick={e => { e.preventDefault(); handleInputChangeClean('kontonr', props.kontonrOgFakturaAdresseSjekkResponse.hovedkontorKontonr); }}>Bytte?</a>
                </Form.Text>}
            </Form.Group>

            <Form.Group className={Style.formGroup} as={Col} sm={6}>
              <Form.Label>Salgsareal</Form.Label>
              <Form.Control
                id="salgsareal"
                type="number"
                name="salgsArealKvadratmeter"
                value={formData.values.salgsArealKvadratmeter || ''}
                onChange={e => formData.handleChange('salgsArealKvadratmeter', e.target.value)}
                isInvalid={
                  formData.touched.salgsArealKvadratmeter && formData.errors.salgsArealKvadratmeter
                }
                isValid={
                  formData.touched.salgsArealKvadratmeter && !formData.errors.salgsArealKvadratmeter
                }
              />
              <Form.Text>I kvadratmeter</Form.Text>
              <Form.Control.Feedback type="invalid">
                {formData.errors.salgsArealKvadratmeter}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className={Style.formGroup} id="bransje" as={Col} lg={6}>
              <Form.Label>Bransje</Form.Label>
              <BransjePicker
                onSelectionChanged={handlePickedBransjeChanged}
                selected={[pickedBransjeID]}
                mode="radioSelect"
              />
            </Form.Group>
            <Form.Group className={Style.formGroup} as={Col} lg={6}>
              <Form.Label>Land</Form.Label>
              <CountryPicker
                selected={formData.values.landKode || 'NO'}
                onChange={value => formData.handleChange('landKode', value)}
              />
              <Form.Control.Feedback type="invalid">{formData.errors.landKode}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className={Style.formGroup} as={Col} lg={6}>
              <Form.Label>Enhettyper</Form.Label>
              <EnhetTypeMultiPicker
                resetIndicator={pickerResetIndicator}
                treeSelectMode="multiSelect"
                onSelectionChanged={handleSelectedTyperChanged}
                selectedNodes={formData.values.enhetTypeKoder}
                disabled={isSaveLoading}
                closeOnSelect={true}
              />
            </Form.Group>
            <Form.Group className={Style.formGroup} as={Col} lg={6}>
              <Form.Label>Hovedtype</Form.Label>
              <Form.Control
                id="enhettyper"
                disabled={formData.values.enhetTypeKoder?.length < 1}
                as="select"
                onChange={e => formData.handleChange('hovedEnhetTypeKode', e.target.value)}
                value={formData.values.hovedEnhetTypeKode || ''}
              //custom
              >
                {formData.values.enhetTypeKoder &&
                  formData.values.enhetTypeKoder.map(kode => (
                    <option key={kode} value={kode}>
                      {kode}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className={Style.formGroup} as={Col} sm={12}>
              <Form.Label>Notat</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="notat"
                value={formData.values.notat || ''}
                onChange={e => formData.handleChange('notat', e.target.value)}
                isInvalid={formData.touched.notat && formData.errors.notat}
                isValid={formData.touched.notat && !formData.errors.notat}
              />
              <Form.Control.Feedback type="invalid">{formData.errors.notat}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Form.Group className={Style.formGroup}>
              <Button type="submit" disabled={formData.submitDisabled}>
                <span>
                  {isSaveLoading && (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  )}{' '}
                  Lagre
                </span>
              </Button>
            </Form.Group>
          </Row>
        </Form>
      ) : (
        <div>Laster</div>
      )
      }
    </>
  );
};

export default Basis;
