import React, { useEffect, useState, useCallback } from 'react';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';

import MarkedsKjedePicker from 'components/MarkedsKjedePicker';
import { PickerMode, PickedKjede } from 'components/KjedePicker';

import AbonnementApi from 'services/KjederegisteretAdminApi/AbonnementApi';
import { Abonnement, AbonnementContent, UpdateAbonnementKjederCommand } from 'services/KjederegisteretAdminApi/AbonnementApi/types';

import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';

interface Props {
  abonnement: Abonnement;
  onUpdated: () => void;
}

const EditKjeder: React.FC<Props> = props => {

  const toaster = useToaster();

  const [isEkskluderende, setIsEkskluderende] = useState<boolean>(
    props.abonnement.kjeder?.findIndex(x => x.isEkskluderende) > -1
  );

  const initialVals: UpdateAbonnementKjederCommand = {
    kjeder: props.abonnement.kjeder
  }

  const validate = (values: UpdateAbonnementKjederCommand) => {
    let errors: any = {};

    return errors;
  }

  const onSubmit = async(command: UpdateAbonnementKjederCommand) => {
    try {
      await AbonnementApi.updateAbonnementKjeder(props.abonnement.id, command);
      toaster.success('Abonnement oppdatert', `Abonnement med id '${props.abonnement.id}', ble oppdatert.`,);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, loading, handleChange, handleSubmit } = useForm(initialVals, validate, onSubmit);

  const handleKjederSelected = useCallback((selected: PickedKjede[]) => {
    var kjeder: AbonnementContent[] = selected.map(x => {
      var kjede: AbonnementContent = {
        id: x.id,
        name: x.name,
        number: x.kjedenr,
        code: "",
        isEkskluderende: isEkskluderende
      }
      return kjede;
    });
    handleChange('kjeder', kjeder);
  }, []);

  useEffect(() => {
    handleChange('kjeder', props.abonnement.kjeder)
    setIsEkskluderende(props.abonnement.kjeder?.findIndex(x => x.isEkskluderende) > -1);
  }, [props.abonnement.id])

  useEffect(() => {
    var kjeder = values.kjeder.map(x => 
      ({...x, isEkskluderende: isEkskluderende})
    );
    handleChange('kjeder', kjeder);
  }, [isEkskluderende])

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Form.Group className='mb-3'>
            <Form.Label>Markedskjeder</Form.Label>
            <MarkedsKjedePicker 
              pickerMode={PickerMode.Multi} 
              onSelectionChanged={handleKjederSelected} 
              selected={values.kjeder.map(o => Number(o.number))} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Ekskluder kjeder</Form.Label>
            <div>
              <Form.Check 
                style={{ display: 'inline-block', marginRight: '0.5rem' }}
                checked={isEkskluderende} 
                onChange={e => setIsEkskluderende(e.target.checked)} />
              <span>
                Ved avhukning vil enheter med medlemskap i valgte kjeder filtreres bort for abonnenter
              </span>
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group>
        <Button variant="primary" type="submit" disabled={loading || JSON.stringify(values) === JSON.stringify(initialVals)}>
         {
            loading && 
            <Spinner
              size="sm"
              style={{ marginRight: '.3em' }}
              animation="border"
              role="status" />
          }
          <span>Lagre</span>
        </Button>
      </Form.Group>
    </Form>
  )
}

export default EditKjeder;