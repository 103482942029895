import { IconButton } from "@tradesolution/iceberg-ui-react";
import { Form, Modal, Spinner } from "react-bootstrap";
import GrossisterApi from "services/KjederegisteretAdminApi/GrossisterApi";
import { AddOrUpdateGrossistCommand, Grossist } from "services/KjederegisteretAdminApi/GrossisterApi/types";
import useForm from "utils/hooks/useForm";
import useToaster from "utils/hooks/useToaster";
import HttpErrorHelper from 'utils/HttpErrorHelper';


interface Props {
    grossist: Grossist;
    onUpdated: (filter: string) => void;
    onClose: () => void;
}

const GrossistEdit = (props: Props) => {
    const toaster = useToaster();

    const initialVals: AddOrUpdateGrossistCommand = {
        grossistEnhetId: props.grossist.grossistEnhetId,
        grossistnr: props.grossist.grossistnr,
        kortnavn: props.grossist.kortnavn,
        grossistgruppe: props.grossist.grossistgruppe,
    };

    const validate = (values: AddOrUpdateGrossistCommand) => {
        const err: any = {};
        if (!values.kortnavn) err.kortnavn = 'Kortnavn må fylles ut';
        return err;
    };

    const onSubmit = async (command: AddOrUpdateGrossistCommand) => {
        try {
            await GrossisterApi.updateGrossist(command.grossistEnhetId, command);
            props.onUpdated('');
            toaster.success('Suksess', 'Grossist oppdatert!');
            props.onClose(); // close the modal 
        } catch (err) {
            toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
        }
    }


    const { loading, touched, errors, values, handleSubmit, handleChange, resetForm } =
        useForm(initialVals, validate, onSubmit);

    const handleClose = () => {
        resetForm(initialVals);
        props.onClose();
    };

    return (
       
            <Modal show={!!props.grossist} onHide={handleClose} backdrop={false}>

                <Modal.Header closeButton>
                    <Modal.Title>Rediger grossist</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>Kortnavn</Form.Label>
                            <Form.Control
                                name="kortnavn"
                                type="text"
                                value={values.kortnavn}
                                onChange={(e) => handleChange('kortnavn', e.target.value.toUpperCase())}
                                isInvalid={touched.kortnavn && !!errors.kortnavn}
                                isValid={touched.kortnavn && !errors.kortnavn} />
                            <Form.Control.Feedback type="invalid">{errors.kortnavn}</Form.Control.Feedback>
                        </Form.Group>

                        {props.grossist.grossistgruppe && (
                            <Form.Group className="mb-3">
                                <Form.Label>Grossistnr</Form.Label>
                                <Form.Control
                                    name="grossistnr"
                                    type="number"
                                    value={props.grossist.grossistnr}
                                    readOnly />
                            </Form.Group>
                        )}


                        <Form.Group className="mb-3">
                            <Form.Label>Grossistgruppe</Form.Label>
                            <Form.Control
                                name="grossistgruppe"
                                type="text"
                                value={values.grossistgruppe || ''}
                                onChange={(e) => handleChange('grossistgruppe', e.target.value.toUpperCase())} />
                        </Form.Group>


                    </Modal.Body>

                    <Modal.Footer>
                        <IconButton
                            icon='close'
                            variant='outline-primary'
                            onClick={handleClose}>
                            Avbryt
                        </IconButton>

                        <IconButton
                            variant='primary'
                            className="col"
                            type='submit'
                            icon='disk'
                            disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}>
                            {
                                loading &&
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            } Lagre
                        </IconButton>
                    </Modal.Footer>
                </Form>
            </Modal>
    )
};

export default GrossistEdit;