import React, { useEffect, useState } from "react";
import ConfirmButton from "components/ConfirmButton";
import DatePicker from "components/DatePicker";
import { format } from "date-fns";
import { Alert, Button, Card, Form } from "react-bootstrap";
import EnhetsgrupperingApi from "services/KjederegisteretAdminApi/EnhetsgrupperingApi";
import { Enhetgruppering, ToggleNedleggelseOrDeleteCommand } from "services/KjederegisteretAdminApi/EnhetsgrupperingApi/types";
import DateHelper from "utils/DateHelper";
import useToaster from "utils/hooks/useToaster";
import HttpErrorHelper from "utils/HttpErrorHelper";
import MeldUtAlleEnhetsMedlemmer from "./MeldUtAlleEnhetsMedlemmer";

interface Props {
  gruppering: Enhetgruppering;
  onEnhetsgruppeUpdated: () => void;
}

const AdminTab: React.FC<Props> = props => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [nedLeggelsesdato, setNedLeggelsesdato] = useState<Date>(new Date());
  const [antallAktiveEnheter, setAntallAktiveEnheter] = useState<number>(0);

  const toaster = useToaster();

  // vi sjekker om det er aktive medlemmer dagen derpå fordi nedleggelsesdato er tilOgMed
  // enhetsgrupperingen er altså aktiv ut dagen
  const getAntallAktiveEnheterDagenDerpå = async () => {
    const data = await EnhetsgrupperingApi.getAntallAktiveEnheter(
      props.gruppering.enhetgrupperingID, false, DateHelper.addDays(nedLeggelsesdato, 1)
    );
    setAntallAktiveEnheter(data);
  }

  const toggleNedleggelse = async () => {
    setIsUpdating(true);
    try {
      const payload: ToggleNedleggelseOrDeleteCommand = {
        enhetgrupperingId: props.gruppering.enhetgrupperingID,
        nedleggelsedato: nedLeggelsesdato,
      };
      const result = await EnhetsgrupperingApi.toogleNedleggelseOrDelete(props.gruppering.enhetgrupperingID, payload);
      toaster.success('Suksess!', result);
      props.onEnhetsgruppeUpdated();
    } catch (err) {
      toaster.error('Feil.', HttpErrorHelper.handleErrorMessage(err));
    }
    setIsUpdating(false);
  }

  useEffect(() => {
    getAntallAktiveEnheterDagenDerpå();
  }, [nedLeggelsesdato, props.gruppering]);

  const handleMedlemmerMeldtUt = () => {
    getAntallAktiveEnheterDagenDerpå();
  };

  return (
    <Card>
      <Card.Body>
        <MeldUtAlleEnhetsMedlemmer
          gruppering={props.gruppering}
          antallAktiveEnheter={antallAktiveEnheter}
          onSuccess={handleMedlemmerMeldtUt}
        />
        <Card>
          <Card.Body>
            {props.gruppering.nedlagtDato ?
              <Card.Title>Reaktiver enhetsgruppering</Card.Title>
              :
              <Card.Title>Legg ned enhetsgruppering</Card.Title>
            }
            {props.gruppering.nedlagtDato ?
              <Alert variant="danger">Enhetsgruppering ble lagt ned {format(props.gruppering.nedlagtDato, "dd.MM.yyyy")} </Alert>
              :
              <>
                <Form.Group>
                  <Form.Label>Nedleggelsesdato</Form.Label>
                  <DatePicker selected={nedLeggelsesdato} onChange={(dato: Date) => setNedLeggelsesdato(dato)} adjustTimeForTimezoneOffset={true} />
                </Form.Group>
                <p>
                  Hvis enhetsgrupperingen tidligere har hatt medlemmer så legges den ned, hvis ikke slettes den fra systemet. Enhetsgrupperingen kan ikke ha aktive medlemmer når dette utføres
                </p>
              </>
            }

            {
              antallAktiveEnheter > 0 &&
              <Alert variant="info">
                Enhetsgrupperingen har {antallAktiveEnheter} aktive medlemmer på angitt dato og kan ikke legges ned.
              </Alert>
            }
            <p>
              {!props.gruppering.nedlagtDato && (
                <ConfirmButton
                  disabled={isUpdating || antallAktiveEnheter > 0}
                  onClick={toggleNedleggelse}
                  title={`Enhetsgruppering har ${antallAktiveEnheter} aktive medlemmer.`}
                  customtext={props.gruppering.harMedlemsHistorikk ? "Legg ned enhetsgruppering" : "Slett enhetsgruppering"}
                />
              )}
              {props.gruppering.nedlagtDato && (
                <Button
                  variant="primary"
                  disabled={isUpdating}
                  onClick={toggleNedleggelse}>
                  ReAktiver enhetsgruppe
                </Button>
              )}
            </p>
          </Card.Body>
        </Card>
      </Card.Body>
    </Card>
  );
};

export default AdminTab;
