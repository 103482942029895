import React, { useState, useEffect } from 'react';
import { Row, Col, InputGroup, FormControl, OverlayTrigger, Tooltip, Button, Tab, Tabs, Spinner } from 'react-bootstrap';

import GrossistTable from './components/GrossistTable';
import AddGrossist from './components/AddGrossist';
import CreateGrossist from './components/CreateGrossist';

import SpinnerLarge from 'components/SpinnerLarge';
import PageWrapper from 'components/PageWrapper';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import GrossisterApi from 'services/KjederegisteretAdminApi/GrossisterApi';
import { Grossist } from 'services/KjederegisteretAdminApi/GrossisterApi/types';

import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import useDebounce from 'utils/hooks/useDebounce';
import Style from './index.module.css';

const Grossister = () => {
  const isMountedRef = useIsMountedRef();
  const [data, setData] = useState<Grossist[]>([]);
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [searchControlValue, setSearchControlValue] = useState<string>('');
  const [isAddGrossist, setIsAddGrossist] = useState<boolean>(false);
  const [createdGrossist, setCreatedGrossist] = useState<Grossist>();

  const filterOnChange = (e: React.SetStateAction<string>) => {
    if (isMountedRef.current) {
      setFilterQuery(e);
      setSearchControlValue(e);
    }
  };

  const loadGrossister = (filter: string) => {
    if (isMountedRef.current) {
      try {
        GrossisterApi.getGrossister(filter).then(res => {
          setData(res);
        });
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const debouncedQuery = useDebounce(filterQuery, 300);

  useEffect(() => {
    if (debouncedQuery) {
      loadGrossister(debouncedQuery);
    } else {
      loadGrossister(filterQuery);
    }
  }, [debouncedQuery]);

  return (
    <PageWrapper header="Grossister">
      <Row>
        <Col md="5">
          <InputGroup className='mb-3'>
            <FormControl
              value={searchControlValue}
              placeholder="Filtrer liste..."
              style={{ borderRight: 'hidden', borderRadius: '24px 0px 0px 24px' }}
              onChange={e => filterOnChange(e.target.value)} />
            <InputGroup.Text style={{ background: 'white', borderLeft: 'hidden', borderRadius: '0px 24px 24px 0px' }}>
              {
                filterQuery.length > 0 ?
                  <IcebergIcon icon='close' cursor='pointer'
                    onClick={() => setFilterQuery('')} />
                  : <IcebergIcon icon='search' />
              }
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col md="7">
          <Button
            variant='outline-primary'
            onClick={() => setIsAddGrossist(prev => !prev)}
            className={Style.newGrossistBtn}>
            {
              !isAddGrossist ?
                <>
                  <IcebergIcon icon='plus' bold />
                  <span style={{ paddingLeft: '0.3em' }}>Ny grossist</span>
                </> :
                'Avbryt'
            }
          </Button>
        </Col>
      </Row>

      {isAddGrossist && (
        <div>
          <Tabs defaultActiveKey="createGrossist" id="uncontrolled-tab-example">
            <Tab eventKey="createGrossist" title="Ny grossist">
              <CreateGrossist
                setCreatedGrossist={setCreatedGrossist} />
            </Tab>
            <Tab eventKey="addGrossist" title="Bruk enhet">
              <AddGrossist
                setIsAddGrossist={setIsAddGrossist}
                setCreatedGrossist={setCreatedGrossist}
              />
            </Tab>
          </Tabs>
        </div>
      )}

      <Row>
        <Col>
          {data?.length < 1 ? (
            <div className={Style.spinnerContainer}>
              <SpinnerLarge />
              <h5>Henter grossister...</h5>
            </div>
          ) : (
            <GrossistTable data={data} loadGrossister={loadGrossister} />
          )}
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default Grossister;
