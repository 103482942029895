export const getKjederegAdminApiUrl = (): string => {
  const { host } = window.location;

  if (host.indexOf('localhost') > -1) {
    return process.env.REACT_APP_LOCAL_KJEDEREGISTERADMIN_API_URL;
  }
  if (host.indexOf('kjederegisteretadmin-dev') > -1 || host.indexOf('kjederegisterspadev') > -1 || host.indexOf('kjederegisteretadmin-temp') > -1) {
    return process.env.REACT_APP_DEV_KJEDEREGISTERADMIN_API_URL;
  }
  return process.env.REACT_APP_PROD_KJEDEREGISTERADMIN_API_URL;
}

export const getKundeportalenApiUrl = (): string => {
  const { host } = window.location;

  if (host.indexOf('localhost') > -1) {
    return process.env.REACT_APP_DEV_KUNDEPORTALEN_API_URL;
  }
  if (host.indexOf('kjederegisteretadmin-dev') > -1 || host.indexOf('kjederegisterspadev') > -1 || host.indexOf('kjederegisteretadmin-temp') > -1) {
    return process.env.REACT_APP_DEV_KUNDEPORTALEN_API_URL;
  }
  return process.env.REACT_APP_PROD_KUNDEPORTALEN_API_URL;
}


export const getQAApiUrl = (): string => {
  const { host } = window.location;

  if (host.indexOf('localhost') > -1) {
    return process.env.REACT_APP_DEV_QA_API_URL;
  }
  if (host.indexOf('kjederegisteretadmin-dev') > -1 || host.indexOf('kjederegisterspadev') > -1 || host.indexOf('kjederegisteretadmin-temp') > -1) {
    return process.env.REACT_APP_DEV_QA_API_URL;
  }
  return process.env.REACT_APP_PROD_QA_API_URL;
}

export const getImportApiUrl = (): string => {
  const { host } = window.location;

  if (host.indexOf('localhost') > -1) {
    return process.env.REACT_APP_DEV_IMPORT_API_URL;
  }
  if (host.indexOf('kjederegisteretadmin-dev') > -1 || host.indexOf('kjederegisterspadev') > -1 || host.indexOf('kjederegisteretadmin-temp') > -1) {
    return process.env.REACT_APP_DEV_IMPORT_API_URL;
  }
  return process.env.REACT_APP_PROD_IMPORT_API_URL;
}