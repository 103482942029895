/*IMPORTS */

/*React & React module dependencies */
import React, { useState, useEffect, useCallback } from 'react';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/*Third-party modules */
import { format } from 'date-fns';

/*Third-party modules */
import DatePicker from 'components/DatePicker';
import PageWrapper from 'components/PageWrapper';

/*hooks */
import useIsMountedRef from 'utils/hooks/useIsMountedRef';


/*Services */
import { ImportData } from 'services/ImportApi/types';
import ImportApi from 'services/ImportApi';

/*IMPORTS END */

const Importer = () => {
  const isMountedRef = useIsMountedRef();

  /*useState properties */
  const [imports, setImports] = useState<ImportData[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const loadImports = useCallback(async (date: Date) => {
    const day = format(date, 'yyyyMMdd');
    const data = await ImportApi.get(day);
    if (isMountedRef.current) {
      setImports(data.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()));
    }
  }, [isMountedRef]);

  const formatTimestamps = (from: Date, to: Date) => {
    const timestampFormat = 'HH.mm.ss';
    return `kl ${format(from, timestampFormat)} - kl ${format(to, timestampFormat)}`;
  };

  const formatDuration = (from, to) => `${(to - from) / 1000} sek`;

  /*useEffect */
  useEffect(() => {
    loadImports(selectedDate);
  }, [selectedDate, loadImports]);



  /*Content */
  return (
    <PageWrapper header="Dagens importkjøringer">
      <p>Importer som kommer inn som CSV via FTP eller epost, blir parset og sendt inn til QaApi</p>
      <Row>
        <Col>
          <form>
            <div className="form-group">
              <label htmlFor="date">Valgt dato</label>
              <div>
                <DatePicker
                  selected={selectedDate}
                  onChange={date => setSelectedDate(date)}
                  adjustTimeForTimezoneOffset={true}
                />
              </div>
            </div>
          </form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped hover>
            <thead>
              <tr>
                <th>Kilde</th>
                <th>Antall endringsmeldinger</th>
                <th>Start - Slutt</th>
                <th>Varighet</th>
              </tr>
            </thead>
            <tbody>
              {imports.map(i => (
                <tr key={i.rowKey}>
                  <td>{i.source}</td>
                  <td>{i.sentTilQaCount}</td>
                  <td>{formatTimestamps(new Date(i.start), new Date(i.end))}</td>
                  <td>{formatDuration(new Date(i.start), new Date(i.end))}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default Importer;
