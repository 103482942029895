import React, { useEffect, useState } from 'react';
import { Col, Row, Button, ButtonToolbar, ButtonGroup, Spinner, Alert } from 'react-bootstrap';

import Kjedemedlemskap, { EditType } from './components/KjedeMedlemskap';
import KjedemedlemskapForm from './components/KjedemedlemskapForm';
import { KundeNummerPeriodeFormData } from './components/KundenummerPeriode';

import HollowDotsSpinner from 'components/HollowDotsSpinner';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import EnhetKjedeApi from 'services/KjederegisteretAdminApi/EnhetKjedeApi';
import { EnhetViewModel, KjedeHistorikk } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { AddEnhetKjedeMedlemskapCommand, UpdateEnhetKjedeMedlemskapCommand } from 'services/KjederegisteretAdminApi/EnhetKjedeApi/types';
import { KjedeType } from 'services/KjederegisteretAdminApi/KjedeApi/types';

import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';

import Style from './index.module.css';

interface Props {
  enhet: EnhetViewModel;
  onUpdated: () => void;
}

const Kjede: React.FC<Props> = props => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showNewRegionKjedeForm, setShowNewRegionKjedeForm] = useState<boolean>(false);
  const [showNewSalgOgMarkedKjedeForm, setShowNewSalgOgMarkedKjedeForm] = useState<boolean>(false);
  const [isReaktivering, setIsReaktivering] = useState<boolean>(false);

  const toaster = useToaster();

  const handleUpdateKjede = async (command: AddEnhetKjedeMedlemskapCommand) => {
    command.enhetId = props.enhet.id;
    setIsLoading(true);
    try {
      await EnhetKjedeApi.add(command);
      setIsLoading(false);
      props.onUpdated();
      setShowNewRegionKjedeForm(false);
      setShowNewSalgOgMarkedKjedeForm(false);
      toaster.success('Suksess!', 'Kjede oppdatert');
    } catch (err) {
      setIsLoading(false);
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  };

  const handleUpdateKjedeInfo = async (enhetKjedeId: number, values: KundeNummerPeriodeFormData, kjedeType: string) => {

    const command: UpdateEnhetKjedeMedlemskapCommand = {
      fraOgMedDato: values.fraOgMedDato,
      tilOgMedDato: values.tilOgMedDato,
      kundenummer: values.kundenummer,
      kjedeTypeKode: kjedeType
    }

    try {
      const response = await EnhetKjedeApi.update(enhetKjedeId, command);

      toaster.success('Suksess!', 'EnhetKjede medlemsskap oppdatert');
    } catch (err) {
      toaster.error(
        'Feil! Kunne ikke oppdatere medlemsskap.',
        HttpErrorHelper.handleErrorMessage(err),
      );
    }

    props.onUpdated();
  };

  const slettKjedeMedlemskap = async (enhetKjedeId: number) => {
    try {
      const result = await EnhetKjedeApi.delete(enhetKjedeId);
      toaster.success('Suksess!', 'Kjedemedlemskap slettet!');
    } catch (err) {
      toaster.error(
        'Feil! Kunne ikke slette medlemskap.',
        HttpErrorHelper.handleErrorMessage(err),
      );
    }
    props.onUpdated();
  }

  const reaktiverMedlemskap = async (enhetKjedeId: number) => {
    setIsReaktivering(true);
    try {
      const result = await EnhetKjedeApi.reaktiver(enhetKjedeId);
      toaster.success('Suksess!', 'Kjedemedlemskap reaktiver!');
    } catch (err) {
      toaster.error(
        'Feil! Kunne ikke reaktivere medlemskap.',
        HttpErrorHelper.handleErrorMessage(err),
      );
    }
    setIsReaktivering(false);
    props.onUpdated();
  }

  const canReaktiverSisteRegional = (!!props.enhet && !!props.enhet.sisteRegionalkjedeMedlemskap?.tilOgMedDato);
  const canReaktiverSisteMarked = (!!props.enhet && !!props.enhet.sisteMarkedskjedeMedlemskap?.tilOgMedDato);

  return (
    <div>
      <Row>
        <Col>
          {props.enhet.hasTwoDifferentToppKjederNowOrInTheFuture && (
            <Alert variant="danger">
              <span><IcebergIcon icon='alert' /> OBS: ENHETEN HAR TO FORSKJELLIGE TOPPKJEDER INNENFOR EN PERIODE. DETTE MÅ FIKSES ELLERS BLIR INSIGHT LEI SEG</span>
            </Alert>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <Row style={{ marginBottom: '1rem' }}>
            <Col md="7">
              <h5>Regionalkjeder</h5>
            </Col>
            <Col md="5">
              <ButtonToolbar className={Style.btnToolbar}>
                {canReaktiverSisteRegional && (
                  <ButtonGroup className="mr-2">
                    <Button
                      size='sm'
                      className='mx-3'
                      variant="primary"
                      onClick={() => reaktiverMedlemskap(props.enhet.sisteRegionalkjedeMedlemskap.enhetKjedeId)}
                    >
                      Reaktiver nyeste
                      {isReaktivering && <Spinner size="sm" animation="border" role="status" />}
                    </Button>
                  </ButtonGroup>
                )}
                <ButtonGroup>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    title={showNewRegionKjedeForm ? 'Lukk' : 'Nytt medlemskap'}
                    onClick={() => setShowNewRegionKjedeForm(!showNewRegionKjedeForm)}
                  >
                    <IcebergIcon
                      size="18px"
                      icon={showNewRegionKjedeForm ? 'close' : 'plus'}
                      bold
                    />
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
          {showNewRegionKjedeForm && (
            <Row style={{ marginBottom: '1rem' }}>
              <Col>
                <KjedemedlemskapForm
                  enhetId={props.enhet.id}
                  kjedeType={KjedeType.Region}
                  isLoadingSubmit={isLoading}
                  onUpdateKjede={handleUpdateKjede}
                  defaultFraOgMedDato={new Date()}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div>
                {isLoading ? (
                  <div className={Style.loader}>
                    <HollowDotsSpinner />
                  </div>
                ) : (
                  <div>
                    {props.enhet && props.enhet.regionalkjedeHistorikk?.filter(o => o.isFuture).map((medlemsskap: KjedeHistorikk) => (
                      <Row key={medlemsskap.kjedeId}>
                        <Col>
                          <Kjedemedlemskap
                            kjede={medlemsskap}
                            kjedeType={KjedeType.Region}
                            handleUpdateKjede={handleUpdateKjede}
                            handleUpdateKjedeInfo={data =>
                              handleUpdateKjedeInfo(medlemsskap.enhetKjedeId, data, KjedeType.Region)
                            }
                            editType={EditType.Future}
                            handleFjernEnhetFromKjede={() => slettKjedeMedlemskap(medlemsskap.enhetKjedeId)}
                            canRemoveKjede={true}
                          />
                        </Col>
                      </Row>
                    ))}
                    {props.enhet && props.enhet.regionalkjedeHistorikk?.filter(o => o.isActive).map((medlemsskap: KjedeHistorikk) => (
                      <Row key={medlemsskap.kjedeId}>
                        <Col>
                          <Kjedemedlemskap
                            kjede={medlemsskap}
                            kjedeType={KjedeType.Region}
                            handleUpdateKjede={handleUpdateKjede}
                            handleUpdateKjedeInfo={data =>
                              handleUpdateKjedeInfo(medlemsskap.enhetKjedeId, data, KjedeType.Region)
                            }
                            editType={EditType.Active}
                            handleFjernEnhetFromKjede={() => slettKjedeMedlemskap(medlemsskap.enhetKjedeId)}
                            canRemoveKjede={
                              props.enhet.regionalkjedeHistorikk?.find(o => o.isFuture) ? false : true
                            }
                          />
                        </Col>
                      </Row>
                    ))}
                    {props.enhet && props.enhet.regionalkjedeHistorikk?.filter(o => o.isHistoric).length > 0 && (
                      <Row>
                        <Col>
                          <h6>Historikk</h6>
                          {props.enhet.regionalkjedeHistorikk?.filter(o => o.isHistoric).map(medlemsskap => (
                            <div key={medlemsskap.kjedeId}>
                              <Kjedemedlemskap
                                kjede={medlemsskap}
                                kjedeType={KjedeType.Region}
                                handleUpdateKjede={handleUpdateKjede}
                                handleUpdateKjedeInfo={data =>
                                  handleUpdateKjedeInfo(medlemsskap.enhetKjedeId, data, KjedeType.Region)
                                }
                                editType={EditType.Historic}
                                handleFjernEnhetFromKjede={() => slettKjedeMedlemskap(medlemsskap.enhetKjedeId)}
                                canRemoveKjede={false}
                              />
                            </div>
                          ))}
                        </Col>
                      </Row>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={6}>
          <Row style={{ marginBottom: '1rem' }}>
            <Col md={7}>
              <h5>Markedskjeder</h5>
            </Col>
            <Col>
              <ButtonToolbar className={Style.btnToolbar}>
                {canReaktiverSisteMarked && (
                  <ButtonGroup className="mr-2">
                    <Button
                      size="sm"
                      className="mx-3"
                      variant="primary"
                      onClick={() => reaktiverMedlemskap(props.enhet.sisteMarkedskjedeMedlemskap.enhetKjedeId)}
                    >
                      Reaktiver nyeste
                      {isReaktivering && <Spinner size="sm" animation="border" role="status" />}
                    </Button>
                  </ButtonGroup>
                )}
                <ButtonGroup>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    title={showNewSalgOgMarkedKjedeForm ? 'Lukk' : 'Nytt medlemskap'}
                    onClick={() =>
                      setShowNewSalgOgMarkedKjedeForm(!showNewSalgOgMarkedKjedeForm)
                    }
                  >
                    <IcebergIcon
                      size="18px"
                      icon={showNewSalgOgMarkedKjedeForm ? 'close' : 'plus'}
                      bold
                    />
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
          {showNewSalgOgMarkedKjedeForm && (
            <Row style={{ marginBottom: '1rem' }}>
              <Col>
                <KjedemedlemskapForm
                  enhetId={props.enhet.id}
                  kjedeType={KjedeType.Marked}
                  isLoadingSubmit={isLoading}
                  onUpdateKjede={handleUpdateKjede}
                  defaultFraOgMedDato={props.enhet.sisteRegionalkjedeMedlemskap?.fraOgMedDato}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div>
                {isLoading ? (
                  <div className={Style.loader}>
                    <HollowDotsSpinner />
                  </div>
                ) : (
                  <div>
                    {props.enhet && props.enhet.markedskjedeHistorikk?.filter(o => o.isFuture).map((medlemsskap: KjedeHistorikk) => (
                      <Row key={medlemsskap.kjedeId}>
                        <Col>
                          <Kjedemedlemskap
                            kjede={medlemsskap}
                            kjedeType={KjedeType.Marked}
                            handleUpdateKjede={handleUpdateKjede}
                            handleUpdateKjedeInfo={data =>
                              handleUpdateKjedeInfo(medlemsskap.enhetKjedeId, data, KjedeType.Marked)
                            }
                            editType={EditType.Future}
                            handleFjernEnhetFromKjede={() => slettKjedeMedlemskap(medlemsskap.enhetKjedeId)}
                            canRemoveKjede={true}
                          />
                        </Col>
                      </Row>
                    ))}
                    {props.enhet && props.enhet.markedskjedeHistorikk?.filter(o => o.isActive).map((medlemsskap: KjedeHistorikk) => (
                      <Row key={medlemsskap.kjedeId}>
                        <Col>
                          <Kjedemedlemskap
                            kjede={medlemsskap}
                            kjedeType={KjedeType.Marked}
                            handleUpdateKjede={handleUpdateKjede}
                            handleUpdateKjedeInfo={data =>
                              handleUpdateKjedeInfo(medlemsskap.enhetKjedeId, data, KjedeType.Marked)
                            }
                            editType={EditType.Active}
                            handleFjernEnhetFromKjede={() => slettKjedeMedlemskap(medlemsskap.enhetKjedeId)}
                            canRemoveKjede={
                              props.enhet.markedskjedeHistorikk?.find(o => o.isFuture) ? false : true
                            }
                          />
                        </Col>
                      </Row>
                    ))}

                    {props.enhet && props.enhet.markedskjedeHistorikk?.filter(o => o.isHistoric).length > 0 && (
                      <Row>
                        <Col>
                          <h6>Historikk</h6>
                          {props.enhet.markedskjedeHistorikk?.filter(o => o.isHistoric).map(medlemsskap => (
                            <div key={medlemsskap.kjedeId}>
                              <Kjedemedlemskap
                                kjede={medlemsskap}
                                kjedeType={KjedeType.Marked}
                                handleUpdateKjede={handleUpdateKjede}
                                handleUpdateKjedeInfo={data =>
                                  handleUpdateKjedeInfo(medlemsskap.enhetKjedeId, data, KjedeType.Marked)
                                }
                                editType={EditType.Historic}
                                handleFjernEnhetFromKjede={() => slettKjedeMedlemskap(medlemsskap.enhetKjedeId)}
                                canRemoveKjede={false}
                              />
                            </div>
                          ))}
                        </Col>
                      </Row>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Kjede;
