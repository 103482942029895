import React from "react";
import { Row, Col, ButtonGroup, Button, Spinner } from "react-bootstrap";

import { format } from 'date-fns';

import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import QAEnhetSearch from "./components/QAEnhetSearch";

import { EndringsMelding } from "services/QAApi/types";
import { PickedEnhet } from "components/EnhetTypeahead/types";

import { formatBehandlesAvItem } from "../../../../utils";

import Style from "./index.module.css";
import { EnhetViewModel } from "services/KjederegisteretAdminApi/EnhetApi/types";

interface Props {
    enhet: EnhetViewModel;
    erPublisert?: boolean;
    initialEnhetIsNotAktiv?: boolean;
    changeRequest: EndringsMelding;
    submitDisabled: boolean;
    submitLoading: boolean;
    folgesOppLoading: boolean;
    onFolgesOppClicked: () => void;
    onAvvisClicked: () => void;
    onIgnoreClicked: () => void;
    onSubmitClicked: () => void;
    onEnhetChange: (enhet: PickedEnhet) => void;
    onCloseWindowClicked: () => void;
}

const DetailStickyHeader = (props: Props) => {

    return (
        <div className={Style.headerContainer}>
            <Row className={Style.infoContainer}>
                <Col className={Style.headerInfoText} data-private>
                    <span>
                        <strong>Behandles av: </strong>
                        {
                            props.changeRequest.behandlesAv.length > 0
                                ? props.changeRequest.behandlesAv.map(formatBehandlesAvItem).join(', ')
                                : '...'
                        }
                    </span>
                    {
                        props.changeRequest.folgesOppAv &&
                        <div className={Style.folgesOppContainer}>
                            <span style={{ marginRight: "0.3rem" }}>
                                <strong>Følges opp av: </strong>
                                {props.changeRequest?.folgesOppAv?.changedBy || ''} ({format(props.changeRequest?.folgesOppAv?.statusChangedAt, 'dd.MM.yyyy')})
                            </span>
                            {
                                props.changeRequest.folgesOppAv.comment &&
                                <span>
                                    <strong>Kommentar: </strong>
                                    {props.changeRequest.folgesOppAv.comment}
                                </span>
                            }
                        </div>
                    }

                    {
                        props.enhet && props.enhet.grupper && props.enhet.grupper.map(g => g.toppnivaaEnhetsgrupperingnavn).length > 0 &&
                        <span>
                            <strong>Enhetsgruppe(r): </strong>
                            {props.enhet.grupper.map(g => g.toppnivaaEnhetsgrupperingnavn).join(', ')}
                        </span>
                    }

                    {
                        props.enhet && props.enhet.markedskjedeHistorikk && props.enhet.markedskjedeHistorikk.flatMap((o, i) => (
                            o.isActive ? o.kjedeHierarki.map(h => h.kjedenavn) : []
                        )).length > 0 &&
                        <Col>
                            <strong>Markedsskjede: </strong>
                            {
                                props.enhet.markedskjedeHistorikk.flatMap((o, i) => (
                                    o.isActive ? o.kjedeHierarki.map(h => h.kjedenavn) : []
                                )).join(' / ')
                            }
                        </Col>
                    }

                </Col>

                <Col className={Style.closeIconCol}>
                    <IcebergIcon className={Style.windowCloseIcon} size='2x'
                        icon="close" color="#757575" cursor="pointer"
                        onClick={props.onCloseWindowClicked} bold />
                </Col>
            </Row>

            <Row className={Style.enhetSearchContainer}>
                <Col>
                    <QAEnhetSearch onChange={props.onEnhetChange}
                        enhetID={props.enhet ? props.enhet.id : undefined}
                        lopenummer={props.enhet ? props.enhet.lopenr : undefined}
                        bruktEnhet={props.changeRequest.changesUsedInEnhetWithEnhetId ? true : false}
                        erPublisert={props.erPublisert}
                        initialEnhetIsNotAktiv={props.initialEnhetIsNotAktiv} />
                </Col>
                <Col className={Style.btnGroupCol}>
                    <ButtonGroup size="sm">
                        <Button size='sm' variant="outline-primary" className="me-2"
                            disabled={!!(props.changeRequest.status === 'Avvist' || props.changeRequest.status === 'Behandlet')}
                            onClick={() => props.onFolgesOppClicked()}>
                            <span>{props.changeRequest.status === 'FolgesOpp' ? 'Stans oppfølging' : 'Følg opp'}</span>
                            {props.folgesOppLoading && <Spinner animation="border" as="span" size="sm" />}
                        </Button>
                        <Button size='sm' variant="outline-primary" className="me-2"
                            disabled={!!(props.changeRequest.status === 'Avvist' || props.changeRequest.status === 'Behandlet')}
                            onClick={props.onAvvisClicked}>
                            <span>Avvis</span>
                        </Button>
                        <Button size='sm' variant="outline-primary" className="me-2"
                            disabled={!!(props.changeRequest.status === 'Behandlet' || props.changeRequest.status === 'Avvist')}
                            onClick={props.onIgnoreClicked}>
                            <span>Ignorer</span>
                        </Button>
                        <Button size='sm' variant="primary"
                            disabled={props.submitDisabled}
                            onClick={props.onSubmitClicked}>
                            <span>Lagre</span>
                            {props.submitLoading && <Spinner animation="border" as="span" size="sm" />}
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </div>
    )
}

export default DetailStickyHeader;