import { useState } from 'react';
import { Button, ButtonProps, Spinner } from 'react-bootstrap';

interface Props extends ButtonProps{  
  customtext?: string;
}

const ConfirmButton = (props: Props) => {
  const [timesPressed, setTimesPressed] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const dialog = [props.customtext || 'Fjern', 'Er du sikker?', props.customtext || 'Fjerner'];

  const onPress = async (e: any) => {
    let count = timesPressed + 1;
    setTimesPressed(count);
    if (count === 2) {
      setIsLoading(true);
      setIsButtonDisabled(true);
      await props.onClick(e);
      setIsLoading(false);
    }
  };

  return (
    <Button
      {...props} 
      disabled={isLoading || props.disabled || isButtonDisabled}      
      onClick={e => onPress(e)}
      variant= {props.variant || "danger"}>
      {dialog[timesPressed]}
      {isLoading && (
        <Spinner
          style={{ marginLeft: '0.5em' }}
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
    </Button>
  );
};

export default ConfirmButton;
