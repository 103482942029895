import React, { useEffect, useState, useRef } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { Button } from 'react-bootstrap';
import useLongPress from 'utils/hooks/useLongPress';
import { Coordinates } from 'models/Coordinates';

interface Props {
  position: Coordinates;
  setPosition: (coordinates: Coordinates) => void;
  onLatLngChanged: (coordinates: Coordinates) => void;
}

const SetLocationMarker: React.FC<Props> = ({ position, setPosition, onLatLngChanged }) => {
  const { google } = window;
  const locationMarkerRef = useRef(null);
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);

  const onCloseInfoWindow = () => {
    setIsInfoWindowOpen(false);
  };

  const onOpenInfoWindow = () => {
    setIsInfoWindowOpen(true);
  };

  const onBtnUseClicked = () => {
    onLatLngChanged(position);
    setIsInfoWindowOpen(false);
  };

  const longPressRemoveCallback = e => {
    if (e) {
      setPosition(null);
    }
  };

  const longPressRemoveProps = useLongPress({
    onLongPress: ev => longPressRemoveCallback(ev),
  });

  const CustomMarkerImage = () => ({
    url:
      'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
    size: new google.maps.Size(20, 32), // Size(width, height)
    origin: new google.maps.Point(0, 0), // Origin for this image (0,0)
    // The anchor for this image is the base of the flagpole at (0, 32).
    anchor: new google.maps.Point(0, 32),
  });

  const CustomMarkerShape = () => ({
    coords: [1, 1, 1, 20, 18, 20, 18, 1],
    type: 'poly',
  });

  useEffect(() => {
    if (locationMarkerRef?.current.props.position) {
      setIsInfoWindowOpen(true);
    } else {
      setIsInfoWindowOpen(false);
    }
  }, [position]);

  return (
    <Marker
      ref={locationMarkerRef}
      title="Egendefinert"
      icon={CustomMarkerImage()}
      shape={CustomMarkerShape()}
      position={position}
      onClick={onOpenInfoWindow}
      onDragStart={() => setIsInfoWindowOpen(false)}
      onDragEnd={() => setIsInfoWindowOpen(true)}
      draggable
      {...longPressRemoveProps}
    >
      {isInfoWindowOpen && (
        <InfoWindow onCloseClick={onCloseInfoWindow}>
          <Button size="sm" onClick={onBtnUseClicked}>
            Bruk koordinater
          </Button>
        </InfoWindow>
      )}
    </Marker>
  );
}

export default SetLocationMarker;
