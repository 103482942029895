import React, { useState } from 'react';
import { Overlay, Button, Popover } from 'react-bootstrap';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import { Grossist } from 'services/KjederegisteretAdminApi/GrossisterApi/types';

import Style from './index.module.css';

export interface Props {
  grossist: Grossist;
  handleShowLeggNedModal: (grossit: Grossist) => void;
  handleEdit: (grossit: Grossist) => void;
}

const PopoverMenu = (props: Props) => {
  const [show, setShow] = useState<boolean>(false);
  const [target, setTarget] = useState(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setShow(!show);
    setTarget(event.target);
  };


  return (
    <div className={Style.container} onClick={(e) => handleClick(e)}>
      <IcebergIcon className={Style.menuIcon} size="20px" icon="ellipsis-horizontal" />
      <Overlay
        show={show}
        target={target}
        placement="bottom"
        containerPadding={20}>
        <Popover className={Style.popover} id="popover-menu">
          <Popover.Body className={Style.popoverContent}>
            <div className={Style.contentButtons}>
              <Button variant={'secondary'} size="sm" onClick={() => props.handleEdit(props.grossist)}>
                {props.grossist.edit ? 'Lukk' : 'Endre'}
              </Button>
              <Button disabled={props.grossist?.nedlagtdato !== null} variant="secondary" size="sm" onClick={() => props.handleShowLeggNedModal(props.grossist)}>
                Legg ned
              </Button>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default PopoverMenu;
