import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { debounce } from 'lodash';

import QACardList from "./components/QACardList";
import QACardDetails from "./components/QACardDetails";
import QaStats from "./components/QAStats";

import PageHeader from "components/PageHeader";

import QAApi from "services/QAApi";
import { Statistikk, SearchFilter, Filter, ChangeRequestStatus, EndringsMelding, BehandlesAv } from "services/QAApi/types";

import useIsMountedRef from "utils/hooks/useIsMountedRef";
import useSignalR, { SignalRData } from "utils/hooks/useSignalR";

import Style from './index.module.css';
import { useMsal } from "@azure/msal-react";

const QA = () => {
  const navigate = useNavigate();

  const { accounts } = useMsal();

  const username = accounts[0] && accounts[0].name;

  const currentUser = username || 'ukjent';

  const [negotiateUrl, setNegotiateUrl] = useState<string | null>(null);
  const [statistikk, setStatistikk] = useState<Statistikk | undefined>();
  const [searchFilter, setSearchFilter] = useState<SearchFilter>({
    status: ChangeRequestStatus.Ubehandlet,
    query: '',
    source: '',
    orderBy: '',
    tags: [],
    take: 25,
    leverandorKundenummerHosTsKundeId: '',
    grossistKundenummerHosGln: [],
  });
  const [filter, setFilter] = useState<Filter[]>([]);
  const [loadingChangeRequest, setLoadingChangeRequests] = useState(false);
  const [currentBehandlesAvSignalrMessage, setCurrentBehandlesAvSignalrMessage] = useState<SignalRData>(null);
  const [currentStatusSignalrMessage, setCurrentStatusSignalrMessage] = useState<SignalRData>(null);

  const [selectedChangeRequest, setSelectedChangeRequest] = useState<EndringsMelding>();
  const [nextChangeRequest, setNextChangeRequest] = useState<EndringsMelding>();
  const [changeRequests, setChangeRequests] = useState<EndringsMelding[]>([]);
  const [folgesOppChangeRequests, setFolgesOppChangeRequests] = useState<EndringsMelding[]>([]);
  const [rejectedChangeRequests, setRejectedChangeRequests] = useState<EndringsMelding[]>([]);
  const [processedChangeRequests, setProcessedChangeRequests] = useState<EndringsMelding[]>([]);

  const isMountedRef = useIsMountedRef();

  const listeners = [
    {
      type: 'behandlesAvEndring',
      handler: (data: SignalRData) => {
        setCurrentBehandlesAvSignalrMessage(data);
      },
    },
    {
      type: 'statusendring',
      handler: (data: SignalRData) => {
        setCurrentStatusSignalrMessage(data);
      },
    },
  ];
  const { signalRConnectionStatus } = useSignalR({ negotiateUrl: negotiateUrl, listeners });

  const updateChangeRequestIfVisible = async (data: SignalRData) => {
    if (searchFilter.status === ChangeRequestStatus.Ubehandlet && existInChangeRequestList(changeRequests, data.Id)) {
      const updatedCollection = await updateActiveChangeRequestCollection(
        changeRequests,
        data.Status,
        data.Id,
        data.Title,
      );
      if (isMountedRef.current) {
        setChangeRequests(updatedCollection);
      }
    } else if (searchFilter.status === ChangeRequestStatus.FolgesOpp && existInChangeRequestList(folgesOppChangeRequests, data.Id)) {
      const updatedCollection = await updateActiveChangeRequestCollection(
        folgesOppChangeRequests,
        data.Status,
        data.Id,
        data.Title,
      );
      if (isMountedRef.current) {
        setChangeRequests(updatedCollection);
      }
    } else if (searchFilter.status === ChangeRequestStatus.Behandlet && existInChangeRequestList(processedChangeRequests, data.Id)) {
      const updatedCollection = await updateActiveChangeRequestCollection(
        processedChangeRequests,
        data.Status,
        data.Id,
        data.Title,
      );
      if (isMountedRef.current) {
        setChangeRequests(updatedCollection);
      }
    } else if (searchFilter.status === ChangeRequestStatus.Avvist && existInChangeRequestList(rejectedChangeRequests, data.Id)) {
      const updatedCollection = await updateActiveChangeRequestCollection(
        rejectedChangeRequests,
        data.Status,
        data.Id,
        data.Title,
      );
      if (isMountedRef.current) {
        setChangeRequests(updatedCollection);
      }
    }
  };

  const existInChangeRequestList = (list: EndringsMelding[], id: string) => list.findIndex(o => o.id === id) > -1;

  const updateActiveChangeRequestCollection = async (collection: EndringsMelding[], status: string, changeRequestId: string, name: string): Promise<EndringsMelding[]> => {
    if (status === 'Behandles') {
      const copy = collection.map(o =>
        o.id === changeRequestId
          ? { ...o, behandlesAv: addToBehandlesAv(o.behandlesAv, name) }
          : { ...o, behandlesAv: removeFromBehandlesAv(o.behandlesAv, name) },
      );
      return copy;
    }

    if (status === 'Behandlet' || status === 'Avvist' || status === 'FolgesOpp') {
      const copy = [...collection];
      const index = copy.findIndex(x => x.id === changeRequestId);
      if (index > -1) {
        copy[index] = await getEndringsmelding(changeRequestId);
      }
      return copy;
    }

    const copy = collection.map(o => ({
      ...o,
      behandlesAv: removeFromBehandlesAv(o.behandlesAv, name),
    }));
    return copy;
  };

  const getEndringsmelding = async (id: string) => {
    const item = await QAApi.getEndringsMelding(id);
    return item;
  };

  const removeFromBehandlesAv = (behandlesAv: BehandlesAv[], navn: string) => behandlesAv.filter(o => o.navn !== navn);

  const addToBehandlesAv = (behandlesAv: BehandlesAv[], navn: string) => {
    if (behandlesAv.findIndex(o => o.navn === navn) === -1) {
      behandlesAv.push({ navn, time: new Date() });
    }
    return [...behandlesAv];
  };

  const handleFilterChanged = (value: string, type: string) => {
    let copyFilter = { ...searchFilter };
    switch (type) {
      case 'query':
        copyFilter.query = value;
        break;
      case 'take':
        const take = Number(value);
        copyFilter.take = take;
        break;
      case 'source':
        copyFilter.source = value;
        break;
      case 'orderBy':
        if (copyFilter.orderBy && copyFilter.orderBy === 'decending') {
          copyFilter.orderBy = 'ascending';
        } else {
          copyFilter.orderBy = 'decending';
        }
        break;
      case 'leverandorKundenummerHosTsKundeId':
        copyFilter.leverandorKundenummerHosTsKundeId = value;
        break;
      // case 'grossistKundenummerHosGln':
      //   copyFilter.grossistKundenummerHosGln = value;
      //   break;
    }
    setSearchFilter(copyFilter);
  }

  const handleFilterTagsChanged = (values: string[]) => {
    setSearchFilter(prev => ({ ...prev, tags: values }))
  }

  const handleFilterGlnChanged = (values: string[]) => {
    setSearchFilter(prev => ({ ...prev, grossistKundenummerHosGln: values }))
  }

  const handleFilterTabChanged = (value: string) => {
    setSearchFilter(prev => ({ ...prev, status: value }));
    setSelectedChangeRequest(null);
    resetScrollBars();
  }

  const resetScrollBars = () => {
    document.body.style.overflow = 'visible';
  }

  const hideScrollBars = () => {
    document.body.style.overflow = 'hidden';
  }

  const getStatistikk = async () => {
    const result = await QAApi.getStatistikk();
    if (isMountedRef.current) {
      setStatistikk(result);
    }
  };

  const getFilter = async () => {
    const result = await QAApi.getFilter();
    if (isMountedRef.current) {
      setFilter(result);
    }
  };

  const loadChangeRequests = async () => {
    setLoadingChangeRequests(true);
    getStatistikk();
    getFilter();
    const c = await QAApi.getEndringsMeldinger(searchFilter);
    setLoadingChangeRequests(false);
    if (isMountedRef.current) {
      if (searchFilter.status === ChangeRequestStatus.Ubehandlet) {
        setChangeRequests(c);
      } else if (searchFilter.status === ChangeRequestStatus.FolgesOpp) {
        setFolgesOppChangeRequests(c);
      } else if (searchFilter.status === ChangeRequestStatus.Behandlet) {
        setProcessedChangeRequests(c);
      } else if (searchFilter.status === ChangeRequestStatus.Avvist) {
        setRejectedChangeRequests(c);
      }
    }
  };

  const handleChangeRequestSelected = (cr: EndringsMelding, nextCr?: EndringsMelding) => {
    if (selectedChangeRequest && selectedChangeRequest.id !== cr.id) {
      if (cr) {
        if (nextCr) {
          setNextChangeRequest(nextCr);
        }
        setSelectedChangeRequest(cr);
        hideScrollBars();
        QAApi.settTilBehandles({
          id: cr.id,
          approvedByusername: currentUser,
        });
      }
    }
    if (!selectedChangeRequest) {
      if (cr) {
        if (nextCr) {
          setNextChangeRequest(nextCr);
        }
        setSelectedChangeRequest(cr);
        hideScrollBars();
        QAApi.settTilBehandles({
          id: cr.id,
          approvedByusername: currentUser,
        });
      }
    }
  }

  const handleGotoNext = () => {
    if (nextChangeRequest) {
      setSelectedChangeRequest(nextChangeRequest);
      hideScrollBars();
      const nextIndex = changeRequests.findIndex(x => x.id === nextChangeRequest.id);
      setNextChangeRequest(changeRequests[nextIndex + 1]);
    }
  };

  const handleCloseWindowClicked = () => {
    QAApi.frigi(selectedChangeRequest.id, username);
    setSelectedChangeRequest(null);
  };

  const loadUrl = async () => {
    const n = await QAApi.getUrlWithCode('Endringsmeldinger');
    if (isMountedRef.current && n) {
      setNegotiateUrl(n);
    }
  };

  useEffect(() => {
    loadUrl();
  }, [loadUrl])

  useEffect(() => {
    debounce(() => loadChangeRequests(), 300)();
  }, [searchFilter]);

  useEffect(() => {
    if (currentStatusSignalrMessage) {
      updateChangeRequestIfVisible(currentStatusSignalrMessage);
    }
  }, [currentStatusSignalrMessage]);

  useEffect(() => {
    if (currentBehandlesAvSignalrMessage) {
      updateChangeRequestIfVisible(currentBehandlesAvSignalrMessage);
    }
  }, [currentBehandlesAvSignalrMessage]);

  if (!negotiateUrl) {
    return <></>;
  }

  return (
    <Container fluid className={Style.container}>
      <PageHeader></PageHeader>
      {
        statistikk &&
        <Row>
          <Col md={4}>
            <QACardList filter={searchFilter} onFilterChanged={handleFilterChanged}
              onFilterTagsChanged={handleFilterTagsChanged}
              onFilterGlnChanged={handleFilterGlnChanged}
              onFilterTabChanged={handleFilterTabChanged}
              signalRConnectionStatus={signalRConnectionStatus}
              loadingChangeRequest={loadingChangeRequest}
              statistikk={statistikk}
              changeRequests={changeRequests}
              folgesOppRequests={folgesOppChangeRequests}
              processedChangeRequests={processedChangeRequests}
              rejectedChangeRequests={rejectedChangeRequests}
              selectedChangeRequest={selectedChangeRequest}
              onChangeRequestSelected={handleChangeRequestSelected} />
          </Col>
          <Col md={8}>
            {
              selectedChangeRequest ?
                <QACardDetails changeRequest={selectedChangeRequest}
                  onCloseWindowClicked={handleCloseWindowClicked}
                  onGotoNext={handleGotoNext} /> :
                <div className={Style.notSelectedChangeRequestContainer}>
                  <div style={{ display: 'flex' }}>
                    <QaStats data={statistikk} />
                  </div>
                  <div className={Style.filterLinkBtn}>
                    <Button variant="link" size="lg" onClick={() => navigate('/qa/filter')}>
                      Antall aktive avvisningsfilter: {filter.length}
                    </Button>
                  </div>
                </div>
            }
          </Col>
        </Row>
      }
    </Container>
  )
}

export default QA;