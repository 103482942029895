import { useState, useEffect } from 'react';
import { Form, Row, Col, InputGroup, Button, Spinner } from 'react-bootstrap';

import BrregTypeAhead from 'components/BrregTypeAhead';
import AddressTypeahead from 'components/AddressTypeahead';
import { IAdresse } from 'components/AddressTypeahead/types';
import AlertDismissible from 'components/AlertDismissible';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import { CreateEnhetCommand } from 'services/KjederegisteretAdminApi/EnhetApi/Commands';
import { EnhetViewModel } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import GrossisterApi from 'services/KjederegisteretAdminApi/GrossisterApi';
import { Grossist } from 'services/KjederegisteretAdminApi/GrossisterApi/types';
import { BrregEnhetResponse } from 'services/KjederegisteretAdminApi/BrregApi/types';

import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import HttpErrorHelper from 'utils/HttpErrorHelper';

import Style from './index.module.css';

interface Props {
  setCreatedGrossist: (grossist: Grossist) => void;
}

const CreateGrossist = (props: Props) => {
  const isMountedRef = useIsMountedRef();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [krOrgNrRes, setKrOrgNrRes] = useState<EnhetViewModel[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [address, setAddress] = useState<IAdresse>();
  const [grossist, setGrossist] = useState<Grossist>();
  const toaster = useToaster();

  const defaultValues: CreateEnhetCommand = {
    enhetnavn: '',
    juridisknavn: '',
    organisasjonsnummer: '',
    telefon: '',
    epost: '',
    fakturaEpost: '',
    kontonr: '',
    gln: '',
    notat: '',
    erOffentlig: false,
    besoksAdresse: {
      gateadresse: '',
      postnr: '',
      poststed: '',
      formattedForOneLine: '',
      landkode: ''
    },
    enhettypekode: '',
    oppstartsdato: new Date(),
  };

  const validate = (values: CreateEnhetCommand) => {
    enum ErrorMessage {
      NoEnhetnavn = 'Enhetnavn mangler!',
      NoOrgnr = 'Brreg enhet mangler!',
      NoBesoksadresse = 'Adresse mangler!',
    }
    let errors: any = {};

    if (!values.enhetnavn) {
      errors.enhetnavn = ErrorMessage.NoEnhetnavn;
    }
    if (!values.organisasjonsnummer && !values.juridisknavn) {
      errors.orgnr = ErrorMessage.NoOrgnr;
    }
    if (!address?.gateadresse) {
      errors.besoksAdresse = ErrorMessage.NoBesoksadresse;
    }
    return errors;
  };

  const onSubmit = async () => {
    try {
      setSubmitLoading(true);
      const copy = { ...values };
      copy.enhettypekode = 'Grossist';

      // work with a separate state for address, because of picker issues and form values
      copy.besoksAdresse = address;
      updateValues(copy);
      const enhetId = await EnhetApi.createEnhet(copy);

      if (enhetId) {
        const res = await GrossisterApi.addGrossist({
          grossistEnhetId: enhetId,
          kortnavn: values.enhetnavn.toUpperCase(),
          grossistgruppe: grossist.grossistgruppe.toUpperCase(),
        });

        if (isMountedRef.current) {
          props.setCreatedGrossist(res);
          resetForm(defaultValues);
          toaster.success('Suksess!', 'Grossist er opprettet.');
        }
      }
    } catch (err) {
      toaster.error('Feil! Kunne ikke opprette.', HttpErrorHelper.handleErrorMessage(err));
    }
    setSubmitLoading(false);
  };

  const { values, touched, errors, handleChange, handleSubmit, submitDisabled, updateValues, resetForm } = useForm(defaultValues, validate, onSubmit);

  const handleAddressSelected = (a: IAdresse) => {
    setAddress(a);
  };

  const handleBrregEnhetSelected = (enhet: BrregEnhetResponse) => {
    if (enhet) {
      const copy = { ...values };
      copy.organisasjonsnummer = enhet.orgNr;
      copy.juridisknavn = enhet.name;
      updateValues(copy);
      checkIfOrgNrExistsInKr(copy.organisasjonsnummer);
    } else {
      const copy = { ...values };
      copy.organisasjonsnummer = '';
      copy.juridisknavn = '';
      updateValues(copy);
    }
  };

  const checkIfOrgNrExistsInKr = async (orgnr: string) => {
    // Check if enhet with orgnr exists in kjederegisteret
    try {
      const orgNrRes = await EnhetApi.getByOrgnr(orgnr);
      if (orgNrRes) {
        setKrOrgNrRes(orgNrRes);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (krOrgNrRes.length > 0 && !showAlert) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [krOrgNrRes]);

  const krOrgNrCountAlertBody = (
    <div>
      <p>
        Det er <strong>{krOrgNrRes.length}</strong> enheter med samme organisasjonsnummer i
        kjederegisteret. Naviger til &quot;Bruk enhet&quot; for å opprette ny hovedgrossist med
        eksisterende enhet.
      </p>
    </div>
  );

  return (
    <div className={Style.createGrossistContainer}>
      <h5>Opprett ny enhet som hovedgrossist</h5>
      {showAlert && (
        <AlertDismissible
          setShow={setShowAlert}
          variant="warning"
          alertHeader="OBS! Eksisterer enheten fra før?">
          {krOrgNrCountAlertBody}
        </AlertDismissible>
      )}
      <Form onSubmit={handleSubmit}>
        <Form.Group className='mb-3'>
          <Form.Label className={Style.formLabel}>
            Velg enhet fra brønnøysundregisteret *
          </Form.Label>
          <InputGroup>
            <InputGroup.Text style={{ paddingLeft: '.6rem', paddingRight: '.6rem' }}>
              <IcebergIcon icon="search" />
            </InputGroup.Text>
            <BrregTypeAhead
              required
              isValid={
                (touched.orgnr && !errors.orgnr) || (touched.juridisknavn && !errors.juridisknavn)
                  ? true
                  : false
              }
              isInvalid={
                (touched.orgnr && !!errors.orgnr) ||
                  (touched.juridisknavn && !!errors.juridisknavn)
                  ? true
                  : false
              }

              onBrregEnhetSelected={(e) => handleBrregEnhetSelected(e)}
            />

            {touched.orgnr && errors.orgnr && (
              <Form.Text className="invalid-feedback" style={{ display: 'block' }}>
                {errors.orgnr}
              </Form.Text>
            )}
          </InputGroup>
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label className={Style.formLabel}>Velg adresse *</Form.Label>
          <InputGroup>
            <InputGroup.Text style={{ paddingLeft: '.6rem', paddingRight: '.6rem' }}>
              <IcebergIcon icon="search" />
            </InputGroup.Text>
            <AddressTypeahead
              isValid={touched.besoksAdresse && !errors.besoksAdresse ? true : false}
              isInvalid={touched.besoksAdresse && !!errors.besoksAdresse ? true : false}
              onChange={handleAddressSelected}
              selected={address}
            />
            {touched.besoksAdresse && errors.besoksAdresse && (
              <Form.Text className="invalid-feedback" style={{ display: 'block' }}>
                {errors.besoksAdresse}
              </Form.Text>
            )}
          </InputGroup>
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label className={Style.formLabel}>Kortnavn *</Form.Label>
          <Form.Control
            type="text"
            placeholder="Kortnavn"
            aria-describedby="inputGroup-enhetnavn"
            value={values.enhetnavn}
            onChange={e => handleChange('enhetnavn', e.target.value.toUpperCase())}
            isInvalid={touched.enhetnavn && errors.enhetnavn ? true : false}
            isValid={touched.enhetnavn && !errors.enhetnavn ? true : false}
            required
          />
          <Form.Control.Feedback type="invalid">{errors.enhetnavn}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label className={Style.formLabel}>Grossistgruppe</Form.Label>
          <Form.Control
            type="text"
            placeholder='Grossistgruppe'
            aria-describedby='inputGroup-grossistgruppe'
            value={grossist?.grossistgruppe || ''}
            onChange={e => setGrossist(prevGrossist => ({ ...prevGrossist, grossistgruppe: e.target.value.toUpperCase() }))}
            isInvalid={touched.grossistgruppe && errors.grossistgruppe ? true : false}
            isValid={touched.grossistgruppe && !errors.grossistgruppe ? true : false}
          />
          <Form.Control.Feedback type="invalid">{errors.grossistgruppe}</Form.Control.Feedback>
        </Form.Group>
        <Row className={Style.opprettBtnRow}>
          <Col className={Style.opprettBtnCol}>
            <Button type="submit" disabled={submitDisabled}>
              {submitLoading && (
                <Spinner
                  style={{
                    padding: '0',
                    marginRight: '0.3em',
                  }}
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              <span>
                Opprett
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default CreateGrossist;
