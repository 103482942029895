import React, { useState, useEffect } from 'react';
import DatePicker from 'components/DatePicker';
import EierskifteEnhetTypeahead from './EierskifteEnhetTypeahead';
import { EierskifteEnhetViewModel } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { Alert, FormGroup, Row, Spinner, Col, Modal, Button, Form } from 'react-bootstrap';
import Style from './index.module.css';

interface Props {
  isNedlagt: boolean;
  isFutureGruppering: boolean;
  isFutureKjedemedlemskap: boolean;
  viderefortTil?: number;
  show?: boolean;
  handleClose: () => void;
  handleSave: (dato: Date, navn: string, oppstartDato: Date, videreforTilEnhetId: number, fjernVidereforing: boolean, viderforKjederTilNyEnhet: boolean, videreforKontaktinfoTilNyEnhet: boolean) => void;
  utforIsLoading: boolean;
  loadEierskifteState: () => void;
}

const EierskifteModal: React.FC<Props> = props => {

  const [nedleggelsesDate, setNedleggelsesDate] = useState<Date>(new Date());
  const [navn, setNavn] = useState('');
  const [oppstartDate, setOppstartDate] = useState<Date>(
    new Date(new Date(nedleggelsesDate.getFullYear(),
      nedleggelsesDate.getMonth(),
      nedleggelsesDate.getDate() + 1,
      nedleggelsesDate.getHours(),
      nedleggelsesDate.getMinutes(),
      nedleggelsesDate.getSeconds()) || new Date())
  );
  const [isDatesValid, setIsDatesValid] = useState<boolean>(true);
  const [viderefores, setViderefores] = useState<boolean>(false);
  const [viderforKjederTilNyEnhet, setViderforKjederTilNyEnhet] = useState<boolean>(false);
  const [videreforTil, setVidereforesTil] = useState<EierskifteEnhetViewModel | null>(null);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false);
  const [fjernVidereforing, setFjernVidereforing] = useState<boolean>(false);
  const [videreforKontaktinfoTilNyEnhet, setVidereforKontaktinfoTilNyEnhet] = useState<boolean>(false);
  const [showDateIsTodayWarning, setShowDateIsTodayWarning] = useState<boolean>(false);

  const toggleViderefor = () => {
    setViderefores(!viderefores);
    setNavn('');
  };

  const toggleFjernVidereforing = () => {
    setFjernVidereforing(!fjernVidereforing);
  };

  const toggleViderforKjederTilNyEnhet = () => {
    setViderforKjederTilNyEnhet(!viderforKjederTilNyEnhet);
  };

  const toggleViderforKontaktinfoTilNyEnhet = () => {
    setVidereforKontaktinfoTilNyEnhet(!videreforKontaktinfoTilNyEnhet);
  };

  const handleVidereforingTil = (e: EierskifteEnhetViewModel) => {
    if (!e) {
      setVidereforesTil(null);
      return;
    }

    setVidereforesTil(e);
  };

  useEffect(() => {
    if (nedleggelsesDate) {
      setOppstartDate(new Date(nedleggelsesDate.getFullYear(),
        nedleggelsesDate.getMonth(),
        nedleggelsesDate.getDate() + 1,
        nedleggelsesDate.getHours(),
        nedleggelsesDate.getMinutes(),
        nedleggelsesDate.getSeconds()) || new Date()
      );
    }
  }, [nedleggelsesDate])

  useEffect(() => {
    if (videreforTil && navn) {
      setIsSubmitEnabled(false);
      return;
    }
    else {
      setIsSubmitEnabled(true);
    }

    if (!isDatesValid) {
      setIsSubmitEnabled(false);
      return;
    }
    else {
      setIsSubmitEnabled(true);
    }

    if (videreforTil) {
      setIsSubmitEnabled(videreforTil.kanVidereforeTil);
    }

    if (props.viderefortTil) {
      setIsSubmitEnabled(fjernVidereforing);
    }

    if (!props.isNedlagt && props.isFutureGruppering) {
      setIsSubmitEnabled(false);
    }
    if (!props.isNedlagt && props.isFutureKjedemedlemskap) {
      setIsSubmitEnabled(false);
    }
  }, [videreforTil, navn, fjernVidereforing, isDatesValid, props.isFutureKjedemedlemskap, props.isFutureGruppering])

  useEffect(() => {
    if (!navn || !nedleggelsesDate || !oppstartDate) {
      setIsDatesValid(true);
      return;
    }

    if (nedleggelsesDate > oppstartDate)
      setIsDatesValid(false);
    else
      setIsDatesValid(true);
  }, [nedleggelsesDate, oppstartDate, navn])

  useEffect(() => {
    let nedleggelseDato = new Date(nedleggelsesDate);
    let today = new Date();
    if (viderefores && nedleggelseDato.getDate() === today.getDate()) {
      setShowDateIsTodayWarning(true);
    }
    else {
      setShowDateIsTodayWarning(false);
    }
  }, [nedleggelsesDate, viderefores])

  useEffect(() => {
    props.loadEierskifteState();
  }, []);

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{!props.isNedlagt ? 'Eierskifte / Nedleggelse' : 'Eierskifte'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.isFutureGruppering && (
            <Alert variant="info">
              <p><strong>OBS: </strong>Enheten er fremtidig innmeldt i en gruppering.</p>
              <hr />
              <p className="mb-0">
                Medlemskap må fjernes før nedleggelse er mulig.
              </p>
            </Alert>
          )}
          {props.isFutureKjedemedlemskap && (
            <Alert variant="info">
              <p><strong>OBS: </strong>Enheten er fremtidig innmeldt i et kjedemedlemskap</p>
              <hr />
              <p className="mb-0">
                Medlemskap må fjernes før nedleggelse er mulig.
              </p>
            </Alert>
          )}
          <Form>
            <Form.Group className='mb-3'>
              <Col>
                <Form.Label>Nedleggelsesdato</Form.Label>
                <DatePicker disabled={props.isNedlagt || props.isFutureGruppering || props.isFutureKjedemedlemskap} selected={nedleggelsesDate} onChange={setNedleggelsesDate} adjustTimeForTimezoneOffset={true} />
                {props.isNedlagt && (
                  <Form.Text>Må reaktivere for å endre nedleggelsesdato</Form.Text>
                )}
                {showDateIsTodayWarning && (
                  <Form.Text style={{ color: 'rgba(var(--bs-warning-rgb))' }}>Skal nedleggelsesdato være dagens dato?</Form.Text>
                )}
              </Col>
            </Form.Group>
            {props.viderefortTil && (
              <div>
                <FormGroup className='mb-3'>
                  <Col>
                    <div className="card card-body bg-light">
                      <Form.Label>Enhet er videreført til løpenummer {props.viderefortTil}</Form.Label>
                      <Form.Check
                        type="checkbox"
                        defaultChecked={fjernVidereforing}
                        onChange={toggleFjernVidereforing}
                        label="Fjern videreføring?"
                      />
                    </div>
                  </Col>
                </FormGroup>
              </div>
            )}
            {!props.viderefortTil && (
              <div>
                <Form.Group className='mb-3'>
                  <Col>
                    <div className="card card-body bg-light">
                      <Form.Check
                        type="checkbox"
                        defaultChecked={viderefores}
                        onChange={toggleViderefor}
                        label="Viderefør virksomheten til ny eier"
                      />
                    </div>
                  </Col>
                </Form.Group>
                {viderefores && (
                  <div>
                    <Form.Group className='mb-3'>
                      <Col>
                        <Form.Label>Navn på ny enhet</Form.Label>
                        <Form.Control
                          type="text"
                          name="enhetNavn"
                          value={navn}
                          onChange={e => setNavn(e.target.value.toUpperCase())}
                          disabled={videreforTil !== null}
                        />
                      </Col>
                    </Form.Group>
                    {navn && (
                      <Form.Group className='mb-3'>
                        <Col>
                          <div className="card card-body bg-light">
                            <Row className="margin-bottom-1rem">
                              <Col>
                                <Form.Label>Oppstart ny enhet</Form.Label>
                                <DatePicker selected={oppstartDate} onChange={setOppstartDate} adjustTimeForTimezoneOffset={true} />
                                {!isDatesValid && (
                                  <Form.Text style={{ color: 'rgba(var(--bs-success-rgb))' }}>Oppstartsdato for ny enhet må være etter nedlagtdato</Form.Text>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Form.Check
                                  type="checkbox"
                                  defaultChecked={viderforKjederTilNyEnhet}
                                  onChange={toggleViderforKjederTilNyEnhet}
                                  label="Viderefør aktive kjeder og enhetsgrupperinger til ny enhet"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Form.Check
                                  type="checkbox"
                                  defaultChecked={videreforKontaktinfoTilNyEnhet}
                                  onChange={toggleViderforKontaktinfoTilNyEnhet}
                                  label="Viderefør kontaktinfo og kjedeavdelingsnummer (hvis kjeder videreføres)"
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Form.Group>
                    )}
                    <Form.Group className='mb-3'>
                      <Col>
                        <Form.Label>eller finn en eksisterende enhet å videreføre til</Form.Label>
                        <EierskifteEnhetTypeahead
                          onChange={handleVidereforingTil}
                          isValid={videreforTil?.kanVidereforeTil}
                          disabled={navn.length > 0}
                        />
                        {videreforTil && !videreforTil.kanVidereforeTil && (
                          <Form.Text style={{ color: 'rgba(var(--bs-danger-rgb))' }}>Kan ikke videreføre til enhet som allerede har registrert forrige løpenummer</Form.Text>
                        )}
                      </Col>
                    </Form.Group>
                  </div>
                )}
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Lukk
          </Button>
          <Button
            className={Style.spinnerBtn}
            variant="primary"
            disabled={!isSubmitEnabled}
            onClick={() => props.handleSave(
              nedleggelsesDate,
              navn,
              oppstartDate,
              videreforTil?.enhetId,
              fjernVidereforing,
              viderforKjederTilNyEnhet,
              videreforKontaktinfoTilNyEnhet
            )}>
            <span>Utfør</span>
            {props.utforIsLoading && (
              <Spinner
                className={Style.spinner}
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EierskifteModal;
