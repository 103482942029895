import React, { useState, useEffect } from "react";
import { Button, Collapse, Form, Row, InputGroup } from "react-bootstrap";

import cx from "classnames"
import { format } from "date-fns";

import CompareIcon from "../../../CompareIcon";

import CopyToClipboardIcon from "components/CopyToClipboardIcon";
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import DatePicker from "components/DatePicker";

import SalgsdataInfoApi from "services/SalgsdatainfoApi";
import { KundePrGrossistgruppeSisteFakturadato } from "services/SalgsdatainfoApi/types";

import useIsMountedRef from "utils/hooks/useIsMountedRef";

import { qaContainsInText } from "pages/QA/components/QACardDetails/utils";

import Style from "../../index.module.css";
import { LeverandorKundenummerDto } from "services/KjederegisteretAdminApi/EnhetApi/types";

interface Props {
  crNedlagtDato?: Date;
  enhetNedlagtDato?: Date;
  enhetOppstartDato: Date;
  onChange: (date: Date) => void;
  changeRequest: {

    leverandorKundenummerHosTsKundeId: string;
    leverandorKundenummerHos: string;
    leverandorKundenummer: string;
  };
  enhetId?: number;
  lopenummer?: string;
  leverandorKundenummer?: LeverandorKundenummerDto[];

}

interface Kundenr {
  navn: string;
  kundenr: string;
}


const NedlagtDatoRow: React.FC<Props> = props => {
  const isMountedRef = useIsMountedRef();

  const [isExpandRow, setIsExpandRow] = useState<boolean>(false);
  const [crDate, setCrDate] = useState<string>('');
  const [compareStatus, setCompareStatus] = useState<string>(
    qaContainsInText(
      props.crNedlagtDato ? format(props.crNedlagtDato, 'dd.MM.yyyy') : null,
      props.enhetNedlagtDato ? format(props.enhetNedlagtDato, 'dd.MM.yyyy') : null
    ),
  );
  const [sisteFakturaLinje, setSisteFakturaLinje] = useState<KundePrGrossistgruppeSisteFakturadato | undefined>();

  const loadEnhetStatestikk = async (lopenummer: string) => {
    const result = await SalgsdataInfoApi.getSalgsdata(lopenummer);
    if (result && result.kundePrGrossistgruppePrMaaned.length > 0) {
      setSisteFakturaLinje(result.kundePrGrossistgruppeSisteFakturadato[0]);
    } else {
      setSisteFakturaLinje(undefined);
    }
  };

  const handleDateChanged = (date: Date) => {
    if (date) {
      props.onChange(date);
    } else {
      props.onChange(null);
    }
  };

  const calculateMinDate = (date: Date, addDays: number) => new Date(date.getTime() + addDays * 86400000);


  useEffect(() => {
    if (props.lopenummer) {
      loadEnhetStatestikk(props.lopenummer);
    }
  }, [props.lopenummer])

  useEffect(() => {
    if (isMountedRef.current) {
      if (props.crNedlagtDato) {
        setCrDate(format(props.crNedlagtDato, 'dd.MM.yyyy'))
      } else {
        setCrDate('');
      }
    }
  }, [props.crNedlagtDato]);

  useEffect(() => {
    if (compareStatus === 'ulik' || compareStatus === 'ny' || props.enhetNedlagtDato || sisteFakturaLinje !== null) {
      setIsExpandRow(true);
    }
  }, [compareStatus]);

  useEffect(() => {
    setCompareStatus(
      qaContainsInText(
        props.crNedlagtDato ? format(props.crNedlagtDato, 'dd.MM.yyyy') : null,
        props.enhetNedlagtDato
          ? format(props.enhetNedlagtDato, 'dd.MM.yyyy')
          : null,
      ),
    );
  }, [props.crNedlagtDato, props.enhetNedlagtDato]);

  return (
    <>
      <div className={cx(Style.changeRequestRowHeaderContainer, { [Style.isNotExpandedRowContainer]: !isExpandRow })}
        onClick={() => setIsExpandRow(prev => !prev)}>
        <h6 className={Style.changeRequestRowHeader}>
          {!isExpandRow && <CompareIcon compareStatus={compareStatus} size="sm" />}
          <span className={Style.changeRequestRowHeaderText}>Nedlagtdato</span>
        </h6>
        <Button variant="link" size="sm">
          <IcebergIcon icon={isExpandRow ? 'chevron-up' : 'chevron-down'} bold />
        </Button>
      </div>
      <Collapse in={isExpandRow}>
        <div>
          <Row className={Style.formGroupRow}>
            <div className={Style.formGroupContainer}>
              <Form.Group className={Style.formGroup}>
                <InputGroup size="sm">
                  <Form.Control
                    placeholder="dd.mm.yyyy"
                    className={Style.formControl}
                    style={{ color: 'red' }} // Ønske om tydeligere nedlagtdato
                    readOnly value={crDate} />
                  {crDate && (
                    <InputGroup.Text>
                      <CopyToClipboardIcon val={crDate} />
                    </InputGroup.Text>

                  )}
                </InputGroup>
              </Form.Group>
            </div>
            <div className={Style.compareIconContainer}>
              <CompareIcon
                compareStatus={compareStatus}
                onClick={() => handleDateChanged(props.crNedlagtDato)}
              />
            </div>
            <div className={Style.formGroupContainer}>
              <Form.Group className={Style.formGroup}>
                <DatePicker
                  placeholderText="dd.mm.yyyy"
                  selected={props.enhetNedlagtDato ? new Date(props.enhetNedlagtDato) : undefined}
                  onChange={d => handleDateChanged(d)}
                  minDate={props.enhetOppstartDato && calculateMinDate(new Date(props.enhetOppstartDato), 1)}
                  adjustTimeForTimezoneOffset={true}
                  isClearable
                  isSmall={true} />
              </Form.Group>
              {props.enhetOppstartDato && (
                <Form.Text className="text-muted">
                  Oppstartdato:{' '}
                  {format(props.enhetOppstartDato, 'dd.MM.yyyy')} <br />
                </Form.Text>
              )}
              {props.leverandorKundenummer?.length > 0 && <Form.Text>
                {props.leverandorKundenummer.length} leverandørkundenummer finnes på enheten<br />
              </Form.Text>}
              {props.leverandorKundenummer?.length === 0 && <Form.Text>
                Ingen leverandørkundenummer finnes på enheten<br />
              </Form.Text>}
              {!sisteFakturaLinje && (
                <Form.Text> <span> Finner ingen grossist fakturadata </span></Form.Text>
              )}
              {sisteFakturaLinje &&
                <Form.Text className='text-muted'>
                  Siste faktura: <strong className='text-primary'> {sisteFakturaLinje.grossistGruppeTypeKode} {format(sisteFakturaLinje.sisteFakturadato, 'dd.MM.yyyy')} </strong>
                </Form.Text>
              }
            </div>
          </Row>
        </div>
      </Collapse>
    </>
  )
}

export default NedlagtDatoRow;