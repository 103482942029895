import React from "react";
import { ButtonToolbar, ButtonGroup, Button, Spinner } from "react-bootstrap";

import { EndringsMelding } from "services/QAApi/types";

import Style from "./index.module.css";

interface Props {
    changeRequest: EndringsMelding;
    submitDisabled: boolean;
    folgesOppLoading: boolean;
    submitLoading: boolean;
    onFolgesOppClicked: () => void;
    onIgnoreClicked: () => void;
    onSubmitClicked: () => void;
    onAvvisClicked: () => void;
}

const DetailStickyFooter = (props: Props) => {

    return (
        <div className={Style.footerContainer}>
            <ButtonToolbar className={Style.actionBtnToolbar}>
                <ButtonGroup>
                    <Button variant="outline-primary" size="sm" className="me-2"
                        disabled={!!(props.changeRequest.status === 'Avvist' || props.changeRequest.status === 'Behandlet')}
                        onClick={() => props.onFolgesOppClicked()}>
                        <span>{props.changeRequest.status === 'FolgesOpp' ? 'Stans oppfølging' : 'Følg opp'}</span>
                        {props.folgesOppLoading && <Spinner animation="border" as="span" size="sm" />}
                    </Button>
                    <Button variant="outline-primary" size="sm" className="me-2"
                        disabled={!!(props.changeRequest.status === 'Avvist' || props.changeRequest.status === 'Behandlet')}
                        onClick={props.onAvvisClicked}>
                        <span>Avvis</span>
                    </Button>
                    <Button variant="outline-primary" size="sm" className="me-2"
                        disabled={!!(props.changeRequest.status === 'Behandlet' || props.changeRequest.status === 'Avvist')}
                        onClick={props.onIgnoreClicked}>
                        <span>Ignorer</span>
                    </Button>
                    <Button variant="primary" size="sm"
                        disabled={props.submitDisabled}
                        onClick={props.onSubmitClicked}>
                        <span>Lagre</span>
                        {props.submitLoading && <Spinner animation="border" as="span" size="sm" />}
                    </Button>
                </ButtonGroup>
            </ButtonToolbar>
        </div>
    );
};

export default DetailStickyFooter;