import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import DatePicker from 'components/DatePicker';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import Style from './index.module.css';
import EnhetsgrupperingApi from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi';
import { Enhetgruppering, AddMedlemGruppeCommand } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';
import GrupperingPicker from 'components/GrupperingPicker';
import { IMappedGrupperingNode } from 'components/GrupperingPicker/types';

interface Props {
  setIsEdit: (isEdit: boolean) => void;
  gruppering: Enhetgruppering;
  onDone: () => void;
}

const MedlemskapEdit: React.FC<Props> = props => {
  const toaster = useToaster();

  const [changeDate, setChangeDate] = useState(new Date());
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [enhetsgruppeIdToAdd, setEnhetsgruppeIdToAdd] = useState<number | null>();

  const onChange = async (grupper: IMappedGrupperingNode[]) => {
    if (grupper.length > 0) {
      setEnhetsgruppeIdToAdd(grupper[0].id);
    } else {
      setEnhetsgruppeIdToAdd(null);
    }
  };

  const addMedlemGruppe = async () => {
    const command: AddMedlemGruppeCommand = {
      enhetsgruppeIdToAdd: enhetsgruppeIdToAdd,
      fraOgMedDato: changeDate,
    };

    try {
      await EnhetsgrupperingApi.addMedlemsgruppe(props.gruppering.enhetgrupperingID, command);
      toaster.success('Suksess!', 'Medlemsgruppe lagt til!');
      props.onDone();
      props.setIsEdit(false);
    } catch (err) {
      toaster.error('Feil! Kunne ikke legge til.', HttpErrorHelper.handleErrorMessage(err));
    }
  };

  useEffect(() => {
    if (!enhetsgruppeIdToAdd) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [enhetsgruppeIdToAdd]);

  return (
    <div className={Style.container}>
      <Row>
        <Col md={6} lg={12} xl={6}>
          <Form.Label>Velg medlem</Form.Label>
          <GrupperingPicker onSelectionChanged={grupper => onChange(grupper)} />
        </Col>
        <Col md={6} lg={12} xl={6}>
          <Form.Label>Innmeldingsdato</Form.Label>
          <DatePicker selected={changeDate} onChange={d => setChangeDate(d)} adjustTimeForTimezoneOffset={true} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button disabled={isSubmitDisabled} className={Style.submitBtn} onClick={() => addMedlemGruppe()}>
            Legg til
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default MedlemskapEdit;
