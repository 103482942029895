import React, { useState, useEffect } from "react";
import { Button, Collapse, Row, Form, InputGroup, Badge } from "react-bootstrap";
import cx from "classnames";
import CompareIcon from "../../../CompareIcon";
import CopyToClipboardIcon from "components/CopyToClipboardIcon";
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import { Grossist } from "services/KjederegisteretAdminApi/ChangeRequestApi/types";
import { qaExistInCommaSeparatedString } from "pages/QA/components/QACardDetails/utils";
import Style from "../../index.module.css";
import { EnhetViewModel } from "services/KjederegisteretAdminApi/EnhetApi/types";
import EnhetApi from "services/KjederegisteretAdminApi/EnhetApi";
import openInNewTab from "utils/functions/openInNewTab";
import useIsMountedRef from "utils/hooks/useIsMountedRef";


interface Props {
  changeRequest: {
    grossistKundenummer?: string;
    grossistKundenummerHos?: string;
    grossistKundenummerHosGln?: string;
  };
  error?: string;
  hovedGrossist?: Grossist;
  andreGrossister?: Grossist[];
  onChange: (updatedRequest: Grossist, updateHovedGrossist: boolean) => void;
}

const GrossistKundenummerRow = (props: Props) => {
  const [isExpandRow, setIsExpandRow] = useState<boolean>(false);
  const [compareStatus, setCompareStatus] = useState<string>(
    props.changeRequest.grossistKundenummerHosGln ?
      (
        !props.hovedGrossist?.kundenummerHosGrossist ?
          'ulik' :
          qaExistInCommaSeparatedString(
            [
              props.andreGrossister?.filter(x => x !== undefined).map(x => x.kundenummerHosGrossist + '-' + x.grossistGln),
              props.hovedGrossist?.kundenummerHosGrossist + '-' + props.hovedGrossist?.grossistGln
            ].join(","),
            props.changeRequest.grossistKundenummer + '-' + props.changeRequest.grossistKundenummerHosGln,
          )
      ) :
      'ignorer',
  );

  const handleGrossistInfoUpdated = (grossistKundenummer: string, grossistGln: string, enhetNavn: string) => {
    props.onChange({
      kundenummerHosGrossist: grossistKundenummer,
      grossistGln: grossistGln,
      grossistEnhetNavn: enhetNavn
    }, !props.hovedGrossist?.kundenummerHosGrossist)
  };

  useEffect(() => {
    if (compareStatus === 'ulik' || compareStatus === 'ny' || compareStatus === 'ignorer') {
      setIsExpandRow(true);
    }
  }, [compareStatus]);

  useEffect(() => {
    setCompareStatus(
      props.changeRequest.grossistKundenummerHosGln ?
        (
          !props.hovedGrossist?.kundenummerHosGrossist ?
            'ulik' :
            qaExistInCommaSeparatedString(
              [
                props.andreGrossister?.filter(x => x !== undefined).map(x => x.kundenummerHosGrossist + '-' + x.grossistGln),
                props.hovedGrossist?.kundenummerHosGrossist + '-' + props.hovedGrossist?.grossistGln
              ].join(","),
              props.changeRequest.grossistKundenummer + '-' + props.changeRequest.grossistKundenummerHosGln,
            )
        ) :
        'ignorer',
    );
  }, [
    props.changeRequest.grossistKundenummer,
    props.hovedGrossist?.kundenummerHosGrossist,
    props.andreGrossister
  ]);



  const [enhetData, setEnhetData] = useState<EnhetViewModel[] | null>(null);
  const isMountedRef = useIsMountedRef();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const getGrossistkundenr = async () => {
      if (props.changeRequest.grossistKundenummerHosGln && props.changeRequest.grossistKundenummer) {
        setIsLoading(true);
        const data = await EnhetApi.getByGrossistkundenr(props.changeRequest.grossistKundenummerHosGln, props.changeRequest.grossistKundenummer);
        if (isMountedRef.current) {
          setEnhetData(data);
          setIsLoading(false);
        }
      }
    };

    getGrossistkundenr();

  }, [props.changeRequest.grossistKundenummerHosGln, props.changeRequest.grossistKundenummer]);




  return (
    <>
      <div className={cx(Style.changeRequestRowHeaderContainer, { [Style.isNotExpandedRowContainer]: !isExpandRow })}
        onClick={() => setIsExpandRow(!isExpandRow)}>
        <h6 className={Style.changeRequestRowHeader}>
          {!isExpandRow && <CompareIcon compareStatus={compareStatus} size="sm" />}
          <span className={Style.changeRequestRowHeaderText}>
            Kundenummer hos grossist:
            <strong> {props.changeRequest.grossistKundenummerHos} {props.changeRequest.grossistKundenummerHosGln && `(${props.changeRequest.grossistKundenummerHosGln})`}</strong>
          </span>
        </h6>
        <Button variant="link" size="sm">
          <IcebergIcon icon={isExpandRow ? 'chevron-up' : 'chevron-down'} bold />
        </Button>
        {
          isExpandRow && props.hovedGrossist?.grossistGln &&
          <span style={{ display: 'inline-block', marginLeft: 'auto' }}>
            {props.hovedGrossist?.grossistEnhetNavn} {' '}
            <Badge>Hovedgrossist</Badge>
          </span>
        }
      </div>
      <Collapse in={isExpandRow}>
        <div>
          <Row className={Style.formGroupRow}>
            <div className={Style.formGroupContainer}>
              <Form.Group className={Style.formGroup}>
                <InputGroup size="sm" className={Style.inputGroup}>
                  <Form.Control value={props.changeRequest.grossistKundenummer || ''} readOnly />
                  <InputGroup.Text>
                    <CopyToClipboardIcon val={props.changeRequest.grossistKundenummer} />
                  </InputGroup.Text>
                </InputGroup>

                {/* <Form.Text style={{ color: "red" }}>
                  {!isLoading && props.changeRequest.grossistKundenummer && props.changeRequest.grossistKundenummerHosGln && enhetData && `Kundenummeret ${props.changeRequest.grossistKundenummer} finnes allerede for enhet `}
                  {!isLoading && props.changeRequest.grossistKundenummer && props.changeRequest.grossistKundenummerHosGln && enhetData && enhetData.map((enhet, index, array) =>
                    <React.Fragment key={enhet.id}>
                      <Button variant="link" size="sm" onClick={() => openInNewTab(`/enheter/${enhet.id}`)}>
                        {enhet.enhetnavn}: {enhet.lopenr}
                      </Button>
                      {index < array.length - 1 ? ', ' : ''}
                    </React.Fragment>
                  )}
                </Form.Text> */}

              </Form.Group>
            </div>

            <div className={Style.compareIconContainer}>
              <CompareIcon
                compareStatus={compareStatus}
                onClick={() => handleGrossistInfoUpdated(props.changeRequest.grossistKundenummer, props.changeRequest.grossistKundenummerHosGln, props.changeRequest.grossistKundenummerHos)} />
            </div>

            <div className={Style.formGroupContainer}>
              <Form.Group className={Style.formGroup}>
                <InputGroup size="sm">
                  <Form.Control
                    value={props.hovedGrossist?.kundenummerHosGrossist || ''}
                    readOnly
                    isInvalid={!!props.error}
                  />
                </InputGroup>
                <Form.Text>
                  {
                    props.andreGrossister?.length > 0 &&
                    props.andreGrossister.filter(x => x.grossistGln !== undefined && x.grossistGln !== null).map(grossist =>
                      <span key={grossist.grossistGln} style={{ display: 'inline-block' }}>{grossist?.grossistEnhetNavn} {grossist?.kundenummerHosGrossist && `(${grossist?.kundenummerHosGrossist})`}</span>
                    )
                  }
                </Form.Text>
                <Form.Control.Feedback type="invalid">{props.error}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
        </div>
      </Collapse>
    </>
  )
}

export default GrossistKundenummerRow