
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { InputGroup, Button, Overlay, Tooltip, Spinner } from 'react-bootstrap';

import AddressDetails from './AddressDetails'

import AddressTypeahead from 'components/AddressTypeahead';
import { IAdresse } from 'components/AddressTypeahead/types';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import PostnummerRegisterApi from 'services/KjederegisteretAdminApi/PostnummerRegisterApi';
import { KontonrOgFakturaAdresseSjekkResponse } from 'services/KjederegisteretAdminApi/EnhetApi/types';

import { formatAddressAsOneLiner } from 'utils/AddressHelper';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';

import Style from './index.module.css';

interface Props {
  title: string;
  adresse: IAdresse;
  setAdresse: (adresse: IAdresse) => void;
  ekstraInfo: string;
  setEkstraInfo: (info: string) => void;
  setError: (hasError: boolean) => void;
  copyAddresses?: { adresse: IAdresse, title: string }[];
  kontonrOgFakturaAdresseSjekkResponse?: KontonrOgFakturaAdresseSjekkResponse;
  requireCoordinates?: boolean;
}

const AdresseInput: React.FC<Props> = props => {
  const isMountedRef = useIsMountedRef();

  const adresseInputRef = useRef(null);

  const [initAdresse, setInitAdresse] = useState<IAdresse>(props.adresse);
  const [touched, setTouched] = useState<boolean>(false);
  const [errors, setErrors] = useState({} as any);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [isResetDisabled, setIsResetDisabled] = useState<boolean>(true);
  const [showReadonlyWarning, setShowReadonlyWarning] = useState<boolean>(false);

  const handleUpdateCoordinates = (coor: { lat: number; lng: number }) => {
    if (!props.adresse) {
      const addr: IAdresse = {
        gateadresse: '',
        postnr: '',
        poststed: '',
        landkode: '',
        formattedForOneLine: '',
        latitude: coor.lat,
        longitude: coor.lng
      };
      props.setAdresse(addr);
    } else {
      props.setAdresse({ ...props.adresse, latitude: coor.lat, longitude: coor.lng })
    }
  }

  const handlePostnrChanged = async (postnr: string) => {
    const addressCopy = { ...props.adresse };
    if (postnr && postnr.length === 4) {
      const info = await PostnummerRegisterApi.getByPostkode(postnr);
      if (info) {
        addressCopy["postnr"] = postnr;
        addressCopy["poststed"] = info.poststed;
      } else {
        addressCopy["postnr"] = postnr;
        addressCopy["poststed"] = null
      }
    } else {
      addressCopy["postnr"] = postnr;
    }
    addressCopy.formattedForOneLine = formatAddressAsOneLiner(addressCopy);
    props.setAdresse(addressCopy);
  };

  // update specific address object value, ex: besoksadresse.postnr
  const handleAddressValuesChanged = (targetName: string, value: string) => {
    if (targetName) {
      const addressCopy = { ...props.adresse };
      addressCopy[targetName] = value;
      addressCopy.formattedForOneLine = formatAddressAsOneLiner(addressCopy);
      props.setAdresse(addressCopy);
    }
  }

  const validate = useCallback(() => {
    let adresseErrors: any = {};

    if (props.adresse) {
      let isPostnummerOnlyNumbers = /^\d+$/.test(props.adresse?.postnr);
      let hasPoststedNumbers = /\d/.test(props.adresse?.poststed);
      if (!props.adresse.gateadresse) adresseErrors.gateadresse = 'Gatenavn mangler!';
      if (!isPostnummerOnlyNumbers) adresseErrors.postnr = 'Bare tall i postnummer!';
      if (!props.adresse.postnr) adresseErrors.postnr = 'Postnummer mangler!';
      if (props.adresse.postnr?.length > 4) adresseErrors.postnr = 'Kun 4 tall i postnummer!';
      if (!props.adresse.poststed) adresseErrors.poststed = 'Poststed mangler!';
      if (hasPoststedNumbers) adresseErrors.poststed = 'Poststed skal ikke inneholde tall';
      if (!props.adresse.landkode) adresseErrors.landkode = 'Land mangler!';
      if (props.requireCoordinates && props.requireCoordinates === true) {
        if (!props.adresse.latitude || !props.adresse.longitude) {
          adresseErrors.koordinater = 'Koordinater mangler!';
        }
      }
    }
    setErrors(adresseErrors);
  }, [props.adresse]);

  useEffect(() => {
    setTouched(props.adresse?.formattedForOneLine !== initAdresse?.formattedForOneLine);
  }, [initAdresse, props.adresse])

  useEffect(() => {
    if (isMountedRef.current) {
      validate();
    }
  }, [isMountedRef, props.adresse, validate]);

  const hasError = errors && (Object.keys(errors).find(key => errors[key])?.length > 0);

  useEffect(() => {
    if (hasError)
      props.setError(true);
    else
      props.setError(false);
  }, [errors])

  const disableAddressInput = props.kontonrOgFakturaAdresseSjekkResponse && props.kontonrOgFakturaAdresseSjekkResponse.harSammeOrgNr && !props.kontonrOgFakturaAdresseSjekkResponse.fakturaAdresseErForskjellig;

  return (
    <div className={Style.addressContainer}>
      <h6>{props.title}</h6>
      {props.ekstraInfo && (
        <div>
          <span>Ekstra info: </span>
          <span className="text-muted">{props.ekstraInfo}</span>
        </div>
      )}
      <InputGroup className={`${Style.inputGroup}`} ref={adresseInputRef} onFocus={() => setShowReadonlyWarning(prev => disableAddressInput ? !prev : false)} onBlur={() => setShowReadonlyWarning(false)}>
        {!props.adresse ? (
          <InputGroup.Text style={{ paddingLeft: '.6rem', paddingRight: '.6rem' }}>
            <IcebergIcon icon="search" />
          </InputGroup.Text>
        ) : (
          <InputGroup.Text style={{ paddingLeft: '.45rem', paddingRight: '.45rem' }}>
            <IcebergIcon icon="check" size="lg" bold />
          </InputGroup.Text>
        )}
        <AddressTypeahead
          allowNew
          selected={props.adresse}
          onChange={(newadresse: IAdresse) => props.setAdresse(newadresse)}
          isInvalid={props.adresse?.formattedForOneLine.length > 0 && hasError}
          isValid={touched && props.adresse?.formattedForOneLine.length > 0 && !hasError}
          disabled={disableAddressInput}
        />
        <Button size='sm' variant="outline-primary" className={Style.detailBtn} onClick={() => { setShowDetails(prev => !prev); setIsResetDisabled(true); setShowReadonlyWarning(false) }}>
          {showDetails ? 'Lukk detaljer' : 'Vis detaljer'}
        </Button>
      </InputGroup>
      <Overlay rootClose target={adresseInputRef.current} show={showReadonlyWarning} placement="top">
        {(props) => (
          <Tooltip id="overlay-warning" className={Style.adresseTooltip} {...props}>
            Enhet har samme orgnr som kjedens hovedkontor og skal da dele fakturaadresse. Dette kan ikke endres.
          </Tooltip>
        )}
      </Overlay>
      {hasError && <div style={{ height: '25px', color: 'red' }}><span style={{ lineHeight: '2' }}>Ugyldig adresse</span></div>}
      {!disableAddressInput && <div className={Style.optionsContainer}>
        <div className={Style.addressOptions}>
          {
            props.copyAddresses && props.copyAddresses.length > 0 &&
            props.copyAddresses.map(copyAddress =>
              <Button
                key={copyAddress.title}
                size='sm'
                variant="link"
                disabled={!copyAddress.adresse || showDetails || copyAddress.adresse.formattedForOneLine === props.adresse?.formattedForOneLine}
                onClick={() => props.setAdresse(copyAddress.adresse)}>
                {copyAddress.title}
              </Button>
            )
          }
          <Button
            size='sm'
            variant="link"
            disabled={!touched || props.adresse?.formattedForOneLine === initAdresse?.formattedForOneLine}
            onClick={() => {
              props.setAdresse(initAdresse);
            }}
          >
            Resett
          </Button>
          {showDetails && (
            <Button
              size='sm'
              variant="link"
              onClick={() => {
                setIsResetDisabled(prev => !prev);
              }}
            >
              {isResetDisabled ? 'Rediger adresse' : 'Avbryt'}
            </Button>
          )}
        </div>

      </div>}
      {showDetails && (
        <div>
          <AddressDetails
            errors={errors}
            isFormDisabled={isResetDisabled}
            adresse={props.adresse}
            ekstraInfo={props.ekstraInfo}
            setEkstraInfo={props.setEkstraInfo}
            onValueChanged={handleAddressValuesChanged}
            onCoordinatesChanged={handleUpdateCoordinates}
            onPostnrChanged={handlePostnrChanged}
          />
        </div>
      )}
    </div>
  )
}

export default AdresseInput;
