import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HollowDotsSpinner } from 'react-epic-spinners';
import { Button, Row, Col, Table, Form, Modal, ButtonGroup, Spinner, Dropdown, InputGroup } from 'react-bootstrap';
import querystring from 'query-string';
import { debounce, isEqual } from 'lodash';
import Highlighter from "react-highlight-words";

// ref dist file, otherwise it will pick up source which breaks build
import InfiniteScroll from 'react-infinite-scroller/dist/InfiniteScroll';

import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';

import EnhetStatusIcon from './components/EnhetStatusIcon';
import FilterSearch from './components/FilterSearch';
import { EnhetFromSearch, EnhetSearchFilter } from 'services/KjederegisteretAdminApi/EnhetSearchApi/types';
import EnhetSearchPreview from './components/EnhetSearchPreview';
import SimpleCreateEnhet from './components/SimpleCreateEnhet';
import BulkJobModal from './components/BulkJobModal';
import SortingIcon from '../../components/SortingIcon';

import EnheterBulkApi from 'services/KjederegisteretAdminApi/EnheterBulkApi';
import { ByttAdresseCommand, ByttFakturaEpostCommand, ByttKontonummerCommand, FlyttEnheterTilNyHovedgrossistCommand } from 'services/KjederegisteretAdminApi/EnheterBulkApi/types';

import useDebounce from 'utils/hooks/useDebounce';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import { IAdresse } from 'components/AddressTypeahead/types';
import PageWrapper from 'components/PageWrapper';
import EnhetSearchApi from 'services/KjederegisteretAdminApi/EnhetSearchApi';
import useLocalStorage from "utils/hooks/useLocalStorage";
import Style from './index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

const EnhetSearch = () => {
    let defaultFilter = new EnhetSearchFilter();

    const location = useLocation();
    const navigate = useNavigate();

    if (location.search && location.search.length > 0) {
        const parsedFilter = querystring.parse(
            location.search.substring(1, location.search.length),
            { parseNumbers: true, parseBooleans: true, arrayFormat: 'bracket' },
        );

        if (parsedFilter.bransjeFilter)
            defaultFilter.bransjeFilter = ensureArray<any>(parsedFilter.bransjeFilter);

        if (parsedFilter.regionKjedeFilter)
            defaultFilter.regionKjedeFilter = ensureArray<any>(parsedFilter.regionKjedeFilter);

        if (parsedFilter.salgsOgMarkedsforingKjedeFilter)
            defaultFilter.salgsOgMarkedsforingKjedeFilter = ensureArray<any>(
                parsedFilter.salgsOgMarkedsforingKjedeFilter,
            );

        if (parsedFilter.ekskluderMarkedskjeder) {
            defaultFilter.ekskluderMarkedskjeder = parsedFilter.ekskluderMarkedskjeder.toString() === "true";
        }

        if (parsedFilter.grossistFilter) {
            defaultFilter.grossistFilter = ensureArray<any>(parsedFilter.grossistFilter);
        }
        if (parsedFilter.enhetsgrupperingFilter) {
            defaultFilter.enhetsgrupperingFilter = ensureArray<any>(parsedFilter.enhetsgrupperingFilter);
        }
        if (parsedFilter.enhetTypeFilter) {
            defaultFilter.enhetTypeFilter = ensureArray<any>(parsedFilter.enhetTypeFilter);
        }
        if (parsedFilter.criteria) {
            defaultFilter.criteria = parsedFilter.criteria.toString();
        }
        if (parsedFilter.aktivFilter) {
            defaultFilter.aktivFilter = parsedFilter.aktivFilter.toString();
        }
        if (parsedFilter.sistEndretAv) {
            defaultFilter.sistEndretAv = ensureArray<any>(parsedFilter.sistEndretAv);
        }

        if (parsedFilter.komplettTidspunktFilter) {
            defaultFilter.komplettTidspunktFilter = parsedFilter.komplettTidspunktFilter.toString();
        }

        if (parsedFilter.utenHovedbransjeFilter) {
            defaultFilter.utenHovedbransjeFilter = parsedFilter.utenHovedbransjeFilter.toString();
        }

        if (parsedFilter.utenHovedbransjeFilter) {
            defaultFilter.utenHovedbransjeFilter = parsedFilter.utenHovedbransjeFilter.toString();
        }

        if (parsedFilter.glnFilter) {
            defaultFilter.glnFilter = parsedFilter.glnFilter.toString();
        }

        if (parsedFilter.ikkePublisertFilter) {
            defaultFilter.ikkePublisertFilter = parsedFilter.ikkePublisertFilter.toString();
        }

        if (parsedFilter.opprettetFilter) {
            defaultFilter.opprettetFilter = parsedFilter.opprettetFilter.toString();
        }

        defaultFilter.fylker = Array.isArray(parsedFilter.fylker) ? parsedFilter.fylker.map(o => o.toString()) : [];
        defaultFilter.kommuner = Array.isArray(parsedFilter.kommuner) ? parsedFilter.kommuner.map(o => o.toString()) : [];
        defaultFilter.postnr = Array.isArray(parsedFilter.postnr) ? parsedFilter.postnr.map(o => o.toString()) : [];
    }

    const [criteria, setCriteria] = useState<string>(defaultFilter.criteria);
    const [filter, setFilter] = useState<EnhetSearchFilter>(defaultFilter);
    const [enheter, setEnheter] = useState<EnhetFromSearch[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);

    // case sensitive
    const [sortCol, setSortCol] = useLocalStorage<string | undefined>('krSortCol', undefined);
    const [colClicks, setColClicks] = useLocalStorage<number>('krSortColClicks', 0);

    const [showExportModal, setShowExportModal] = useState(false);
    const [showBulkModal, setShowBulkModal] = useState(false);
    const [isLoadingExport, setIsLoadingExport] = useState(false);

    const getValueFromQueryString = (key: string) => {
        const query = querystring.parse(window.location.search);
        return query[key];
    }

    const [visningsModus, setVisningsModus] = useState(getValueFromQueryString('visningsModus') || 'tabell');
    const [searchResponseCount, setSearchResponseCount] = useState<number>();

    const debouncedFilter = useDebounce(filter, 300);

    const searchTextboxRef = useRef(null);

    const isMountedRef = useIsMountedRef();

    function ensureArray<T>(input: T | T[]) {
        if (!Array.isArray(input)) {
            return [input];
        }
        return input;
    }

    const resetSort = () => {
        setSortCol(undefined);
        setColClicks(0);
    }

    const handleSearchEnheter = async () => {
        setIsSearching(true);
        console.log(filter);
        const response = await EnhetSearchApi.searchEnheter(filter);
        if (isMountedRef.current && response) {
            const mergedEnheterList = enheter.concat(response.items);

            setEnheter((filter.skip > 0 && enheter.length > 0) ? [...mergedEnheterList] : response.items);
            if (checkAll) {
                setSelectedLopenr(mergedEnheterList.map(x => x.lopenr));
            }
            setSearchResponseCount(response.count);
        }
        setIsSearching(false);
    };

    const handleFilterChanged = (newfilter: EnhetSearchFilter) => {
        if (isMountedRef.current && !isEqual(newfilter, filter)) {
            resetSort();
            setFilter({ ...newfilter, skip: 0 });
        }
    };

    const handleLoadMore = () => {
        if (!isSearching && (enheter.length < searchResponseCount) && isMountedRef.current) {
            setFilter({
                ...filter,
                skip: enheter.length
            })
        }
    };

    const handleColSortClicked = (col: string) => {
        if (col === sortCol) {
            if (colClicks === 2) {
                setSortCol(undefined);
            }
            setColClicks(prev => prev === 3 ? 1 : prev + 1);
        } else {
            setSortCol(col);
            setColClicks(1);
        }
    };

    const getRowColor = (enhet: { oppstartsdato?: Date; nedlagtDato?: Date }) => {
        if (enhet.nedlagtDato && enhet.nedlagtDato < new Date()) {
            return 'table-secondary';
        }
        if (enhet.oppstartsdato && enhet.oppstartsdato > new Date()) {
            return 'table-warning';
        }
    };

    const exportResultClicked = async () => {
        setIsLoadingExport(true);
        await EnhetApi.exportToExcel(filter).then(response => {
            const url = URL.createObjectURL(
                new Blob([response], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                }),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'enheter.xlsx');
            document.body.appendChild(link);
            link.click();
        });

        setIsLoadingExport(false);
        handleCloseExportModal();
    };

    const [isLoadingBulkJob, setIsLoadingBulkJob] = useState(false);
    const [bulkJobStarted, setBulkJobStarted] = useState(false);
    const handleBulkJobClicked = async (type: number, value: string | number | IAdresse, extraInfo?: string, ignoreAddress?: boolean, overforKundenr?: boolean) => {
        if ((value || (type === 0 && extraInfo && ignoreAddress)) && selectedLopenr) {
            switch (type) {
                case 0:
                    const byttAdresseCommand: ByttAdresseCommand = {
                        adressetype: "FakturaAdresse",
                        gateadresse: ignoreAddress ? '' : (value as IAdresse).gateadresse,
                        postnummer: ignoreAddress ? '' : (value as IAdresse).postnr,
                        lopenr: selectedLopenr,
                        ekstraInfo: extraInfo,
                        ignoreAdresse: ignoreAddress
                    }
                    setIsLoadingBulkJob(true);
                    await EnheterBulkApi.byttAdresse(byttAdresseCommand);
                    break;
                case 1:
                    const byttFakturaEpostCommand: ByttFakturaEpostCommand = {
                        epost: value as string,
                        lopenr: selectedLopenr
                    }
                    setIsLoadingBulkJob(true);
                    await EnheterBulkApi.byttFakturaEpost(byttFakturaEpostCommand);
                    break;
                case 2:
                    const byttKontonummerCommand: ByttKontonummerCommand = {
                        kontonummer: value as string,
                        lopenr: selectedLopenr
                    }
                    setIsLoadingBulkJob(true);
                    await EnheterBulkApi.byttKontonummer(byttKontonummerCommand);
                    break;
                case 3:
                    const flyttEnheterTilNyHovedgrossistCommand: FlyttEnheterTilNyHovedgrossistCommand = {
                        grossistNummer: Number(value),
                        overforKundenummer: overforKundenr,
                        lopenr: selectedLopenr
                    }
                    setIsLoadingBulkJob(true);
                    await EnheterBulkApi.flyttTilNyHovedgrossist(flyttEnheterTilNyHovedgrossistCommand);
                    break;
            }
            setIsLoadingBulkJob(false);
            setBulkJobStarted(true);
        }

    };

    const handleCloseFilterModal = () => setShowFilterModal(false);
    const openFilterModalClicked = () => setShowFilterModal(true);
    const handleCloseExportModal = () => setShowExportModal(false);
    const handleCloseBulkModal = () => {
        setShowBulkModal(false);
        setBulkJobStarted(false);
    }

    const [selectedLopenr, setSelectedLopenr] = useState<number[]>([]);
    const [checkAll, setCheckAll] = useState(false);

    const handleCheckAll = (on: boolean) => {
        setCheckAll(on);
        if (on) {
            setSelectedLopenr(enheter.map(x => x.lopenr));
        }
        else {
            setSelectedLopenr([]);
        }
    }

    const isLopenrSelected = (lopenr: number) => selectedLopenr.includes(lopenr);
    const handleLopenrSelected = (lopenr: number) => {
        if (isLopenrSelected(lopenr)) {
            setSelectedLopenr(selectedLopenr.filter(x => x !== lopenr));
        } else {
            setSelectedLopenr(selectedLopenr.concat(lopenr));
        }
    };

    const sortTableColumn = (col: string, clicks: number) => {
        setFilter({
            ...filter,
            skip: 0,
            sortBy: col,
            sortByDescending: clicks === 2,
        })
    };

    useEffect(() => {
        sortTableColumn(sortCol, colClicks);
    }, [sortCol, colClicks]);

    // set focus to input for searchtext on initial load
    useEffect(() => {
        if (searchTextboxRef) {
            searchTextboxRef.current.focus();
        }
    }, []);

    // oppdater url med endringer på filter og visningsmodus
    useEffect(() => {
        if (isMountedRef.current) {
            let qs = '?';

            if (filter) {
                qs = qs + querystring.stringify(filter, {
                    arrayFormat: 'bracket',
                    skipNull: true,
                });
            }

            if (visningsModus) {
                qs = `${qs}&visningsModus=${visningsModus}`;
            }

            navigate({
                pathname: '/enheter',
                search: qs,
            });
            handleSearchEnheter();

        }
    }, [debouncedFilter, visningsModus]);

    useEffect(() => {
        setCheckAll(selectedLopenr.length > 0 && enheter.length === selectedLopenr.length);
    }, [selectedLopenr]);

    useEffect(() => {
        if (isMountedRef.current && criteria !== filter.criteria) {
            debounce(() => {
                resetSort();
                setFilter({ ...filter, criteria: criteria, skip: 0 });
            }, 300)();
        }
    }, [criteria])

    return (
        <PageWrapper isFluid header="Enhetssøk">
            <Modal show={showFilterModal} onHide={handleCloseFilterModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showFilterModal && (
                        <FilterSearch filter={{ ...filter }} onFilterChanged={(newFilter: EnhetSearchFilter) => handleFilterChanged(newFilter)} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFilterModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showExportModal} onHide={handleCloseExportModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eksporter søkeresultat til Excel</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Last ned søkeresultat som excel-fil (.xlsx).</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseExportModal}>
                        Avbryt
                    </Button>
                    <Button variant="primary" onClick={exportResultClicked}>
                        {isLoadingExport && (
                            <span style={{ paddingRight: '.3em' }}>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            </span>
                        )}
                        <span style={{ paddingRight: '0.3em' }}>Last ned</span>
                        <IcebergIcon icon="export" />
                    </Button>
                </Modal.Footer>
            </Modal>

            <BulkJobModal show={showBulkModal} isLoadingBulkJob={isLoadingBulkJob} bulkJobStarted={bulkJobStarted}
                numberOfSelected={selectedLopenr.length} onClose={handleCloseBulkModal} handleBulkJob={handleBulkJobClicked} />

            <Row className={Style.container}>
                <Col className={Style.firstCol} lg="3">
                    <Dropdown
                        style={{ width: '100%', marginBottom: '1em', paddingLeft: '0', paddingRight: '0' }}
                        as={ButtonGroup}
                    >
                        <SimpleCreateEnhet />


                    </Dropdown>
                    {!showFilterModal && (
                        <FilterSearch filter={{ ...filter }} onFilterChanged={(newFilter: EnhetSearchFilter) => handleFilterChanged(newFilter)} />
                    )}
                </Col>

                <Col>
                    <Row style={{ paddingBottom: '1em' }}>
                        <Col lg={7}>
                            <Form>
                                <Form.Group style={{ paddingBottom: '1em', width: '100%' }}>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            onChange={e => setCriteria(e.target.value)}
                                            value={criteria}
                                            size="lg"
                                            ref={searchTextboxRef}
                                            placeholder="Søk etter enhet..."
                                        />
                                        <InputGroup.Text
                                            style={{ cursor: 'pointer' }}
                                            id="basic-addon2"
                                            onClick={() => setCriteria('')}
                                        >
                                            {criteria.length > 0 ?
                                                <IcebergIcon icon="close" bold /> :
                                                <IcebergIcon icon="search" bold />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <Button
                                        onClick={() => setShowExportModal(true)}
                                        style={{ height: '46px' }}
                                        variant="outline-primary"
                                    >
                                        Eksporter
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        onClick={() => setShowBulkModal(true)}
                                        style={{ minHeight: '46px', minWidth: '150px' }}
                                        variant="outline-primary"
                                    >
                                        Bulk endringer
                                    </Button>
                                </Col>
                                <Col>
                                    <ButtonGroup
                                        style={{ float: 'right', height: '45px' }}
                                        aria-label="Visningsmodus resultater"
                                    >
                                        <Button
                                            title="Vis resultat i tabell"
                                            variant={visningsModus === 'tabell' ? 'outline-primary' : 'outline-primary'}
                                            onClick={() => setVisningsModus('tabell')}
                                        >
                                            <IcebergIcon icon="table" bold />
                                        </Button>
                                        <Button
                                            title="Vis resultat i liste"
                                            variant={visningsModus === 'liste' ? 'outline-primary' : 'outline-primary'}
                                            onClick={() => setVisningsModus('liste')}
                                        >
                                            <IcebergIcon icon="list" bold />
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className={Style.smallScreenSizeFilter}>
                        <Button size="sm" style={{ width: '100%' }} onClick={openFilterModalClicked}>
                            Legg til filter &nbsp;&nbsp;
                            <IcebergIcon icon="filter" size="sm" />
                        </Button>
                    </div>
                    {/* Show spinner on initial load, when collecting 170k enheter.. */}
                    {isSearching && (enheter.length <= 0) && (
                        <Row>
                            <Col className={Style.initialLoadSpinner}>
                                <HollowDotsSpinner size={40} color="#003b6e" style={{ margin: '0' }} />
                                <span style={{ fontSize: '16px', paddingTop: '1em', color: 'grey' }}>Henter enheter...</span>
                            </Col>
                        </Row>
                    )}
                    {!isSearching && (enheter.length <= 0) && (
                        <div>
                            <h3>Ingen treff</h3>
                        </div>
                    )}
                    {(searchResponseCount > 0) && (enheter.length > 0) && (
                        <>
                            <Row>
                                <Col>
                                    <span style={{ padding: '1rem 0' }}>
                                        <span>{enheter.length > 0 && searchResponseCount > 0 ?
                                            `Viser ${enheter.length} av totalt ${searchResponseCount} treff` :
                                            'Ingen treff'}
                                        </span>
                                        <span>. {selectedLopenr.length} enheter valgt.</span>
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={Style.infiniteScroll}>
                                    <InfiniteScroll
                                        pageStart={1}
                                        loadMore={handleLoadMore}
                                        hasMore={enheter.length < searchResponseCount}
                                        threshold={50}
                                        initialLoad={false}
                                        loader={
                                            <div className={Style.loadingInfiniteScroll} key={0}>
                                                <HollowDotsSpinner color="#003b6e" />
                                                <span style={{ fontSize: '16px', paddingTop: '1em', color: 'grey' }}>Henter flere enheter...</span>
                                            </div>
                                        }
                                    >
                                        {visningsModus === 'tabell' && (
                                            <Table responsive hover size="sm">
                                                <thead>
                                                    <tr>
                                                        <th><Form.Check checked={checkAll} onChange={() => handleCheckAll(!checkAll)} /></th>
                                                        <th />
                                                        <th onClick={() => handleColSortClicked('Enhetnavn')} scope="col">
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Enhetnavn</span>
                                                                <SortingIcon sortDirection={sortCol !== 'Enhetnavn' || colClicks === 3 ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th
                                                            onClick={() => handleColSortClicked('HovedBesoksgateadresse')}
                                                            scope="col"
                                                            className="d-none d-lg-table-cell"
                                                        >
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Gateadresse</span>
                                                                <SortingIcon sortDirection={sortCol !== 'HovedBesoksgateadresse' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th
                                                            onClick={() => handleColSortClicked('BesokPostNr')}
                                                            scope="col"
                                                            className="d-none d-lg-table-cell"
                                                        >
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Poststed</span>
                                                                <SortingIcon sortDirection={sortCol !== 'BesokPostNr' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th
                                                            onClick={() => handleColSortClicked('HovedBransjeDescription')}
                                                            scope="col"
                                                            className="d-none d-lg-table-cell"
                                                        >
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Bransje</span>
                                                                <SortingIcon sortDirection={sortCol !== 'HovedBransjeDescription' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th
                                                            onClick={() => handleColSortClicked('Organisasjonsnr')}
                                                            scope="col"
                                                            className="d-none d-sm-table-cell"
                                                        >
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Orgnr</span>
                                                                <SortingIcon sortDirection={sortCol !== 'Organisasjonsnr' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th
                                                            onClick={() => handleColSortClicked('JuridiskNavn')}
                                                            scope="col"
                                                            className="d-none d-md-table-cell"
                                                        >
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Juridisk navn</span>
                                                                <SortingIcon sortDirection={sortCol !== 'JuridiskNavn' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th
                                                            onClick={() => handleColSortClicked('Gln')}
                                                            scope="col"
                                                            className="d-none d-md-table-cell"
                                                        >
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>GLN</span>
                                                                <SortingIcon sortDirection={sortCol !== 'Gln' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th
                                                            onClick={() => handleColSortClicked('Telefon')}
                                                            scope="col"
                                                            className="d-none d-lg-table-cell"
                                                        >
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Tlf</span>
                                                                <SortingIcon sortDirection={sortCol !== 'Telefon' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th onClick={() => handleColSortClicked('PagingKey')} scope="col">
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Løpenummer</span>
                                                                <SortingIcon sortDirection={sortCol !== 'PagingKey' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {enheter.map(e => (
                                                        <tr
                                                            key={e.enhetID}
                                                            style={{ cursor: 'pointer' }}
                                                            className={getRowColor(e)}
                                                        >
                                                            <td>
                                                                <Form.Check checked={isLopenrSelected(e.lopenr)} onChange={() => handleLopenrSelected(e.lopenr)} />
                                                            </td>
                                                            <td>
                                                                <EnhetStatusIcon enhet={e} />
                                                            </td>
                                                            <td onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                <Highlighter
                                                                    className={Style.highlighterWrapper}
                                                                    searchWords={[filter.criteria]}
                                                                    autoEscape={true}
                                                                    textToHighlight={e.enhetnavn}
                                                                />
                                                            </td>
                                                            <td className="d-none d-lg-table-cell" onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                {e.besoksadresser && e.besoksadresser
                                                                    .filter(x => x.erHovedAdresse)
                                                                    .map(a => a.gateadresse)}
                                                            </td>
                                                            <td className="d-none d-lg-table-cell" onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                {e.besoksadresser && e.besoksadresser
                                                                    .filter(x => x.erHovedAdresse)
                                                                    .map(a => a.postnummer + ' ' + a.poststed)}
                                                            </td>
                                                            <td className="d-none d-lg-table-cell" onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                <Highlighter
                                                                    className={Style.highlighterWrapper}
                                                                    searchWords={[filter.criteria]}
                                                                    autoEscape={true}
                                                                    textToHighlight={e.latestHovedBransjeDescription} />
                                                            </td>
                                                            <td className="d-none d-sm-table-cell" onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                {e.organisasjonsnr}
                                                            </td>
                                                            <td className="d-none d-md-table-cell" onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                <Highlighter
                                                                    className={Style.highlighterWrapper}
                                                                    searchWords={[filter.criteria]}
                                                                    autoEscape={true}
                                                                    textToHighlight={e.juridiskNavn} />
                                                            </td>
                                                            <td className="d-none d-md-table-cell" onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                {e.gln}
                                                            </td>
                                                            <td className="d-none d-lg-table-cell" onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                {e.telefon}
                                                            </td>
                                                            <td onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                {e.lopenr}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        )}
                                        {visningsModus === 'liste' && (
                                            <div>
                                                {enheter.map(enhet => (
                                                    <EnhetSearchPreview key={enhet.enhetID} enhet={enhet} />
                                                ))}
                                            </div>
                                        )}
                                    </InfiniteScroll>
                                </Col>
                            </Row>
                        </>
                    )}
                </Col>
            </Row>
        </PageWrapper>
    );
};

export default EnhetSearch;