/*IMPORTS */

/*React & React module dependencies */

/*Custom containers */
import MainMenu from '../../components/MainMenu';
import HomePage from '../HomePage';
import QA from '../QA';
import QAFilterList from '../QA/components/QAFilterList';
import Mottakere from '../Mottakere';
import AbonnementPage from '../Abonnement';
import Distribusjoner from '../Distribusjoner';
import Importer from '../Importer';
import BulkJobber from '../BulkJobber';

/*Custom contexts */
import ToastProvider from '../../utils/contexts/ToastProvider';
import Bransjer from 'pages/Bransjer';
import { Route, Routes } from 'react-router-dom';
import EnhetSearch from 'pages/EnhetSearch';
import Enhet from 'pages/Enhet';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import Kjeder from 'pages/Kjeder';
import Enhetsgruppering from 'pages/Enhetsgruppering';
import Grossister from 'pages/Grossister';
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import EnsureAuthToken from 'utils/contexts/EnsureAuthToken';
import Test from 'pages/Test';

/*IMPORTS END */

const App = () => {

  // redirect to login if not authenticated
  useMsalAuthentication(InteractionType.Redirect);

  /*Content */
  return (
    <EnsureAuthToken>
      <ToastProvider>
        <MainMenu />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/enheter" element={<EnhetSearch />} />
          <Route path="/enheter/:id" element={<Enhet />} />
          <Route path='/qa' element={<QA />} />
          <Route path='/qa/filter' element={<QAFilterList />} />
          <Route path='/bransjer' element={<Bransjer />} />
          <Route path='/mottakere' element={<Mottakere />} />
          <Route path="/kjeder" element={<Kjeder />} />
          <Route path="/grossister" element={<Grossister />} />
          <Route path="/enhetsgruppering" element={<Enhetsgruppering />} />
          <Route path="/abonnement" element={<AbonnementPage />} />
          <Route path='/distribusjoner' element={<Distribusjoner />} />
          <Route path='/importer' element={<Importer />} />
          <Route path="/bulkjobber" element={<BulkJobber />} />
          <Route path='/test' element={<Test />} />
        </Routes>
      </ToastProvider>
    </EnsureAuthToken>
  );
};

export default App;
