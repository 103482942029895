/*IMPORTS */

/*React & React module dependencies */
import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';

/*Third-party module dependencies */
import format from 'date-fns/format';

/*Components */
import JobbDetailsModal from './components/JobbDetailsModal';

/*Shared components */
import PageWrapper from 'components/PageWrapper';

/*Service */
import EnheterBulkApi from 'services/KjederegisteretAdminApi/EnheterBulkApi';
import { BulkJobb, JobbDetails } from 'services/KjederegisteretAdminApi/EnheterBulkApi/types';

/*IMPORTS END */

const BulkJobber: React.FC = () => {

  /*useState handles */
  const [bulkJobs, setBulkJobs] = useState<BulkJobb[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState<BulkJobb>();
  const [jobDetails, setJobDetails] = useState<JobbDetails>();


  /*Functions */
  const loadBulkJobs = useCallback(async () => {
    const response = await EnheterBulkApi.getLastJobs();
    setBulkJobs(response);
  }, []);

  const handleShowDetails = async (job: BulkJobb) => {
    setSelectedJob(job);
    const response = await EnheterBulkApi.getById(job.id);
    setJobDetails(response);
    setShowModal(true);
  };


  /*useEffect */
  useEffect(() => {
    loadBulkJobs();
  }, [loadBulkJobs]);


  /*Content */
  return (
    <PageWrapper header="Kjørte bulkjobber">
      <Row>
        <Col>
          <Table striped hover>
            <thead>
              <tr>
                <th>Start tidspunkt</th>
                <th>Varighet</th>
                <th>Jobb</th>
                <th>Hvem</th>
                <th>Antall enheter endret</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {bulkJobs.map((i) => (
                <tr key={i.id}>
                  <td>{format(new Date(i.startTidspunkt), 'dd.MM.yyyy HH.mm.ss')}</td>
                  <td>{`${(i.sluttTidspunkt.getTime() - i.startTidspunkt.getTime()) / 1000
                    } sek`}</td>
                  <td>{i.jobbNavn}</td>
                  <td>{i.startetAv}</td>
                  <td>{i.antallPåvirkedeEnheter}</td>
                  <td style={{ textAlign: 'center' }}>
                    <Button variant='outline-primary' size="sm" onClick={() => handleShowDetails(i)}>
                      Se detaljer
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <JobbDetailsModal
        show={showModal}
        jobbNavn={selectedJob?.jobbNavn}
        jobDetails={jobDetails}
        onClose={() => setShowModal(false)}
      />
    </PageWrapper>
  );
};

export default BulkJobber;
