import React from "react"
import Style from "./index.module.css";

const SpinnerLarge = () => {
  return (
    <div className={Style.Wrapper}>
      <svg className={Style.Spinner} viewBox="0 0 50 50">
        <circle className={Style.path} cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
      </svg>
    </div>
  );
}

export default SpinnerLarge;
