import React, { useState, useEffect, useRef } from 'react';
import { IAdresse } from 'components/AddressTypeahead/types';
import { EnhetViewModel } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { KontonrOgFakturaAdresseSjekkResponse } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import styles from './index.module.css';
import { Button, Spinner } from 'react-bootstrap';
import AdresseInput from './AdresseInput';
import useToaster from 'utils/hooks/useToaster';
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import { UpdateAddressesCommand } from 'services/KjederegisteretAdminApi/EnhetApi/Commands';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import { Coordinates } from 'models/Coordinates';

interface Props {
  enhet: EnhetViewModel;
  kontonrOgFakturaAdresseSjekkResponse: KontonrOgFakturaAdresseSjekkResponse;
  onEnhetUpdated: () => void;
}

const AdresserV2: React.FC<Props> = props => {

  const toaster = useToaster();
  const isPublishedOverlayRef = useRef(null);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  const [besoksAdresse, setBesokssadresse] = useState<IAdresse>(props.enhet?.besoksadresse);
  const [besokEkstraInfo, setBesokEkstraInfo] = useState<string>(props.enhet?.besoksadresse?.ekstraInfo);
  const [besokError, setBesokError] = useState<boolean>(false);

  const [leveringsAdresse, setLeveringsadresse] = useState<IAdresse>(props.enhet?.leveringsadresse);
  const [leveringsEkstraInfo, setLeveringsEkstraInfo] = useState<string>(props.enhet?.leveringsadresse?.ekstraInfo);
  const [leveringError, setLeveringError] = useState<boolean>(false);

  const [postAdresse, setPostadresse] = useState<IAdresse>(props.enhet?.postadresse);
  const [postEkstraInfo, setPostEkstraInfo] = useState<string>(props.enhet?.postadresse?.ekstraInfo);
  const [postError, setPostError] = useState<boolean>(false);

  const [fakturaAdresse, setFakturaadresse] = useState<IAdresse>(props.enhet?.fakturaAdresse);
  const [fakturaEkstraInfo, setFakturaEkstraInfo] = useState<string>(props.enhet?.fakturaAdresse?.ekstraInfo);
  const [fakturaError, setFakturaError] = useState<boolean>(false);

  const handleSaveClick = async () => {
    setIsSaveLoading(true);
    try {

      const command: UpdateAddressesCommand = {
        enhetId: props.enhet.id,
        besoksadresse: besoksAdresse ? {
          gateadresse: besoksAdresse?.gateadresse,
          postnr: besoksAdresse?.postnr,
          poststed: besoksAdresse?.poststed,
          landkode: besoksAdresse?.landkode,
          koordinater: besoksAdresse?.latitude && besoksAdresse?.longitude ? new Coordinates(besoksAdresse?.latitude, besoksAdresse?.longitude) : null,
          ekstraInfo: besokEkstraInfo,
          erPostboksAdresse: false,
        } : null,
        leveringsadresse: leveringsAdresse ? {
          gateadresse: leveringsAdresse?.gateadresse,
          postnr: leveringsAdresse?.postnr,
          poststed: leveringsAdresse?.poststed,
          landkode: leveringsAdresse?.landkode,
          koordinater: leveringsAdresse?.latitude && leveringsAdresse?.longitude ? new Coordinates(leveringsAdresse?.latitude, leveringsAdresse?.longitude) : null,
          ekstraInfo: leveringsEkstraInfo,
          erPostboksAdresse: false,
        } : null,
        postadresse: postAdresse ? {
          gateadresse: postAdresse?.gateadresse,
          postnr: postAdresse?.postnr,
          poststed: postAdresse?.poststed,
          landkode: postAdresse?.landkode,
          koordinater: postAdresse?.latitude && postAdresse?.longitude ? new Coordinates(postAdresse?.latitude, postAdresse?.longitude) : null,
          ekstraInfo: postEkstraInfo,
          erPostboksAdresse: false,
        } : null,
        fakturaadresse: fakturaAdresse ? {
          gateadresse: fakturaAdresse?.gateadresse,
          postnr: fakturaAdresse?.postnr,
          poststed: fakturaAdresse?.poststed,
          landkode: fakturaAdresse?.landkode,
          koordinater: fakturaAdresse?.latitude && fakturaAdresse?.longitude ? new Coordinates(fakturaAdresse?.latitude, fakturaAdresse?.longitude) : null,
          ekstraInfo: fakturaEkstraInfo,
          erPostboksAdresse: false,
        } : null,
      };

      await EnhetApi.updateAddresses(props.enhet.id, command);
      toaster.success('Suksess!', 'Adresse er oppdatert.');

      props.onEnhetUpdated();
    } catch (err) {
      toaster.error(
        'Feil! Kunne ikke oppdatere adresse.',
        HttpErrorHelper.handleErrorMessage(err),
      );
    }
    setIsSaveLoading(false);
  };

  useEffect(() => {

    if (props.enhet.erPublisert && !besoksAdresse) {
      setIsSaveDisabled(true);
    } else if (besokError || postError || fakturaError || leveringError) {
      setIsSaveDisabled(true);
    } else {
      setIsSaveDisabled(false);
    }
  }, [besoksAdresse, leveringsAdresse, postAdresse, fakturaAdresse, besokError, postError, fakturaError, leveringError]);

  useEffect(() => {
    if (props.enhet?.besoksadresse?.formattedForOneLine !== besoksAdresse?.formattedForOneLine) {
      setBesokssadresse(props.enhet?.besoksadresse);
    }
    if (props.enhet?.besoksadresse?.ekstraInfo !== besokEkstraInfo) {
      setBesokEkstraInfo(props.enhet?.besoksadresse?.ekstraInfo);
    }
    if (props.enhet?.leveringsadresse?.formattedForOneLine !== leveringsAdresse?.formattedForOneLine) {
      setLeveringsadresse(props.enhet?.leveringsadresse);
    }
    if (props.enhet?.leveringsadresse?.ekstraInfo !== leveringsEkstraInfo) {
      setLeveringsEkstraInfo(props.enhet?.leveringsadresse?.ekstraInfo);
    }
    if (props.enhet?.postadresse?.formattedForOneLine !== postAdresse?.formattedForOneLine) {
      setPostadresse(props.enhet?.postadresse);
    }
    if (props.enhet?.postadresse?.ekstraInfo !== postEkstraInfo) {
      setPostEkstraInfo(props.enhet?.postadresse?.ekstraInfo);
    }
    if (props.enhet?.fakturaAdresse?.formattedForOneLine !== fakturaAdresse?.formattedForOneLine) {
      setFakturaadresse(props.enhet?.fakturaAdresse);
    }
    if (props.enhet?.fakturaAdresse?.ekstraInfo !== fakturaEkstraInfo) {
      setFakturaEkstraInfo(props.enhet?.fakturaAdresse?.ekstraInfo);
    }
  }, [props.enhet])

  return (
    <div className={styles.container}>

      <AdresseInput
        title='Besøksadresse'
        adresse={besoksAdresse}
        setAdresse={setBesokssadresse}
        ekstraInfo={besokEkstraInfo}
        setEkstraInfo={setBesokEkstraInfo}
        copyAddresses={[{ adresse: leveringsAdresse, title: 'Bruk leveringsadresse' }, { adresse: postAdresse, title: 'Bruk postadresse' }, { adresse: fakturaAdresse, title: 'Bruk fakturaadresse' }]}
        setError={setBesokError}
        requireCoordinates={true}
      />

      <AdresseInput
        title="Leveringsadresse"
        adresse={leveringsAdresse}
        setAdresse={setLeveringsadresse}
        ekstraInfo={leveringsEkstraInfo}
        setEkstraInfo={setLeveringsEkstraInfo}
        copyAddresses={[{ adresse: besoksAdresse, title: 'Bruk besøksadresse' }, { adresse: postAdresse, title: 'Bruk postadresse' }, { adresse: fakturaAdresse, title: 'Bruk fakturaadresse' }]}
        setError={setLeveringError}
        requireCoordinates={true}
      />

      <AdresseInput
        title="Postadresse"
        adresse={postAdresse}
        setAdresse={setPostadresse}
        ekstraInfo={postEkstraInfo}
        setEkstraInfo={setPostEkstraInfo}
        copyAddresses={[{ adresse: besoksAdresse, title: 'Bruk besøksadresse' }, { adresse: leveringsAdresse, title: 'Bruk leveringsadresse' }, { adresse: fakturaAdresse, title: 'Bruk fakturaadresse' }]}
        setError={setPostError}
      />

      <AdresseInput
        title="Fakturaadresse"
        adresse={fakturaAdresse}
        setAdresse={setFakturaadresse}
        ekstraInfo={fakturaEkstraInfo}
        setEkstraInfo={setFakturaEkstraInfo}
        copyAddresses={[{ adresse: besoksAdresse, title: 'Bruk besøksadresse' }, { adresse: leveringsAdresse, title: 'Bruk leveringsadresse' }, { adresse: postAdresse, title: 'Bruk postadresse' }]}
        setError={setFakturaError}
        kontonrOgFakturaAdresseSjekkResponse={props.kontonrOgFakturaAdresseSjekkResponse}
      />

      <div className={styles.actionContainer}>
        <Button
          className={styles.saveBtn}
          ref={isPublishedOverlayRef}
          disabled={isSaveDisabled}
          onClick={handleSaveClick}
        >
          <span>Lagre</span>
          {isSaveLoading && (
            <Spinner style={{ marginLeft: '.3em' }} as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          )}
        </Button>

      </div>
    </div>
  )
}
export default AdresserV2;
