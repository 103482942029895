import React from 'react';
import { ListGroup, Form, Button } from 'react-bootstrap';
import { TilknyttetEnhet } from 'services/KjederegisteretAdminApi/GrossisterApi/types';
import Pager from 'components/Pager';
import Style from './index.module.css';
import SpinnerSmall from 'components/SpinnerSmall';

interface Props {
  enheter: TilknyttetEnhet[];
  isFetchingEnheter: boolean;
  enheterTilknyttetCount: number;
  filteredEnheterTilknyttetCount: number;
  setSearchQuery: (value: string) => void;
  searchQuery: string;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const TilknyttedeEnheterList: React.FC<Props> = props => {
  const onFilterSearch = (value: string) => {
    props.setSearchQuery(value);
  };

  const goToEnhetClicked = (id: number) => {
    const url = `/enheter/${id}`;
    window.open(url, '_blank');
  };

  return (
    <div className={Style.container}>
      <div className={Style.contentContainer}>
        <h5>
          Grossist har{' '}
          <span style={{ color: 'red' }}>
            {props.filteredEnheterTilknyttetCount > 0
              ? `${props.filteredEnheterTilknyttetCount}/${props.enheterTilknyttetCount}`
              : `${props.enheterTilknyttetCount}`}
          </span>{' '}
          tilknyttede enheter
        </h5>
        <p>Enheter må få kobling til en ny grossist, før du kan legge ned grossisten.</p>
        <Form>
          <Form.Group>
            <Form.Control
              ref={(input: { focus: () => any }) => input && input.focus()}
              onChange={e => onFilterSearch(e.target.value)}
              placeholder="Søk.."
              value={props.searchQuery}
            />
          </Form.Group>
        </Form>
        {props.isFetchingEnheter ? (
          <SpinnerSmall />
        ) : (
          <ListGroup>
            {props.enheter.map(enhet => (
              <ListGroup.Item key={enhet.enhetId}>
                <Button variant="link" size="sm" onClick={() => goToEnhetClicked(enhet.enhetId)}>
                  {enhet.enhetNavn}
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>
      <Pager
        activePage={props.currentPage}
        itemsPerPage={6}
        numberOfItems={
          props.filteredEnheterTilknyttetCount > 0
            ? props.filteredEnheterTilknyttetCount
            : props.enheterTilknyttetCount
        }
        onActivePageChanged={page => {
          props.setCurrentPage(page);
        }}
      />
    </div>
  );
};

export default TilknyttedeEnheterList;
