import { Button, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import useForm from 'utils/hooks/useForm';
import EnhetTypeahead from 'components/EnhetTypeahead';
import EnhetsgrupperingApi from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi';
import Spinner from 'react-bootstrap/Spinner';
import useToaster from 'utils/hooks/useToaster';
import EnhetsgrupperingtypePicker from 'pages/Enhetsgruppering/EnhetgrupperingTypePicker';
import GrupperingPicker from 'components/GrupperingPicker';
import { IMappedGrupperingNode } from 'components/GrupperingPicker/types';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import Style from './index.module.css';
import { Enhetgruppering, UpdateEnhetgrupperingCommand } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';
import { PickedEnhet } from 'components/EnhetTypeahead/types';

interface Props {
  gruppering: Enhetgruppering;
  reload: () => void;
}

const InfoTab: React.FC<Props> = props => {
  const toaster = useToaster();

  const [isUpdating, setIsUpdating] = useState(false);

  const validate = (values: Enhetgruppering) => {
    const errors: any = {};
    if (!values.enhetgrupperingtypekode)
      errors.enhetgrupperingtypekode = 'Enhetsgrupperingtype mangler!';
    if (!values.enhetID) errors.enhetID = 'Hovedkontor er ikke satt!';
    if (!values.enhetsgrupperingnavn) errors.enhetsgrupperingnavn = 'Enhetsgrupperingnavn mangler!';
    return errors;
  };

  const onSubmit = async (values: Enhetgruppering) => {
    setIsUpdating(true);
    try {
      const payload: UpdateEnhetgrupperingCommand = {
        enhetgrupperingId: values.enhetgrupperingID,
        enhetgrupperingtypekode: values.enhetgrupperingtypekode,
        enhetgrupperingIdOver: values.enhetgrupperingIDOver,
        enhetId: values.enhetID,
        enhetsgrupperingnavn: values.enhetsgrupperingnavn,
        kjedenr: values.kjedenr,
      };
      await EnhetsgrupperingApi.updateEnhetsgrupperingDetaljer(values.enhetgrupperingID, payload);
      toaster.success('Suksess!', 'Enhetsgrupperingen er oppdatert!');
      props.reload();
    } catch (err) {
      toaster.error('Feil.', HttpErrorHelper.handleErrorMessage(err));
    }
    setIsUpdating(false);
  };

  const {
    touched,
    errors,
    values,
    handleChange,
    handleSubmit,
    submitDisabled,
    updateValues,
  } = useForm(props.gruppering, validate, onSubmit);

  const onChangeEnhet = (enhet: PickedEnhet) => {
    const copy = { ...values };
    copy.enhetID = enhet.id;
    copy.enhetNavn = enhet.name;
    updateValues(copy);
  };

  const onChangeParentGruppering = (parentGruppering: IMappedGrupperingNode) => {
    const copy = { ...values };
    copy.enhetgrupperingIDOver = parentGruppering?.id || null;
    copy.enhetsgrupperingOverNavn = parentGruppering?.name || '';
    updateValues(copy);
  };

  const onGrupperingSelectionChanged = (gruppering: IMappedGrupperingNode[]) => {
    if (gruppering.length > 0) {
      onChangeParentGruppering(gruppering[0]);
    } else {
      onChangeParentGruppering(undefined);
    }
  };

  const findPreSelectedOverliggendeNodes = useCallback((type: string) => {
    if (type === 'gruppering') {
      return [values.enhetgrupperingIDOver];
    }
  }, [values.enhetgrupperingIDOver]);

  useEffect(() => {

    if (props.gruppering) {
      updateValues({ ...props.gruppering });
    }
  }, [props.gruppering]);

  return (
    <Form style={{ paddingTop: '1em' }} onSubmit={handleSubmit}>
      <FormGroup controlId="formGrupperingHovedkontor">
        <Form.Label>Velg nytt hovedkontor:</Form.Label>
        <EnhetTypeahead
          showFilters
          isInvalid={touched.enhetID && errors.enhetID}
          onChange={e => onChangeEnhet(e)}
        />
        <Form.Control.Feedback type="invalid">{errors.enhetID}</Form.Control.Feedback>
        <Form.Text>
          {values.enhetID && values.enhetNavn && (
            <div>
              <span>Enhet: </span>
              <Button
                className={Style.linkToEnhetBtn}
                size="sm"
                variant="link"
                onClick={() => window.open(`enheter/${values.enhetID}`, '_blank')}
              >
                {values.enhetNavn}
              </Button>
            </div>
          )}
        </Form.Text>
      </FormGroup>
      <hr />

      <FormGroup controlId="formGrupperingnavn">
        <Form.Label>Enhetsgrupperingsnavn:</Form.Label>
        <Form.Control
          type="text"
          name="enhetsgrupperingsnavn"
          value={values.enhetsgrupperingnavn}
          onChange={e => handleChange('enhetsgrupperingnavn', e.target.value)}
          isInvalid={touched.enhetsgrupperingnavn && errors.enhetsgrupperingnavn}
          isValid={touched.enhetsgrupperingnavn && !errors.enhetsgrupperingnavn}
        />
        <Form.Control.Feedback type="invalid">{errors.enhetsgrupperingnavn}</Form.Control.Feedback>
      </FormGroup>

      <FormGroup controlId="formEnhetgrupperingForeldre">
        <Form.Label>Overliggende:</Form.Label>
        <GrupperingPicker
          onSelectionChanged={onGrupperingSelectionChanged}
          findPreselectedNodesByID={findPreSelectedOverliggendeNodes}
        />
      </FormGroup>

      <hr />

      <FormGroup controlId="formGrupperingtype">
        <Form.Label>Enhetsgrupperingstype:</Form.Label>

        <EnhetsgrupperingtypePicker
          selected={values.enhetgrupperingtypekode}
          onChange={value => handleChange('enhetgrupperingtypekode', value)}
        />
      </FormGroup>

      <FormGroup controlId="formGrupperingnr">
        <Form.Label>Enhetsgrupperingnr:</Form.Label>
        <Form.Control
          type="text"
          name="enhetsgrupperingnr"
          value={values.enhetgrupperingnr}
          readOnly
        />
      </FormGroup>

      <FormGroup controlId="formGrupperingKjedenr">
        <Form.Label>Kjedenr (valgfritt):</Form.Label>
        <Form.Control
          type="text"
          name="enhetsgrupperingkjedenr"
          value={values.kjedenr}
          onChange={e => handleChange('kjedenr', e.target.value)}
        />
        <Form.Text className="text-muted">
          Enhetsgrupperingen blir synlig som en kjede I gammel fileksport hvis den får et
          kjedenummer.
        </Form.Text>
      </FormGroup>
      <Row>
        <Col>
          <Button type="submit" disabled={submitDisabled || isUpdating}>
            Lagre
            {
              isUpdating &&
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            }
          </Button>{' '}
        </Col>
      </Row>
    </Form>
  );
};

export default InfoTab;
