import React, { useEffect, useState } from 'react';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import DropdownTreeSelect, { Mode, TreeNode, TextProps } from 'react-dropdown-tree-select';
import BrukereApi, { IBruker } from 'services/KjederegisteretAdminApi/BrukereApi';
import { Spinner } from 'react-bootstrap';

interface Props {
  treeSelectMode?: Mode;
  customTexts?: TextProps;
  disabled?: boolean;
  onSelectionChanged: (brukere: IMappedBruker[]) => void;
  findPreselectedInFilter?: (picker: string) => string[];
}
export interface IMappedBruker {
  id: number;
  brukerId: string;
  label: string;
  checked?: boolean;
}

const BrukerPicker: React.FC<Props> = props => {
  const [data, setData] = useState<IMappedBruker[]>();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const isMountedRef = useIsMountedRef();

  const defMode = 'radioSelect';
  const defTexts = {
    placeholder: 'Velg...',
    noMatches: 'Ingen treff',
  };

  let pickedusers: string[] = [];
  if (props.findPreselectedInFilter) {
    pickedusers = props.findPreselectedInFilter('bruker');
  }

  const mapBrukerNode = (node: TreeNode) => {
    const item: IMappedBruker = {
      id: node.id,
      label: node.label,
      brukerId: node.brukerId,
      checked: pickedusers && pickedusers.includes(node.brukerId) ? true : false,
    };
    return item;
  };

  const mapTreeItem = (node: IBruker) => {
    const isChecked = (pickedusers && pickedusers.includes(node.brukerID)) ? true : false;
    const item: IMappedBruker = {
      id: parseInt(node.brukerID),
      label: node.fornavn + ' ' + (node.etternavn ? node.etternavn : ''),
      brukerId: node.brukerID.toString(),
      checked: isChecked,
    };
    return item;
  };

  const loadBrukere = async () => {
    setIsFetching(true);
    let res = await BrukereApi.getBrukere();
    let extraRows: string[] = ['Meg', 'Noen'];
    if (extraRows?.length > 0) {
      extraRows.map(x => res.unshift({ id: Number(x), brukerID: x, fornavn: x }));
    }
    if (res.length > 0 && isMountedRef.current) {
      const resData: IMappedBruker[] = res.map((x: IBruker) => mapTreeItem(x))
      setData(resData);
    }
    setIsFetching(false);
  };


  const handleChange = (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
    if (Array.isArray(selectedNodes)) {
      props.onSelectionChanged(selectedNodes.map(x => mapBrukerNode(x)));
    }
  };

  useEffect(() => {
    if (!data) {
      loadBrukere();
    }
  }, [data]);

  return (
    <>
      <DropdownTreeSelect
        className="reactDropdownMultiPicker"
        mode={props.treeSelectMode || defMode}
        texts={props.customTexts || defTexts}
        data={data || []}
        keepTreeOnSearch
        keepChildrenOnSearch
        showPartiallySelected
        onChange={(currentNode: TreeNode, selectedNodes: TreeNode[]) => handleChange(currentNode, selectedNodes)}
        disabled={props.disabled || false}

      />
      {isFetching && (
        <div>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          <span> Henter data... </span>
        </div>
      )}
    </>
  );
};

export default React.memo(BrukerPicker);

