import axios from 'axios';

import { AddressSearchResult, AddressViewModel } from './types';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/adresse';

export default {
  postUpdatePosition: async data =>
    axios.put(`${baseUrl}/${data.id}/position`, data).then(response => response.data),

  updateAddress: async data =>
    axios.put(`${baseUrl}/${data.id}`, data).then(response => response.data),

  createAddress: async data => axios.post(`${baseUrl}/`, data).then(response => response.data),

  findMatching: async data =>
    axios
      .get(
        `${baseUrl}/lookup?gateadresse=${data.gateadresse}&postnr=${data.postnr}&poststed=${data.poststed
        }&checkExternalSource=${data.checkExternalSource}`,
        data,
      )
      .then(response => response.data),

  search: async (query: string): Promise<AddressSearchResult[]> =>
    axios.get(`${baseUrl}/search?q=${query}`).then(response => response.data),

  getById: async (id: number): Promise<AddressViewModel | undefined> =>
    axios.get(`${baseUrl}/${id}`).then(response => response.data),
};
