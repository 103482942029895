import { useState, useEffect, useCallback } from 'react';
import { Form, Button } from 'react-bootstrap';
import AbonnementTypeahead, { Abo } from './components/AbonnementTypeahead';
import TsKundeTypeahead from 'components/TsKundeTypeahead';
import MottakerApi from 'services/KjederegisteretAdminApi/MottakerApi';
import { Mottaker, AddOrUpdateMottakerCommand } from 'services/KjederegisteretAdminApi/MottakerApi/types';
import useForm from "utils/hooks/useForm";
import useToaster from "utils/hooks/useToaster";
import { TsKunde } from 'services/KjederegisteretAdminApi/TsKunderApi/types';
import TsKunderApi from 'services/KjederegisteretAdminApi/TsKunderApi';

interface Props {
  mottaker?: Mottaker;
}

const MottakerEdit = (props: Props) => {
  const toaster = useToaster();

  const [selectAbonnement, setSelectedAbonnement] = useState<Abo[]>(props.mottaker?.abonnementList || []);
  const [selectedTsKunde, setSelectedTsKunde] = useState<TsKunde | undefined>();
  const [loadTsKundeError, setLoadTsKundeError] = useState<string | undefined>();

  const initialValues: AddOrUpdateMottakerCommand = props.mottaker ?
    { id: props.mottaker.id, name: props.mottaker.name, tsKundeId: props.mottaker.tsKundeId, abonnementIds: props.mottaker.abonnementList.map(o => o.id), notat: props.mottaker.notat } :
    { name: '', tsKundeId: '', abonnementIds: [], notat: '' };

  const validate = (values: AddOrUpdateMottakerCommand) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'Navn mangler!';
    }
    if (values.notat && values.notat.length > 2000) {
      errors.notat = 'Notat må være mindre enn 2000 tegn!';
    }
    return errors;
  };

  const onSubmit = async (command: AddOrUpdateMottakerCommand) => {
    if (command.id) {
      try {
        await MottakerApi.updateMottaker(command.id, command);
        toaster.success('Suksess!', 'Mottaker er oppdatert.');
      } catch (err) {
        toaster.error('Feil! Noe gikk galt.', `Mottaker er ikke oppdatert. ${err}`);
      }
    } else {
      try {
        await MottakerApi.addMottaker(command);
        toaster.success('Suksess!', 'Mottaker er opprettet.');
      } catch (err) {
        toaster.error('Feil! Noe gikk galt.', `Mottaker er ikke opprettet. ${err}`);
      }
    }
  };

  const { values, errors, handleSubmit, handleChange, touched, submitDisabled, updateValues } = useForm(initialValues, validate, onSubmit);

  const handleAbonnementChanged = (selected: Abo[]) => {
    setSelectedAbonnement(selected);
    updateValues({ ...values, abonnementIds: selected.map(o => o.id) });
  };

  const handleTsKundeSelected = (selectedTsKunde?: TsKunde) => {
    setSelectedTsKunde(selectedTsKunde);
    updateValues({ ...values, tsKundeId: selectedTsKunde?.tsKundeId });
  };


  const loadTsKunde = async (tsKundeId: string) => {
    try {
      const result = await TsKunderApi.getTsKundeById(tsKundeId);
      setSelectedTsKunde({
        tsKundeId: result.tsKundeId,
        tsKundeNavn: result.tsKundeNavn,
      });
    } catch (err) {
      setLoadTsKundeError(`Uthenting av navn på tskunde med id: ${tsKundeId} feilet!`);
    }
  };

  useEffect(() => {
    if (props.mottaker && props.mottaker.tsKundeId) {
      loadTsKunde(props.mottaker.tsKundeId);
    }
  }, [props.mottaker?.tsKundeId]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className='mb-3'>
        <Form.Label>Mottakernavn</Form.Label>
        <Form.Control name="name" type="text" autoComplete="false" value={values.name}
          onChange={e => handleChange('name', e.target.value)}
          isInvalid={touched.name && errors.name}
          isValid={touched.name && !errors.name} />
        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label>TsKunde</Form.Label>
        <TsKundeTypeahead
          onChange={handleTsKundeSelected}
          selected={selectedTsKunde} />
        {loadTsKundeError && <div style={{ color: 'red' }}>{loadTsKundeError}</div>}
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label>Enhetsutvalg</Form.Label>
        <AbonnementTypeahead onChange={handleAbonnementChanged} selected={selectAbonnement} />
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label>Notat</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="notat"
          value={values.notat || ''}
          onChange={e => handleChange('notat', e.target.value)}
          isInvalid={touched.notat && errors.notat}
          isValid={touched.notat && !errors.notat} />
        <Form.Control.Feedback type="invalid">{errors.notat}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Button type="submit" disabled={submitDisabled}>
          Lagre
        </Button>
      </Form.Group>
    </Form>
  );
};

export default MottakerEdit;
