import { useState } from 'react';
import { Form, Button, Modal, InputGroup, Spinner } from 'react-bootstrap';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';
import DatePicker from 'components/DatePicker';
import addMinutes from 'date-fns/addMinutes';
import Style from './index.module.css';
import EnhetsgrupperingApi from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi';
import { MeldUtMedlemskjedeCommand } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';

interface Props {
  enhetsgruppeId: number;
  kjedeEnhetsgrupperingId: number;
  isShowModal: boolean;
  handleCloseModal: () => void;
}

const AvsluttKjedeMedlemskapModal: React.FC<Props> = props => {
  const [selectedUtmeldingsDato, setSelectedUtmeldingsDato] = useState<Date>(addMinutes(new Date(), -new Date().getTimezoneOffset()));
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const toaster = useToaster();

  const handleSubmit = () => {
    const command: MeldUtMedlemskjedeCommand = {
      kjedeEnhetgrupperingID: props.kjedeEnhetsgrupperingId,
      tilOgMedDato: selectedUtmeldingsDato,
    };
    setIsLoadingSubmit(true);
    EnhetsgrupperingApi.meldUtMedlemskjede(props.enhetsgruppeId, command)
      .then(() => {
        toaster.success('Suksess!', 'Kjede ble meldt ut.');
      })
      .catch(err => {
        toaster.error('Feil! Kunne ikke melde ut kjede.', HttpErrorHelper.handleErrorMessage(err));
      })
      .finally(() => {
        props.handleCloseModal();
        setIsLoadingSubmit(false);
      });
  };

  return (
    <Modal show={props.isShowModal} onHide={props.handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Meld ut</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <InputGroup>
            <DatePicker
              selected={selectedUtmeldingsDato}
              onChange={(date: Date) => setSelectedUtmeldingsDato(date)}
              adjustTimeForTimezoneOffset={true}
            />
            <Form.Text>Dagens dato automatisk valgt.</Form.Text>
          </InputGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer className={Style.modalFooter}>
        <div>
          <Button className={Style.closeModalBtn} variant="secondary" onClick={props.handleCloseModal}>
            Lukk
          </Button>
          <Button variant="danger" onClick={handleSubmit} disabled={isLoadingSubmit}>
            {isLoadingSubmit && (
              <span style={{ paddingRight: '.4em' }}>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              </span>
            )}
            <span>{isLoadingSubmit ? '...melder ut' : 'Ja, meld ut'}</span>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AvsluttKjedeMedlemskapModal;
