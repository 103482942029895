/*IMPORTS */

/*React & React module dependencies */
import React, { useState, useEffect, useCallback } from 'react';
import Table from 'react-bootstrap/Table';

/*Js library to sort  */
import { orderBy } from 'lodash';

/*Third-party modules */
import { format } from 'date-fns';

/*Shared components */
import SortingIcon from 'components/SortingIcon';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

/*Services */
import { DistribusjonsHistorikk } from 'services/KjederegisteretAdminApi/DistribusjonsApi/types';

/*Styling */
import Style from './index.module.css';

/*IMPORTS END */

interface Props {
  data: DistribusjonsHistorikk[];
}

const DistribusjonsTable = (props: Props) => {

  /*useState properties */
  const [data, setData] = useState(props.data);
  const [sortCol, setSortCol] = useState('tidspunkt');
  const [reverseSort, setReverseSort] = useState(true);

  /*functions */
  const sortTableColumn = useCallback((col: string, reverse: boolean) => {
    const copy = [...data];
    setData(orderBy(copy, [col], reverse ? ['desc'] : ['asc']));
  }, [data]);

  const handleColSortClicked = (col: React.SetStateAction<string>) => {
    if (col === sortCol) {
      setReverseSort(!reverseSort);
    } else {
      setSortCol(col);
      setReverseSort(false);
    }
  };

  /*useEffect */
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    sortTableColumn(sortCol, reverseSort);
  }, [sortCol, reverseSort, sortTableColumn]);

  /*Content */
  return (
    <>
      <Table className={Style.distribusjonsTable} striped hover>
        <thead>
          <tr>
            <th onClick={() => handleColSortClicked('tidspunkt')}>
              <div className={Style.tidspunktColumn}>
                <span className={Style.sortableColumnHeader}>Tidspunkt</span>
                <SortingIcon sortDirection={sortCol !== 'tidspunkt' ? '' : (reverseSort ? 'desc' : 'asc')} />
              </div>
            </th>
            <th onClick={() => handleColSortClicked('mottakerNavn')}>
              <div className={Style.mottakernavnColumn}>
                <span className={Style.sortableColumnHeader}>Mottaker</span>
                <SortingIcon sortDirection={sortCol !== 'mottakerNavn' ? '' : (reverseSort ? 'desc' : 'asc')} />
              </div>
            </th>
            <th>
              <span className={Style.columnHeader}>Distribusjon</span>
            </th>
            <th>
              <span className={Style.columnHeader}>AntallEnheter</span>
            </th>
          </tr>
        </thead>
        {data && data.length > 0 ? (
          <tbody>
            {data.map(i => (
              <tr key={Math.random()}>
                <td>{format(i.tidspunkt, "dd.MM.yyyy")}</td>
                <td>{i.mottakerNavn}</td>
                <td>{i.distribusjonsNavn}</td>
                <td>{i.antallEnheter}</td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr key={`empty-${Math.random()}`}>
              <td colSpan={4}>
                <div className={Style.noDataInfoContainer}>
                  <h6>Tomt. Klikk for å laste på nytt.</h6>
                  <IcebergIcon icon='redo' />
                </div>
              </td>
            </tr>
          </tbody>
        )}
      </Table>
    </>
  );
};

export default DistribusjonsTable;
