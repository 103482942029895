/*IMPORTS */

/*React & React module dependencies */
import { useEffect, useState } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

/*Tradesolution UI module dependencies */
import {
  IcebergNavbarBrandLogo,
  IcebergProductMenu,
  IcebergAvatar,
} from '@tradesolution/iceberg-ui-react';

/*Service */
import KundeportalenApi from 'services/KundeportalenApi';
import { MenuItem } from 'services/KundeportalenApi/types';

/*Utils */
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import { useMsal } from '@azure/msal-react';
import HealthStatus from 'components/HealthStatus';

/*IMPORTS END */


/*Interface */
interface Props {
}


const MainMenu = (props: Props) => {

  const { instance, accounts } = useMsal();

  const username = accounts[0] && accounts[0].name;

  /*useState handles */
  const [services, setServices] = useState<MenuItem[]>([]);

  const toaster = useToaster();

  const currentSelectedServiceName = 'KR Admin';


  /*Functions */
  const loadServices = (isTileDisplay: boolean) => {
    KundeportalenApi.fetchMenu(isTileDisplay)
      .then((res) => {
        setServices(res);
      })
      .catch((error) => {
        toaster.error('', HttpErrorHelper.handleErrorMessage(error));
      });
  };


  /*useEffect hooks */
  /* useEffect(() => {
    if (!user && props.userContext && props.userContext.user) {
      setUser(props.userContext.user);
    }
  }, [props.userContext, user]); */

  useEffect(() => {
    loadServices(false);
  }, []);


  let canReadAll = true;

  // if (Array.isArray(user?.profile?.Role)) {
  //   canReadAll = user?.profile?.Role?.includes('TradesolutionAnsatt' || 'UberAdmin');
  // } else {
  //   canReadAll =
  //     user?.profile?.Role === 'TradesolutionAnsatt' || user?.profile?.Role === 'UberAdmin';
  // }

  const handleLogout = () => {
    instance.logoutRedirect().catch(e => {
      console.error(e);
    });
  }

  /*Content */
  return (
    <Navbar fixed="top" collapseOnSelect expand="xxl">
      <Container fluid>
        <Navbar.Brand href="/">
          <IcebergNavbarBrandLogo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse>
          <Nav className="me-auto">
            <NavDropdown id="dropdownProductSwitcher" title="KR Admin">
              <IcebergProductMenu />
            </NavDropdown>
            {canReadAll && (
              <>
                <Nav.Link href="/qa">QA</Nav.Link>
                <Nav.Link href="/enheter">Enheter</Nav.Link>
                <Nav.Link href="/kjeder">Kjeder</Nav.Link>
                <Nav.Link href="/enhetsgruppering">Enhetsgrupperinger</Nav.Link>
                <Nav.Link href="/bransjer">Bransjer</Nav.Link>
                <Nav.Link href="/grossister">Grossister</Nav.Link>
                <Nav.Link href="/mottakere">Mottakere</Nav.Link>
                <Nav.Link href="/abonnement">Abonnement</Nav.Link>
                <Nav.Link href='/distribusjoner'>Distribusjoner</Nav.Link>
                <Nav.Link href='/importer'>Importer</Nav.Link>
                <Nav.Link href="/bulkjobber">Bulkjobber</Nav.Link>
              </>
            )}
          </Nav>
          <Nav>
            <span style={{ margin: "0.7rem" }}>
              <HealthStatus />
            </span>
            <NavDropdown
              id="dropdownUserMenu"
              align="end"
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IcebergAvatar bgDark firstName={username} />
                  <span style={{ marginLeft: '0.5em' }}>{username}</span>
                </div>
              }
            >
              <NavDropdown.Item onClick={handleLogout}>
                <span>
                  <strong>Logg ut: </strong>
                </span>
                <span data-private>
                  {username}
                </span>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainMenu;
