import { useEffect, useState, useRef } from 'react';
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import DropdownTreeSelect, { Mode, TreeNode } from 'react-dropdown-tree-select';
import { Spinner } from 'react-bootstrap';
import 'react-dropdown-tree-select/dist/styles.css';
import { GenericViewModel } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { PickedType } from "./types";

interface Props {
  resetIndicator?: string;
  treeSelectMode?: Mode;
  disabled?: boolean;
  selectedNodes?: string[];
  onSelectionChanged: (typer: PickedType[]) => void;
  closeOnSelect?: boolean;
}

const EnhetTypeMultiPicker = (props: Props) => {
  const [data, setData] = useState<PickedType[]>();
  const [isFetchingTyper, setIsFetchingTyper] = useState(false);

  const isMountedRef = useIsMountedRef();

  const pickerRef = useRef<DropdownTreeSelect>(null);

  const defMode = 'radioSelect';

  const preSelectedNodes: string[] = props.selectedNodes ?? [];

  const mapPreSelectedTypeNode = (node: PickedType, preSelected: string[]) => {
    const isChecked = (preSelectedNodes && preSelectedNodes.includes(node.kode)) ? true : false;
    const item: PickedType = {
      id: node.id,
      label: node.label,
      name: node.name,
      kode: node.kode,
      disabled: false,
      checked: isChecked,
    }
    return item;
  }

  const mapTypeNode = (node: TreeNode) => {
    const isChecked = (preSelectedNodes && preSelectedNodes.includes(node.kode)) ? true : false;
    const item: PickedType = {
      id: node.id,
      label: node.label,
      name: node.name,
      kode: node.kode,
      disabled: false,
      checked: isChecked,
    }
    return item;
  }

  const mapTypeTreeItem = (node: GenericViewModel, preSelected?: string[]) => {
    const isChecked = (preSelectedNodes && preSelectedNodes.includes(node.code)) ? true : false;
    const item: PickedType = {
      id: node.id,
      label: node.code,
      name: node.name,
      kode: node.code,
      disabled: false,
      checked: isChecked,
    };
    return item;
  };

  const loadTyper = async () => {
    setIsFetchingTyper(true);
    const res: GenericViewModel[] = await EnhetApi.getEnhettyper();
    if (res && (res.length > 0) && isMountedRef.current) {
      const resData: PickedType[] = res.map(x => mapTypeTreeItem(x));
      setData(resData);
    }
    setIsFetchingTyper(false);
  };

  const handleChange = (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
    if (Array.isArray(selectedNodes)) {
      props.onSelectionChanged(selectedNodes.map(x => mapTypeNode(x)));
      if(isMountedRef.current && props.closeOnSelect && pickerRef.current.state.showDropdown){
        pickerRef.current.searchInput.click();
      }
    }
  };

  useEffect(() => {
    if (isMountedRef.current && !data) {
      loadTyper();
    }
  }, [data]);

  useEffect(() => {
    if (data && preSelectedNodes) {
      const newData: PickedType[] = data.map(x => mapPreSelectedTypeNode(x, preSelectedNodes));
      setData(newData);
    }
  }, [preSelectedNodes]);

  return (
    <>
      <DropdownTreeSelect
        className="reactDropdownMultiPicker"
        mode={props.treeSelectMode || defMode}
        texts={{
          placeholder: 'Velg...',
          noMatches: 'Ingen treff',
        }}
        data={data || []}
        keepTreeOnSearch
        keepChildrenOnSearch
        showPartiallySelected
        onChange={(currentNode: TreeNode, selectedNodes: TreeNode[]) => handleChange(currentNode, selectedNodes)}
        disabled={props.disabled || false}
        ref={pickerRef}
      />
      {isFetchingTyper && (
        <div>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          <span> Henter data... </span>
        </div>
      )}
    </>
  );
};

export default EnhetTypeMultiPicker; 
