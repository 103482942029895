import EnhetTypeahead from "components/EnhetTypeahead";
import { PickedEnhet } from "components/EnhetTypeahead/types";
import { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import GrossisterApi from "services/KjederegisteretAdminApi/GrossisterApi";
import { RekboleGrossistCommand, SelectedGrossist } from "services/KjederegisteretAdminApi/GrossisterApi/types";

interface Props {
    item?: SelectedGrossist;
    show: boolean;
    onClose: () => void;
    onUpdated: () => void;
}

const RekobleGrossist = (props: Props) => {
    const [selectedEnhet, setSelectedEnhet] = useState<PickedEnhet>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onChange = (enhet: PickedEnhet) => {
        if (enhet && enhet.id) {
            setSelectedEnhet(enhet);
        } else {
            setSelectedEnhet(null);
        }
    };

    const handleRekobleClicked = async () => {
        setIsLoading(true);
        const command: RekboleGrossistCommand = {
            movetoEnhetId: selectedEnhet.id,
            grossistEnhetId: props.item.id,
            kundenummer: props.item.kundenummerHosGrossist,
        }
        await GrossisterApi.rekobleGrossist(command);
        setIsLoading(false);
        props.onUpdated();
        props.onClose();
    }

    return (
        <Modal show={props.show} onHide={props.onClose} size="lg" >
            <Modal.Header closeButton>
                <Modal.Title>
                    Rekoble {props.item?.kundenummerHosGrossist} fra {props.item?.name} 
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Velg enhet for rekobling</Form.Label>
                        <EnhetTypeahead onChange={enhet => onChange(enhet)} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" className="col" onClick={props.onClose}>
                    Lukk
                </Button>
                <Button variant="primary" className="col" onClick={handleRekobleClicked} disabled={!selectedEnhet || isLoading}>
                    Rekoble {isLoading && (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RekobleGrossist;