import React, { useState, useContext } from "react";
import { Button, Spinner } from "react-bootstrap";

import { EndringsMelding } from "services/QAApi/types";

import QAApi from "services/QAApi";

import HttpErrorHelper from "utils/HttpErrorHelper";
import useToaster from "utils/hooks/useToaster";

import Style from "./index.module.css";
import { useMsal } from "@azure/msal-react";

interface Props {
  changeRequest: EndringsMelding;
}

const ToolButtons: React.FC<Props> = props => {
  const toaster = useToaster();

  const { accounts } = useMsal();

  const username = accounts[0] && accounts[0].name;

  const currentUser = username || 'ukjent';

  const [isVaskLoading, setIsVaskLoading] = useState(false);
  const [isIgnorerLoading, SetIsIgnorerLoading] = useState(false);
  const [isAvvisLoading, setIsAvvistLoading] = useState(false);

  const onAvvisClicked = async (id: string) => {
    setIsAvvistLoading(true);
    try {
      await QAApi.avvis(id, currentUser, 'Manuelt avvist');
      setIsAvvistLoading(false);
      toaster.success('Suksess!', ' Endringsmelding avvist.');
    } catch (err) {
      setIsAvvistLoading(false);
      toaster.error(
        'Feil! Kunne ikke avvise endringsmelding.',
        HttpErrorHelper.handleErrorMessage(err),
      );
    }
  }

  const onIgnorerClicked = async (id: string, lopenummer?: string) => {
    SetIsIgnorerLoading(true);
    try {
      await QAApi.settTilBehandlet({
        id: id,
        lopenummer: lopenummer,
        changedBy: currentUser,
      });
      SetIsIgnorerLoading(false);
      toaster.info('Behandlet', 'Endringsmelding har fått status "behandlet"');
    } catch (err) {
      SetIsIgnorerLoading(false);
      toaster.error(
        'Feil! Kunne ikke endre status på endringsmelding.',
        HttpErrorHelper.handleErrorMessage(err),
      );
    }
  }

  const onStartVaskClicked = async (id: string) => {
    setIsVaskLoading(true);
    try {
      const res = await QAApi.startVaskEndringsmelding(id);
      if (res) {
        toaster.success('Suksess', `${res}`);
      }
    } catch (err) {
      setIsVaskLoading(false);
      toaster.error('Feil! Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }

    setIsVaskLoading(false);
  };

  return (
    <div className={Style.moveButton}>
      <Button className={Style.buttonStyle} size="sm" variant="outline-danger"
        onClick={() => onAvvisClicked(props.changeRequest.id)}>
        {
          isAvvisLoading ?
            <span>
              <Spinner className={Style.isLoadingSpinner} as="span"
                animation="border" size="sm" role="status" aria-hidden="true" />
              Avviser
            </span> :
            <span>Avvis</span>
        }
      </Button>

      <Button className={Style.buttonStyle} size="sm" variant="outline-primary"
        onClick={() => onIgnorerClicked(props.changeRequest.id, props.changeRequest.lopenummer)}>
        {
          isIgnorerLoading ?
            <span>
              <Spinner className={Style.isLoadingSpinner} as="span"
                animation="border" size="sm" role="status" aria-hidden="true" />
              Ignorerer
            </span> :
            <span>Ignorer</span>
        }
      </Button>

      <Button className={Style.buttonStyle} size="sm" variant="outline-primary"
        onClick={() => onStartVaskClicked(props.changeRequest.id)}>
        {
          isVaskLoading ?
            <span>
              <Spinner className={Style.isLoadingSpinner} as="span"
                animation="border" size="sm" role="status" aria-hidden="true" />
              Vasker
            </span> :
            <span>Vask på nytt</span>
        }
      </Button>
    </div>
  )
}

export default ToolButtons;