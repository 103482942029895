
export interface KjedeHistorikk {
  enhetKjedeId: number;
  fraOgMedDato: Date;
  tilOgMedDato?: Date;
  isActive: boolean;
  isFuture: boolean;
  isHistoric: boolean;
  isIndirectChange: boolean;
  kjedeId: number;
  kjedenrPath: string;
  kundenrIKjede: string;
  namePath: string;
  kjedeNrHierarchy: number[];
  nameHierarchy: string[];
  indirekteHistorikk: KjedeHistorikk[];
  kjedeHierarki: HierarchyKjede[];
}

export interface HierarchyKjedeTest {
  kjedeId: number;
  kjedenr: string;
  kjedenavn: string;
  kjedeEnhetId: number;
}

export interface Adresse {
  gateadresse: string;
  postnr: string;
  poststed: string;
}

export interface PropertyChange {
  id: number;
  column: string;
  oldValue: string;
  newValue: string;
  timestamp: Date;
  changedBy: string;
}

export interface PagedCollection<T> {
  currentPage: number;
  firstRowOnPage: number;
  lastRowOnPage: number;
  pageCount: number;
  pageSize: number;
  totalCount: number;
  results: T[];
}

export interface KjedeMedlem {
  enhetID: number;
  lopenr: number;
  kundenrIKjede: string;
  enhetnavn: string;
  besoksadresse: Adresse;
  fraOgMedDato: Date;
  tilOgMedDato?: Date;
  nedlagtDato?: Date;
}

export interface GrupperingMedlem {
  enhetID: number;
  lopenr: number;
  kundenrIGruppering: string;
  enhetnavn: string;
  besoksadresse: Adresse;
  fraOgMedDato: Date;
  tilOgMedDato?: Date;
  nedlagtDato?: Date;
}
export class HierarchyKjede {
  constructor(kjedeId: number, kjedenr: string, kjedenavn: string, kjedeEnhetId: number) {
    this.kjedeId = kjedeId;
    this.kjedenr = kjedenr;
    this.kjedenavn = kjedenavn;
    this.kjedeEnhetId = kjedeEnhetId;
  }
  kjedeId: number;
  kjedenr: string;
  kjedenavn: string;
  kjedeEnhetId: number;
}

export interface FirmaViewModel {
  lopenr?: number;
  statistikknr?: number;
  forrigeLopenr?: number;
  nesteLopenr?: number;
  gln: string;
  eierGLN: string;
  eierGLNNavn: string;
  glnNedlagtDato?: Date;
  juridisknavn: string;
  organisasjonsnr: string;
  juridisknavnBedrift: string;
  bedriftsnr: string;
  bransje: string;
  grossist: string;
  enhettype: string;
  regionalKjede: string;
  salgsOgMarkedsKjede: string;
  telefon: string;
  nedlagtDato?: Date;
  oppstartsdato?: Date;
  kontonr: string;
  epost: string;
  fakturaEpost: string;
  postFraOgMedDato?: Date;
  erSesongdrift: boolean;
  landkode: string;
  erOffentlig: boolean;
  reservertMotPost: boolean;
  kanMottaEfaktura: boolean;
  salgsArealKvadratmeter?: number;
  omsetningMillioner?: number;
  produl?: number;
}

export interface AdresseViewModel {
  id: number;
  gateadresse: string;
  postnr: string;
  adressetype: string;
  ekstraInfo: string;
  poststed: string;
  landkode: string;
  formattedForOneLine: string;
  latitude?: number;
  longitude?: number;
}

export interface EnhetTypeViewModel {
  kode: string;
  navn: string;
}

export interface EnhetTyperViewModel {
  kode: string;
  navn: string;
  erHovedtype: boolean;
}

export interface GenericViewModel {
  id: number;
  name: string;
  code: string;
  number: string;
  type: string;
}

export interface KjedeViewModel {
  id: number;
  name: string;
  number: string;
}

export interface GrossistViewModel {
  id: number;
  enhetGrossistId: number;
  name: string;
  grossistEnhetNavn: string;
  kundenummerHosGrossist: string;
  grossistGln: string;
  erHovedgrossist: boolean;
}

export interface OmsetningViewModel {
  year: number;
  omsetningMillioner: number;
  produl?: number;
}

export interface EnhetViewModel {
  id: number;
  enhetnavn: string;
  notat: string;
  lopenr: string;
  komplettTidspunkt?: Date;
  erPublisert: boolean;
  firma: FirmaViewModel;
  besoksadresse: AdresseViewModel;
  postadresse: AdresseViewModel;
  fakturaAdresse: AdresseViewModel;
  leveringsadresse: AdresseViewModel;
  enhetType: EnhetTypeViewModel;
  enhetTyper: EnhetTyperViewModel[];
  bransje: GenericViewModel;
  regionalKjede: KjedeViewModel;
  regionalKjedeKundeNr: string;
  salgsOgMarkedsforingsKjede: KjedeViewModel;
  salgsOgMarkedsforingsKjedeKundeNr: string;
  grossist: GrossistViewModel;
  grossister: GrossistViewModel[];
  omsetning: OmsetningViewModel;
  nedlagtDato?: Date;
  oppstartsdato?: Date;
  sisteRegionalkjedeMedlemskap: LatestKjedemedlemskap;
  sisteMarkedskjedeMedlemskap: LatestKjedemedlemskap;
  regionalkjedeHistorikk: KjedeHistorikk[];
  markedskjedeHistorikk: KjedeHistorikk[];
  grupper: Enhetsgruppering[];
  leverandorKundenummer: LeverandorKundenummerDto[];
  sisteGrupperingMedlemskap: LatestGrupperingMedlemskap;
  showPublishReminder: boolean;
  isInactive: boolean;
  hasTwoDifferentToppKjederNowOrInTheFuture: boolean;
}

export interface LatestKjedemedlemskap {
  fraOgMedDato: Date;
  id: number;
  kjedeId: number;
  enhetKjedeId: number;
  isActive: boolean;
  isFuture: boolean;
  isHistoric: boolean;
  name: string;
  number: string;
  tilOgMedDato: Date;
}

export interface LatestGrupperingMedlemskap {
  id: number;
  enhetgrupperingId: number;
  enhetEnhetgrupperingID: number;
  enhetgrupperingtypekode: string;
  enhetgrupperingtypenavn: string;
  name: string;
  number: string;
  fraOgMedDato: Date;
  tilOgMedDato?: Date;
}

export interface OverliggendeKjede {
  kjedeID: string;
  kjedetypeKode: string;
  fraOgMedDato: Date;
  parentKjede: {
    kjedeID: number;
  };
}

export interface Enhetsgruppering {
  enhetEnhetgrupperingID: number;
  enhetgrupperingId: number;
  enhetgrupperingnr: number;
  enhetgrupperingtypekode: string;
  enhetgrupperingtypenavn: string;
  enhetsgrupperingnavn: string;
  fraOgMedDato: Date;
  toppnivaaEnhetsgrupperingnavn: string;
  kjedenr: string;
  kundenr: string;
  skalBetraktesSomKjedemedlemskap: boolean;
  tilOgMedDato?: Date;
}

export interface LeverandorKundenummerDto {
  id: string;
  enhetId: number;
  enhetNavn: string;
  lopenummer: number;
  mottakerId: number;
  mottakerNavn: string;
  kundenummer: string;
  tsKundeId: string;
}

export interface KontonrOgFakturaAdresseSjekkResponse {
  harSammeOrgNr: boolean;
  kontonrErForskjellig: boolean;
  fakturaAdresseErForskjellig: boolean;
  fakturaEpostErForskjellig: boolean;
  enhetKontonr: string;
  enhetFakturaAdresse: string;
  hovedkontorEnhetId: number;
  hovedkontorEnhetnavn: string;
  hovedkontorKontonr: string;
  hovedkontorFakturaAdresse: string;
  hovedKontorFakturaEpost: string;
  hovedkontorFakturaAdresseId?: number;
}


export interface EierskifteEnhetViewModel {
  enhetId: number;
  lopenummer?: number;
  enhetnavn: string;
  kanVidereforeTil: boolean;
  nedlagtDato?: Date;
}

// for enhet
export interface ReAktiviserCommand {
  id: number;
  reaktiverSisteKjedeOgEnhetsgrupperingsMedlemsskap: boolean;
}