import { useState, useEffect } from "react";
import { Button, Collapse, Row, Form, InputGroup, OverlayTrigger, Col } from "react-bootstrap";

import querystring from 'query-string';
import cx from "classnames";

import CompareIcon from "../../../CompareIcon";
import BrregPopover from "components/BrregPopover";

import BrregTypeAhead from "components/BrregTypeAhead";
import CopyToClipboardIcon from "components/CopyToClipboardIcon";
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import { OrgNrInfo } from "./types";

import BrregApi from "services/KjederegisteretAdminApi/BrregApi";
import { BrregEnhetResponse } from "services/KjederegisteretAdminApi/BrregApi/types";
import { EnhetSearchFilter } from "services/KjederegisteretAdminApi/EnhetSearchApi/types";

import openInNewTab from "utils/functions/openInNewTab";
import useIsMountedRef from "utils/hooks/useIsMountedRef";
import { qaCompareSimpleValue, qaCompareObjectValues } from "../../../../utils";

import Style from "../../index.module.css";
import useLocalStorage from "utils/hooks/useLocalStorage";

interface Props {
  changeRequestId: string;
  changeRequest: OrgNrInfo;
  improvedChangeRequest: OrgNrInfo;
  onChange: (updatedValue: OrgNrInfo) => void;
  enhetJurNavn: string;
  duplicateWarning: string;
}

const OrgNrRow = (props: Props) => {
  const isMountedRef = useIsMountedRef();

  const [lookupChangeRequestOrgnrResult, setLookupChangeRequestOrgnrResult] = useState<BrregEnhetResponse[]>([]);
  const [isLoadingLookupChangeRequest, setIsLoadingLookupChangeRequest] = useState<boolean>(false);
  const [isShowMoreChangeRequest, setIsShowMoreChangeRequest] = useState<boolean>(false);
  const [isExpandRow, setIsExpandRow] = useState<boolean>(false);
  const [isUserPreferneceExpandRow, setIsUserPreferneceExpandRow] = useLocalStorage<boolean>('userPreferenceOrgNrRowExpanded', false);
  const [isLoadingBrreg, setIsLoadingBrreg] = useState<boolean>(false);
  const [selectedBrregEnhetInfo, setSelectedBrregEnhetInfo] = useState<BrregEnhetResponse>();
  const [compareStatus, setCompareStatus] = useState<string>(
    qaCompareSimpleValue(props.changeRequest.organisasjonsnr, props.improvedChangeRequest.organisasjonsnr),
  );
  const [selectedJuridisk, setSelectedJuridisk] = useState<{ name: string; orgNr: string }[]>(
    props.improvedChangeRequest.juridisknavn && props.improvedChangeRequest.organisasjonsnr ? [
      { name: props.improvedChangeRequest.juridisknavn, orgNr: props.improvedChangeRequest.organisasjonsnr },
    ] : []);

  const lookupByChangeRequestOrgNr = async (value: string) => {
    isMountedRef.current && setIsLoadingLookupChangeRequest(true);
    if (value) {
      const res = await BrregApi.getByOrgNr(value);
      if (res && !res[0].finnesIkke && !res[0].slettedato && isMountedRef.current) {
        setLookupChangeRequestOrgnrResult(res);
        setIsLoadingLookupChangeRequest(false);
      }
    }
    isMountedRef.current && setIsLoadingLookupChangeRequest(false);
  };

  const transferChangeRequestValue = () => {
    if (isMountedRef.current) {
      if (props.changeRequest?.organisasjonsnr && (props.changeRequest?.juridisknavn ||
        (lookupChangeRequestOrgnrResult[0] && lookupChangeRequestOrgnrResult[0].name && !lookupChangeRequestOrgnrResult[0].finnesIkke))
      ) {
        let simpleSelected: { name: string; orgNr: string }[] = [{
          name: (props.changeRequest.juridisknavn !== lookupChangeRequestOrgnrResult[0].name) ? lookupChangeRequestOrgnrResult[0].name : props.changeRequest?.juridisknavn,
          orgNr: props.changeRequest?.organisasjonsnr,
        }];
        setSelectedJuridisk(simpleSelected);
      }
    }
  };

  const handleBrregSelectedChange = (selected: BrregEnhetResponse) => {
    if (selected) {
      if (Array.isArray(selected) && selected.length > 0) {
        setSelectedBrregEnhetInfo(selected[0]);
        const simpleArrSelected: { name: string; orgNr: string }[] = [{
          name: selected[0]?.name.toUpperCase(),
          orgNr: selected[0]?.orgNr,
        }];
        if ((simpleArrSelected[0]?.name !== selectedJuridisk[0]?.name) ||
          (simpleArrSelected[0]?.orgNr !== selectedJuridisk[0]?.orgNr)) {
          setSelectedJuridisk(simpleArrSelected);
        }
      } else if (typeof selected === 'object') {
        setSelectedBrregEnhetInfo(selected);
        const simpleSelected: { name: string; orgNr: string } = {
          name: selected.name.toUpperCase(),
          orgNr: selected.orgNr,
        };
        if ((simpleSelected.name !== selectedJuridisk[0]?.name) ||
          (simpleSelected.orgNr !== selectedJuridisk[0]?.orgNr)) {
          setSelectedJuridisk([simpleSelected]);
        }
      }
    } else {
      setSelectedJuridisk([]);
      setSelectedBrregEnhetInfo(null);
    }
  }

  const handleGoToEnhetSearch = (criteria: string) => {
    let filter = new EnhetSearchFilter();
    filter.criteria = criteria;
    let searchstring = querystring.stringify(filter, {
      arrayFormat: 'bracket',
      skipNull: true,
    });
    openInNewTab(`/enheter?${searchstring}`);
  }

  useEffect(() => {
    if (isMountedRef.current) {
      setCompareStatus(qaCompareObjectValues({
        orgnr: props.changeRequest.organisasjonsnr,
        juridisknavn: props.changeRequest.juridisknavn
      }, {
        orgnr: selectedJuridisk[0]?.orgNr,
        juridisknavn: selectedJuridisk[0]?.name
      },
        lookupChangeRequestOrgnrResult && lookupChangeRequestOrgnrResult[0] &&
        lookupChangeRequestOrgnrResult[0].name === selectedJuridisk[0]?.name && lookupChangeRequestOrgnrResult[0].name !== props.changeRequest.juridisknavn
      ));
    }
  }, [selectedJuridisk, props.changeRequest.organisasjonsnr, props.improvedChangeRequest.organisasjonsnr, lookupChangeRequestOrgnrResult]);

  useEffect(() => {
    if (props.changeRequest.organisasjonsnr) {
      lookupByChangeRequestOrgNr(props.changeRequest.organisasjonsnr);
    } else {
      setLookupChangeRequestOrgnrResult([]);
    }
  }, [props.changeRequest.organisasjonsnr]);

  useEffect(() => {
    if (props.improvedChangeRequest.organisasjonsnr !== selectedJuridisk[0]?.orgNr || (!selectedJuridisk && isMountedRef.current)) {
      setIsShowMoreChangeRequest(false);
      handleBrregSelectedChange(null);
      setSelectedJuridisk([]);
      setSelectedBrregEnhetInfo(undefined);
    }
  }, [props.improvedChangeRequest]);

  useEffect(() => {
    if (compareStatus === 'ulik' || compareStatus === 'ny') {
      setIsExpandRow(true);
    }

    if (isUserPreferneceExpandRow) {
      setIsExpandRow(true);
    }
  }, [compareStatus]);

  useEffect(() => {
    if (selectedBrregEnhetInfo && (selectedBrregEnhetInfo.slettedato || selectedBrregEnhetInfo.finnesIkke)) {
      setIsExpandRow(true);
    }

    if (isUserPreferneceExpandRow) {
      setIsExpandRow(true);
    }

  }, [selectedBrregEnhetInfo]);

  useEffect(() => {
    if (isMountedRef.current) {
      props.onChange({
        organisasjonsnr: selectedJuridisk[0]?.orgNr,
        juridisknavn: selectedJuridisk[0]?.name,
        isOrgnrValidated: true,
      })
    }
  }, [selectedJuridisk]);

  const handleExpandRowClick = () => {
    setIsExpandRow(!isExpandRow);
    setIsUserPreferneceExpandRow(!isExpandRow);
  };

  return (
    <>
      <div className={cx(Style.changeRequestRowHeaderContainer, { [Style.isNotExpandedRowContainer]: !isExpandRow })}
        onClick={handleExpandRowClick}>
        <h6 className={Style.changeRequestRowHeader}>
          {!isExpandRow && <CompareIcon compareStatus={compareStatus} size="sm" />}
          {lookupChangeRequestOrgnrResult && lookupChangeRequestOrgnrResult[0] &&
            !isLoadingLookupChangeRequest && (props.changeRequest.juridisknavn !== lookupChangeRequestOrgnrResult[0].name) && (
              <div className={Style.jurNameMismatchIconContainer}>
                <IcebergIcon backgroundColor="rgba(var(--bs-danger-rgb))" color="white" size="sm" icon="exclamationMark" rounded bold />
              </div>
            )}
          <span className={Style.changeRequestRowHeaderText}>Organisasjonsnummer</span>
        </h6>
        <Button variant="link" size="sm">
          <IcebergIcon icon={isExpandRow ? 'chevron-up' : 'chevron-down'} bold />
        </Button>
      </div>
      <Collapse in={isExpandRow}>
        <div>
          <Row className={Style.formGroupRow}>
            <div className={Style.formGroupContainer}>
              <Form.Group className={Style.formGroup}>
                <InputGroup size='sm' className={Style.orgNrInputGroup}>
                  {!lookupChangeRequestOrgnrResult[0] ? (
                    <InputGroup.Text style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                      <IcebergIcon icon="search" />
                    </InputGroup.Text>
                  ) : (
                    <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={BrregPopover(lookupChangeRequestOrgnrResult[0])}>
                      {lookupChangeRequestOrgnrResult[0].slettedato || lookupChangeRequestOrgnrResult[0].finnesIkke ? (
                        <Button variant="warning" id="button-addon1" style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                          <IcebergIcon icon="check" bold />
                        </Button>
                      ) : (
                        <Button variant="success" id="button-addon1" style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                          <IcebergIcon icon="check" bold />
                        </Button>
                      )}
                    </OverlayTrigger>
                  )}
                  <Form.Control
                    readOnly
                    value={props.changeRequest.organisasjonsnr || ''}
                    isValid={lookupChangeRequestOrgnrResult.length > 0}
                    isInvalid={lookupChangeRequestOrgnrResult && lookupChangeRequestOrgnrResult[0]?.finnesIkke === true} />
                  <InputGroup.Text>
                    <CopyToClipboardIcon val={props.changeRequest.organisasjonsnr} />
                  </InputGroup.Text>
                </InputGroup>
                {lookupChangeRequestOrgnrResult[0] && !lookupChangeRequestOrgnrResult[0].finnesIkke && (
                  <Row className={Style.orgNrFormRow}>
                    {!isShowMoreChangeRequest &&
                      <Col className={Style.formTxtCol}>
                        <div>
                          {lookupChangeRequestOrgnrResult[0] && !isLoadingLookupChangeRequest && (props.changeRequest.juridisknavn !== lookupChangeRequestOrgnrResult[0].name) &&
                            <>
                              <Form.Text className='text-muted'>
                                Juridisk navn i endringsmelding:{' '}
                                <span style={{ color: 'red' }}> {props.changeRequest.juridisknavn}</span>
                              </Form.Text>
                              <br />
                            </>}
                          {lookupChangeRequestOrgnrResult[0] && (
                            <Form.Text className='text-muted'>Juridisk navn i brreg: {lookupChangeRequestOrgnrResult[0].name}</Form.Text>
                          )}
                        </div>
                      </Col>
                    }
                  </Row>
                )}
              </Form.Group>
            </div>
            <div className={Style.compareIconContainer}>
              <CompareIcon compareStatus={compareStatus} onClick={transferChangeRequestValue} />
            </div>
            <div className={Style.formGroupContainer}>
              <Form.Group className={Style.formGroup}>
                <InputGroup id="juridiskinfo" size="sm">
                  {!selectedBrregEnhetInfo ? (
                    <InputGroup.Text style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                      <IcebergIcon icon="search" />
                    </InputGroup.Text>
                  ) : (
                    <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={BrregPopover(selectedBrregEnhetInfo)}>
                      {selectedBrregEnhetInfo.slettedato || selectedBrregEnhetInfo.finnesIkke ? (
                        <Button variant="warning" id="button-addon1" style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                          <IcebergIcon icon="check" bold />
                        </Button>
                      ) : (
                        <Button variant="success" id="button-addon1" style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                          <IcebergIcon icon="check" bold />
                        </Button>
                      )}
                    </OverlayTrigger>
                  )}
                  <BrregTypeAhead
                    selected={(selectedJuridisk && selectedJuridisk.length > 0) ? selectedJuridisk : []}
                    clearTypeaheadInitiator={props.changeRequestId}
                    onBrregEnhetSelected={e => handleBrregSelectedChange(e)}
                    setIsLoadingBrreg={setIsLoadingBrreg}
                    size="small"
                  />
                </InputGroup>
                {selectedJuridisk && !selectedBrregEnhetInfo && !isLoadingBrreg && (
                  <Form.Text className={Style.brregWarningFormText}>
                    <span>Ingen funn på eksisterende juridisk navn og organisasjonsnummer kombinasjon. </span>
                  </Form.Text>
                )}
                {!props.enhetJurNavn && selectedBrregEnhetInfo?.orgNr && props.duplicateWarning && (
                  <Form.Text className='text-muted'>
                    <span>{props.duplicateWarning} </span>
                    <a style={{ fontSize: '12px' }} href="#" onClick={() => handleGoToEnhetSearch(selectedJuridisk[0]?.orgNr)}>Se enhet(er)</a>
                  </Form.Text>
                )}
              </Form.Group>
            </div>
          </Row>
        </div>
      </Collapse>
    </>
  )
}

export default OrgNrRow;