import { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import HealthApi from "services/KjederegisteretAdminApi/HealthApi";

interface SystemStatus {
    name: string;
    status: string;
    errorMessage?: string;
}

const HealthStatus = () => {

    const [healthStatus, setHealthStatus] = useState<any>();
    const loadHealthStatus = async () => {
        try {
            const response = await HealthApi.get();
            setHealthStatus(response);
        } catch (err) {
            console.log(err);
            setHealthStatus(undefined);
        }
    };

    // first load
    useEffect(() => {
        loadHealthStatus();
    }, []);

    // load every minute
    useEffect(() => {
        const timer = setTimeout(() => {
            loadHealthStatus();
        }, 60000); // every minute

        return () => clearTimeout(timer);
    }, [healthStatus]);

    const getVariant = () => {
        if (!healthStatus) {
            return "light";
        }
        switch (healthStatus.status) {
            case "Healthy":
                return "success";
            case "Degraded":
                return "warning";
            case "Unhealty":
                return "danger";
        }
    }

    const getTextStatus = () => {
        if (!healthStatus) {
            return "Kjederegister api er utilgjengelig";
        }
        switch (healthStatus.status) {
            case "Healthy":
                return "Alle systemer ser ut til å svare ok!";
            case "Degraded":
                return "En eller flere tjenester er utilgjengelig, men systemet er fortsatt i drift";
            case "Unhealty":
                return "En eller flere tjenester er utilgjengelig";
        }
    };

    const [showDetails, setShowDetails] = useState<boolean>(false);
    const handleClick = () => {
        setShowDetails(!showDetails);
    }

    const handleClose = () => {
        setShowDetails(false);
    }

    const getSystems = (): SystemStatus[] => {
        if (!healthStatus) {
            return [];
        }
        return Object.entries(healthStatus.entries).map(([key, value]) => {
            const v = value as any;
            return { name: key, status: v.status, errorMessage: v.description };
        });
    }

    return (
        <>
            <Button variant={getVariant()} title={getTextStatus()} className="btn-circle" onClick={handleClick} />
            <Modal show={showDetails} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>System status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>{getTextStatus()}</strong></p>
                    <Table>
                        <thead>
                            <tr>
                                <th>System</th>
                                <th>Status</th>
                                <th>Feilmelding</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getSystems().map((system, index) => (
                                <tr key={index}>
                                    <td>{system.name}</td>
                                    <td>{system.status}</td>
                                    <td>{system.errorMessage}</td>
                                </tr>
                            )
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleClose} className="col">Lukk</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default HealthStatus;