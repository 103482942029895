import axios from 'axios';
import { Mottaker, AddOrUpdateMottakerCommand } from './types';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/mottaker';

async function getMottakere(): Promise<Mottaker[]> {
  const response = await axios.get(baseUrl);
  return response.data;
}

async function getMottaker(id: number): Promise<Mottaker> {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
}

async function addMottaker(command: AddOrUpdateMottakerCommand) {
  const response = await axios.post(`${baseUrl}`, command).then(response => response.data);
  return response.data;
}

async function updateMottaker(id: number, command: AddOrUpdateMottakerCommand) {
  const response = await axios.put(`${baseUrl}/${id}`, command);
  return response.data;
}

async function deleteMottaker(id: number) {
  axios.delete(`${baseUrl}/${id}`).then(response => response.data);
}

async function get(query: string, onlyWithTsKundeId = false): Promise<Mottaker[]> {
  const response = await axios.get(`${baseUrl}?q=${query}&onlyWithTsKundeId=${onlyWithTsKundeId}`);
  return response.data;
}

const MottakerApi = {
  getMottakere,
  getMottaker,
  addMottaker,
  updateMottaker,
  deleteMottaker,
  get
}

export default MottakerApi;
