/*IMPORTS */

/*React & React module dependencies */
import React, { useEffect, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';

/*shared */
import BransjePicker, { PickedBransje } from 'components/BransjePicker';

/*services */
import BransjeApi from 'services/KjederegisteretAdminApi/BransjeApi';
import { Bransje, UpdateBransjeCommand } from 'services/KjederegisteretAdminApi/BransjeApi/types';

/*utils */
import useForm from 'utils/hooks/useForm';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';

/*IMPORTS END */

interface Props {
  bransje: Bransje;

  onUpdated: (bransje: Bransje) => void;
}

const BransjeEditTab: React.FC<Props> = (props) => {
  const [memberCount, setMemberCount] = useState<number>(0);
  const [foundBransjeByKode, setFoundBransjeByKode] = useState<Bransje | undefined>();
  const [pickedOverliggendeBransjeID, setPickedOverliggendeBransjeID] = useState<number>(
    props.bransje?.overliggendeBransjeId,
  );
  const isMountedRef = useIsMountedRef();
  const toaster = useToaster();

  const initialVals: UpdateBransjeCommand = {
    id: props.bransje.bransjeID,
    bransjekode: props.bransje.bransjekode,
    bransjenavn: props.bransje.bransjenavn,
    definisjon: props.bransje.definisjon,
    overliggendeBransjeId: props.bransje.overliggendeBransjeId,
  };

  const validate = (values: UpdateBransjeCommand) => {
    const err: any = {};
    if (!values.bransjekode) err.bransjekode = 'Bransjekode må fylles ut';
    if (!values.bransjenavn) err.bransjenavn = 'Bransjenavn må fylles ut';
    return err;
  };

  const onSubmit = async (command: UpdateBransjeCommand) => {
    try {
      const response = await BransjeApi.updateBransje(command);
      props.onUpdated(response);
      toaster.success('Suksess', 'Bransje oppdatert!');
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  };

 

  const { touched, errors, values, handleSubmit, handleChange, submitDisabled, resetForm } =
    useForm(initialVals, validate, onSubmit);

  const handleOverliggendeBransjeChanged = async (pickedBransjer: PickedBransje[]) => {
    if (pickedBransjer?.length > 0) {
      setPickedOverliggendeBransjeID(pickedBransjer[0].id);
    }

    if (props.bransje?.bransjeID) {
      const count = await BransjeApi.getAntallaktivemedlemmerforbransjeplussunderbransjer(
        props.bransje.bransjeID,
      );
      setMemberCount(count);
    }
  };

  const lookupByKode = async (kode: string) => {
    const result = await BransjeApi.getByKode(kode);
    if (result && result.bransjenr !== props.bransje.bransjenr) {
      setFoundBransjeByKode(result);
    } else {
      setFoundBransjeByKode(undefined);
    }
  };

  const displayInfoAlert = (count: number) => {
    return (
      <Alert variant="info">
        {count} aktive enheter vil få nytt bransjehierarki som følge av denne endringen.
      </Alert>
    );
  };

  const handleBransjeKodeChanged = (kode: string) => {
    handleChange('bransjekode', kode);
    lookupByKode(kode);
  };

  useEffect(() => {
    if (isMountedRef.current) {
      resetForm(initialVals);
      setPickedOverliggendeBransjeID(props.bransje?.overliggendeBransjeId);
      setMemberCount(0);
    }
  }, [props.bransje]);

  useEffect(() => {
    if (isMountedRef.current) {
      handleChange('overliggendeBransjeId', pickedOverliggendeBransjeID);
    }
  }, [pickedOverliggendeBransjeID]);

  /*Content */
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Bransjenavn</Form.Label>
        <Form.Control
          name="bransjenavn"
          type="text"
          autoComplete="false"
          value={values.bransjenavn}
          onChange={(e) => handleChange('bransjenavn', e.target.value)}
          isInvalid={touched.bransjenavn && errors.bransjenavn}
          isValid={touched.bransjenavn && !errors.bransjenavn}
        />
        <Form.Control.Feedback type="invalid">{errors.bransjenavn}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Bransjekode</Form.Label>
        <Form.Control
          name="bransjekode"
          type="text"
          value={values.bransjekode}
          onChange={(e) => handleBransjeKodeChanged(e.target.value)}
          isInvalid={!!foundBransjeByKode}
        />
        <Form.Control.Feedback type="invalid">
          Bransjekode må være unik og {values.bransjekode} er allerede i bruk av bransje{' '}
          {foundBransjeByKode?.bransjenr + ' ' + foundBransjeByKode?.bransjenavn}
        </Form.Control.Feedback>
      </Form.Group>
      {props.bransje.bransjenr && (
        <Form.Group className="mb-3">
          <Form.Label>Bransjenr</Form.Label>
          <Form.Control name="bransjenr" type="number" value={props.bransje.bransjenr} readOnly />
        </Form.Group>
      )}
      <Form.Group className="mb-3">
        <Form.Label>Definisjon</Form.Label>
        <Form.Control
          name="definisjon"
          as="textarea"
          autoComplete="false"
          value={values.definisjon || ''}
          onChange={(e) => handleChange('definisjon', e.target.value)}
          isInvalid={touched.definisjon && errors.definisjon}
          isValid={touched.definisjon && !errors.definisjon}
        />
        <Form.Control.Feedback type="invalid">{errors.definisjon}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Overliggende bransje</Form.Label>
        <BransjePicker
          onSelectionChanged={handleOverliggendeBransjeChanged}
          selected={[pickedOverliggendeBransjeID]}
          mode="radioSelect"
        />
        {memberCount > 0 && displayInfoAlert(memberCount)}
      </Form.Group>
      <Form.Group>
        <Button type="submit" disabled={submitDisabled}>
          Lagre
        </Button>
      </Form.Group>
    </Form>
  );
};

export default BransjeEditTab;
