import { useState, useEffect } from 'react';
import { Button, Popover, OverlayTrigger, Form, Spinner, InputGroup, Alert } from 'react-bootstrap';

import { debounce } from 'lodash';
import querystring from 'query-string';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import { CreateEnhetCommand } from 'services/KjederegisteretAdminApi/EnhetApi/Commands';
import BrregApi from 'services/KjederegisteretAdminApi/BrregApi';
import { BrregEnhetResponse } from 'services/KjederegisteretAdminApi/BrregApi/types';
import { EnhetSearchFilter } from 'services/KjederegisteretAdminApi/EnhetSearchApi/types';
import { findEnhetDuplicates } from 'services/KjederegisteretAdminApi/EnhetApi/EnhetDuplicateHelper';

import openInNewTab from 'utils/functions/openInNewTab';
import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';

import Style from './index.module.css';


const SimpleCreateEnhet = () => {
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [brregResult, setBrregResult] = useState<BrregEnhetResponse | undefined>();
  const [isLoadingBrreg, setIsLoadingBrreg] = useState<boolean>(false);
  const [duplicateOrgnrWarning, setDuplicatOrgnrWarning] = useState<string | undefined>();
  const [duplicateNameWarning, setDuplicatNameWarning] = useState<string | undefined>();

  const toaster = useToaster();

  const initialVals: CreateEnhetCommand = {
    enhetnavn: '',
    juridisknavn: '',
    organisasjonsnummer: '',
    telefon: '',
    epost: '',
    fakturaEpost: '',
    gln: '',
    notat: '',
    kontonr: '',
    erOffentlig: false,
    enhettypekode: '',
    besoksAdresse: null,
  };

  const validate = (values: CreateEnhetCommand) => {
    const errors: any = {};
    if (!values.enhetnavn) errors.enhetnavn = 'Enhetnavn mangler!';
    let cleaned = values.organisasjonsnummer;
    cleaned = cleaned.replaceAll(' ', '');
    cleaned = cleaned.replaceAll('.', '');
    if (isNaN(Number(cleaned))) errors.organisasjonsnummer = 'må bestå av tall!';
    if (!isNaN(Number(cleaned)) && cleaned && cleaned.length > 9) errors.organisasjonsnummer = 'Kan ikke overskride 9 siffer';
    if (!isNaN(Number(cleaned)) && cleaned && cleaned.length < 9) errors.organisasjonsnummer = 'Må være 9 siffer';
    // if brregResult.finnesIkke: true || slettetDato => "Finnes ikke lengre/er slettet i brreg" 
    if (!isLoadingBrreg && !brregResult && !isNaN(Number(cleaned)) && cleaned.length === 9) errors.organisasjonsnummer = 'Ingen treff i brreg';
    return errors;
  };

  const lookupOrgnr = debounce(async (organisasjonsnummer: string) => {
    setIsLoadingBrreg(true);
    if (organisasjonsnummer) {
      const result = await BrregApi.getByOrgNr(organisasjonsnummer);
      if (result.length > 0) {
        setBrregResult(result[0]);
      } else {
        setBrregResult(undefined);
      }
    }
    setIsLoadingBrreg(false);
  }, 300);

  const handleNameInputChanged = (input: string) => {
    handleChange('enhetnavn', input);
  };

  const handleOrgnrChanged = (input: string) => {
    setBrregResult(undefined); // reset result on change
    handleChange('organisasjonsnummer', input);
    let cleaned = input;
    cleaned = cleaned.replaceAll(' ', '');
    cleaned = cleaned.replaceAll('.', '');
    if (cleaned !== '' && !isNaN(Number(cleaned))) {
      lookupOrgnr(cleaned);
    }
  };

  const onSubmit = async () => {
    setIsCreating(true);
    try {
      const enhetId = await EnhetApi.createEnhet(values);
      if (enhetId) {
        openInNewTab(`/enheter/${enhetId}`);
        resetForm(initialVals);
      }

    } catch (err) {
      toaster.error('Feil! Kunne ikke opprette enhet', HttpErrorHelper.handleErrorMessage(err));
    }
    setIsCreating(false);
  };

  const checkForDuplicates = () => {
    findEnhetDuplicates(values).then(e => {
      if (e?.byOrgnr.length >= 50) {
        setDuplicatOrgnrWarning(`Det er 50 eller flere enheter med samme orgnr i KR.`);
      } else if (e?.byOrgnr.length > 0 && e?.byOrgnr.length < 50) {
        setDuplicatOrgnrWarning(`Det er ${e.byOrgnr.length} enheter med samme orgnr i KR.`)
      } else {
        setDuplicatOrgnrWarning(undefined);
      }

      if (e?.byEnhetnavn.length >= 50) {
        setDuplicatNameWarning(`Det er 50 eller flere enheter med samme navn i KR.`)
      } else if (e?.byEnhetnavn.length > 0 && e?.byEnhetnavn.length < 50) {
        setDuplicatNameWarning(`Det er ${e.byEnhetnavn.length} enheter med samme navn i KR.`)
      } else {
        setDuplicatNameWarning(undefined);
      }
    }).catch(err => {
      toaster.error(`Feil!`, HttpErrorHelper.handleErrorMessage(err));
    });
  }

  const {
    touched,
    errors,
    values,
    handleSubmit,
    handleChange,
    submitDisabled,
    resetForm,
    updateValues,
  } = useForm(initialVals, validate, onSubmit);

  const handleGoToEnhetSearch = (criteria: string) => {
    let filter = new EnhetSearchFilter();
    filter.criteria = criteria;
    let searchstring = querystring.stringify(filter, {
      arrayFormat: 'bracket',
      skipNull: true,
    });
    openInNewTab(`/enheter?${searchstring}`);
  }

  useEffect(() => {
    if (brregResult && brregResult.name) {
      const copy = { ...values };
      if (!values.enhetnavn) {
        copy.enhetnavn = brregResult.name;
      }
      copy.juridisknavn = brregResult.name;
      updateValues(copy);
    }
  }, [brregResult]);

  useEffect(() => {
    if (!values.organisasjonsnummer) {
      if (values.juridisknavn === values.enhetnavn) {
        resetForm(initialVals);
      } else {
        const copy = { ...values }
        copy.organisasjonsnummer = '';
        copy.juridisknavn = ''
        updateValues(copy);
      }
      setBrregResult(undefined);
    }
  }, [values.organisasjonsnummer])

  useEffect(() => {
    if (!errors.enhetnavn && !errors.organisasjonsnummer) {
      checkForDuplicates();
    }
  }, [values]);

  const popoverMenu = (
    <Popover className={Style.popover} id="popover-menu">
      <Popover.Header className={Style.popoverTitle}>Opprett enhet</Popover.Header>
      <Popover.Body className={Style.popoverContent}>
        <Form onSubmit={handleSubmit}>

          <Form.Group className={Style.formGroup}>
            <Form.Label>
              Enhetnavn<span style={{ color: 'red' }}>*</span>
            </Form.Label>
            <Form.Control
              className={Style.enhetnavnFormControl}
              placeholder="Navn"
              value={values.enhetnavn}
              onChange={e => handleNameInputChanged(e.target.value)}
              isInvalid={touched.enhetnavn && errors.enhetnavn}
              isValid={touched.enhetnavn && !errors.enhetnavn}
              readOnly={isCreating}
            />
            <Form.Control.Feedback className={Style.formFeedback} type="invalid">{errors.enhetnavn}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Organisasjonsnummer</Form.Label>
            <InputGroup className="mb-3">
              {!isLoadingBrreg ? (
                <InputGroup.Text id="basic-addon" style={{ paddingLeft: '.6rem', paddingRight: '.6rem' }}>
                  <IcebergIcon icon="search" />
                </InputGroup.Text>
              ) : (
                <InputGroup.Text id="basic-addon">
                  <Spinner
                    style={{
                      marginRight: '0',
                      padding: '0',
                    }}
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </InputGroup.Text>
              )}
              <Form.Control
                placeholder="Organisasjonsnummer"
                value={values.organisasjonsnummer}
                onChange={e => handleOrgnrChanged(e.target.value)}
                isInvalid={touched.organisasjonsnummer && errors.organisasjonsnummer}
                isValid={values.organisasjonsnummer && touched.organisasjonsnummer && !errors.organisasjonsnummer}
              />
              <Form.Control.Feedback type="invalid">{errors.organisasjonsnummer}</Form.Control.Feedback>
            </InputGroup>
            {brregResult?.name && (
              <Form.Text>
                Juridisk navn: <strong>{brregResult?.name}</strong>
              </Form.Text>
            )}
          </Form.Group>

          <div className={Style.btnContainer} >
            <Button className={Style.submitBtn} disabled={submitDisabled} type="submit" variant="primary" size="sm">
              {isCreating && (
                <Spinner
                  style={{
                    padding: '0',
                    marginRight: '0.3em',
                  }}
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              <span>Opprett</span>
            </Button>

          </div>

          {(brregResult?.finnesIkke || brregResult?.slettedato) && (
            <Alert variant="warning" className={Style.duplicateContainer}>
              <span className={Style.duplicateMsg}>Organisasjonsnummer finnes ikke eller har slettet dato.</span>
            </Alert>
          )}

          {duplicateNameWarning && (
            <Alert variant="warning" className={Style.duplicateContainer}>
              <span className={Style.duplicateMsg}>{duplicateNameWarning}</span>
              <Button variant="link" size="sm" onClick={() => handleGoToEnhetSearch(values.enhetnavn)}>Se enheter</Button>
            </Alert>
          )}

          {duplicateOrgnrWarning && (
            <Alert variant="warning" className={Style.duplicateContainer}>
              <span className={Style.duplicateMsg}>{duplicateOrgnrWarning}</span>
              <Button variant="link" size="sm" onClick={() => handleGoToEnhetSearch(values.organisasjonsnummer)}>Se enheter</Button>
            </Alert>
          )}

        </Form>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger rootClose trigger="click" placement="auto" overlay={popoverMenu}>
      <Button size="lg" variant="primary" className={Style.createBtn}>
        <IcebergIcon icon="plus" size="lg" bold />
        <span
          style={{
            paddingLeft: '.3em',
          }}
        >
          Opprett enhet
        </span>
      </Button>
    </OverlayTrigger>
  );
};

export default SimpleCreateEnhet;
