import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap';

import { format } from 'date-fns';

import MedlemskapEdit from './MedlemskapEdit';
import AvsluttKjedeMedlemskapModal from './AvsluttKjedeMedlemskapModal';

import ConfirmButton from 'components/ConfirmButton';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import EnhetsgrupperingApi from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi';
import { Enhetgruppering, Medlemskjede, FjernMedlemskjedeCommand } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';

import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';

import Style from './index.module.css';

import * as _ from "lodash";

interface Props {
  gruppering: Enhetgruppering;
  reload: () => void;
  setMedlemskjederCount: (count: number) => void;
}

const MedlemskjederTab = (props: Props) => {
  const toaster = useToaster();
  const isMountedRef = useIsMountedRef();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [medlemskjeder, setMedlemskjeder] = useState<Medlemskjede[]>(props.gruppering.medlemskjeder);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | null>();
  const [queryText, setQueryText] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("kjedeNavn");


  const loadMedlemskjeder = async () => {
    setIsLoading(true);
    const result = await EnhetsgrupperingApi.getMedlemskjeder(props.gruppering.enhetgrupperingID);
    setMedlemskjeder(result);
    setIsLoading(false);
  };

  const onFjernConfirmed = async (kjedeEnhetsgrupperingId: number) => {
    const command: FjernMedlemskjedeCommand = {
      kjedeEnhetgrupperingID: kjedeEnhetsgrupperingId,
    };

    EnhetsgrupperingApi.fjernMedlemskjede(props.gruppering.enhetgrupperingID, command)
      .then(() => {
        toaster.success('Suksess!', 'Kjede ble fjernet.');
      })
      .catch(err => {
        toaster.error('Feil! Kunne ikke fjerne.', HttpErrorHelper.handleErrorMessage(err));
      })
      .finally(() => {
        loadMedlemskjeder();
      });
  };

  const handleShowModal = (id: number) => {
    if (isMountedRef.current) {
      setIsShowModal(true);
      setSelectedId(id);
    }
  };

  const handleCloseModal = () => {
    if (isMountedRef.current) {
      setIsShowModal(false);
      setSelectedId(null);
      loadMedlemskjeder();
    }
  };

  useEffect(() => {
    setMedlemskjeder(props.gruppering.medlemskjeder.filter(o => o.kjedeNavn.toLowerCase().includes(queryText.toLowerCase())));
  }, [queryText]);

  useEffect(() => {
    switch (sortBy) {
      case "kjedeNavn":
        setMedlemskjeder(_.orderBy(props.gruppering.medlemskjeder, o => o.kjedeNavn));
        break;
      case "innmeldt":
        setMedlemskjeder(_.orderBy(props.gruppering.medlemskjeder, o => o.fraOgMedDato));
        break;
      case "innmeldt_desc":
        setMedlemskjeder(_.orderBy(props.gruppering.medlemskjeder, o => -o.fraOgMedDato));
        break;
      case "utmeldt":
        setMedlemskjeder(_.orderBy(props.gruppering.medlemskjeder, o => o.tilOgMedDato));
        break;
      case "utmeldt_desc":
        setMedlemskjeder(_.orderBy(props.gruppering.medlemskjeder, o => -o.tilOgMedDato));
        break;
    }

  }, [sortBy]);

  useEffect(() => {
    const active = medlemskjeder.filter(x => x.isActive);
    props.setMedlemskjederCount(active.length);
  }, [medlemskjeder]);

  return (
    <Card>
      <Card.Body>
        <div className={Style.headerContainer}>
          <h6>{props.gruppering.enhetsgrupperingnavn} sine medlemskjeder</h6>
          <Button size="sm" variant={isEdit ? 'secondary' : 'outline-primary'}
            onClick={() => setIsEdit(!isEdit)}>
            <IcebergIcon icon={isEdit ? "close" : "plus"} bold />
            {isEdit ? <span> Avbryt</span> : <span> Nytt medlemskap</span>}
          </Button>
        </div>
        {
          isEdit &&
          <div className={Style.paddingBottom}>
            <MedlemskapEdit
              setIsEdit={setIsEdit}
              gruppering={props.gruppering}
              onDone={loadMedlemskjeder}
            />
          </div>
        }
        <Form style={{ marginBottom: '0.5rem' }}>
          <Row>
            <Col sm={8}>
              <Form.Control
                size="sm"
                placeholder="Søk"
                value={queryText}
                onChange={e => setQueryText(e.target.value)}
              />
            </Col>
            <Col>
              <Form.Control size="sm" placeholder="Sorter" as="select" value={sortBy || 'postnr'}
                onChange={e => setSortBy(e.target.value)}>
                <option value="kjedeNavn">Navn</option>
                <option value="innmeldt">Innmeldt dato - tidligste først</option>
                <option value="innmeldt_desc">Innmeldt dato - nyligste først</option>
                <option value="utmeldt">Utmeldt dato - tidligste først</option>
                <option value="utmeldt_desc">Utmeldt dato - nyligste først</option>
              </Form.Control>
            </Col>
          </Row>
        </Form>
        <Table striped>
          <thead>
            <tr>
              <th>Navn (Kjedenr)</th>
              <th>Periode</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {medlemskjeder.length > 0 ? (
              medlemskjeder.map(k => (
                <tr key={k.kjedeEnhetgrupperingId}>
                  <td>{k.kjedeNavn} ({k.kjedenummer})</td>
                  <td>
                    {format(k.fraOgMedDato, 'dd.MM.yyyy')} -{' '}
                    {!!k.tilOgMedDato ? format(k.tilOgMedDato, 'dd.MM.yyyy') : ''}
                  </td>
                  <td className="float-right">
                    {!k.tilOgMedDato && (
                      <Button
                        onClick={() => handleShowModal(k.kjedeEnhetgrupperingId)}
                        variant="outline-danger"
                        size="sm"
                      >
                        Meld ut
                      </Button>
                    )}
                    &nbsp;
                    <ConfirmButton onClick={() => onFjernConfirmed(k.kjedeEnhetgrupperingId)} size="sm" />
                  </td>
                </tr>
              ))
            ) : (
              <tr key={Math.random()}>
                {isLoading ? (
                  <td colSpan={3}>
                    <div>
                      <Spinner animation="border" />
                      <span>Henter medlemskjeder..</span>
                    </div>
                  </td>
                ) : (
                  <td colSpan={3}>
                    <h6>Ingen medlemskjeder</h6>
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </Table>
        <AvsluttKjedeMedlemskapModal
          kjedeEnhetsgrupperingId={selectedId}
          isShowModal={isShowModal}
          handleCloseModal={handleCloseModal}
          enhetsgruppeId={props.gruppering.enhetgrupperingID}
        />
      </Card.Body>
    </Card>
  );
};

export default MedlemskjederTab;
