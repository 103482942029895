import React from 'react';
import { Pagination } from 'react-bootstrap';

interface Props {
  activePage: number;
  numberOfItems: number;
  itemsPerPage: number;
  onActivePageChanged: (pageNumber: number) => void;
}

const Pager = (props: Props) => {
  const maxVisiblePages = 5;
  const numberOfPages = Math.ceil(props.numberOfItems / props.itemsPerPage);

  const getPages = () => {
    const pages = [];
    for (let number = calcStartPage(); number <= calcLastPage(); number++) {
      pages.push(
        <Pagination.Item
          active={props.activePage === number}
          key={number}
          onClick={() => props.onActivePageChanged(number)}
        >
          {number}
        </Pagination.Item>,
      );
    }
    return pages;
  };

  const calcStartPage = () => {
    if (numberOfPages < maxVisiblePages) {
      return 1;
    }
    return Math.min(Math.max(1, props.activePage - 2), numberOfPages - maxVisiblePages);
  };

  const calcLastPage = () => {
    if (numberOfPages < maxVisiblePages) {
      return numberOfPages;
    }
    return Math.max(Math.min(props.activePage + 2, numberOfPages), maxVisiblePages);
  };

  return (
    <Pagination size="sm">
      <Pagination.First onClick={() => props.onActivePageChanged(1)} />
      <Pagination.Prev onClick={() => props.onActivePageChanged(Math.max(1, props.activePage - 1))} />
      {getPages().map((page, index) => {
        return <React.Fragment key={index}>{page}</React.Fragment>;
      })}
      <Pagination.Next
        onClick={() => props.onActivePageChanged(Math.min(numberOfPages, props.activePage + 1))}
      />
      <Pagination.Last onClick={() => props.onActivePageChanged(numberOfPages)}>
        {numberOfPages > maxVisiblePages && <span>{numberOfPages}</span>}
      </Pagination.Last>
    </Pagination>
  );
};

export default Pager;
