import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import GrossisterApi from 'services/KjederegisteretAdminApi/GrossisterApi';
import { Grossist, TilknyttetEnhet } from 'services/KjederegisteretAdminApi/GrossisterApi/types';

import SpinnerSmall from 'components/SpinnerSmall';
import TilknyttedeEnheterList from './components/TilknyttedeEnheterList';
import LeggNedGrossist from './components/LeggNedGrossist';

import useToaster from 'utils/hooks/useToaster';

import Style from './index.module.css';

interface Props {
  grossist: Grossist;
  show: boolean;
  handleClose: () => void;
  loadGrossister: (filter: string) => void;
}

const GrossistModal: React.FC<Props> = props => {
  const [enheter, setEnheter] = useState<TilknyttetEnhet[]>(null);
  const [enheterTilknyttetCount, setEnheterTilknyttetCount] = useState<number>(0);
  const [filteredEnheterTilknyttetCount, setFilteredEnheterTilknyttetCount] = useState<number>(0);
  const [isFetchingEnheter, setIsFetchingEnheter] = useState<boolean>(false);
  const [isLoadingNedleggelse, setIsLoadingNedleggelse] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [nedlagtDato, setNedlagtDato] = useState<Date | string>('');

  const toaster = useToaster();

  useEffect(() => {
    fetchEnheter();
  }, [props.grossist.grossistEnhetId, currentPage]);

  useEffect(() => {
    fetchEnheter();
  }, [searchQuery]);

  const fetchEnheter = () => {
    setIsFetchingEnheter(true);
    GrossisterApi.getEnheterByGrossistId(props.grossist.grossistEnhetId, currentPage, 6, searchQuery, false, false)
      .then(res => {
        setEnheter(res.results);
        if (searchQuery === '') {
          setEnheterTilknyttetCount(res.totalCount);
          setFilteredEnheterTilknyttetCount(0);
        } else {
          setFilteredEnheterTilknyttetCount(res.totalCount);
          setCurrentPage(1);
        }
        setIsFetchingEnheter(false);
      })
      .catch(err => {
        setIsFetchingEnheter(false);
        toaster.error('Feil!', `Kunne ikke laste enheter: ${err}`);
      });
  };

  const saveChangesToGrossist = () => {
    setIsLoadingNedleggelse(true);
    if (nedlagtDato) {
      GrossisterApi.leggNedGrossist(props.grossist.grossistEnhetId, { grossistEnhetId: props.grossist.grossistEnhetId, nedlagtDato: nedlagtDato })
        .then(res => {
          if (res) {
            fetchEnheter();
            setIsLoadingNedleggelse(false);
            props.loadGrossister('');
            props.handleClose();
            toaster.success('Suksess!', 'Grossist er lagt ned.');
          }
        })
        .catch(err => {
          props.handleClose();
          setIsLoadingNedleggelse(false);
          toaster.error('Feil!', `Kunne ikke legge ned grossist: ${err}`);
        });
    }
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.grossist.kortnavn}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={Style.modalBody}>
        <div className={Style.tabPaneContainer}>
          {
            !enheter && 
            <div><SpinnerSmall /></div>
          }

          {enheter && enheter.length >= 1 && (
            <TilknyttedeEnheterList
              enheter={enheter}
              isFetchingEnheter={isFetchingEnheter}
              enheterTilknyttetCount={enheterTilknyttetCount}
              filteredEnheterTilknyttetCount={filteredEnheterTilknyttetCount}
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}

          {enheter && enheter.length <= 0 && (
            <LeggNedGrossist setNedlagtDato={setNedlagtDato} />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className={Style.modalFooter}>
        <Button variant="secondary" onClick={props.handleClose}>
          Lukk
        </Button>
        <Button
          className={Style.leggNedBtn}
          variant="danger"
          onClick={saveChangesToGrossist}
          disabled={nedlagtDato === ''}
        >
          <span>Legg ned</span>
          {isLoadingNedleggelse && (
            <Spinner
              className={Style.spinner}
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GrossistModal;
