/*IMPORTS */

/*React & React module dependencies */
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

/*Third-party dependencies */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

/*Styling */
import './index.scss';

/*Custom containers */
import App from './pages/App';

/*Custom util */
import reportWebVitals from './reportWebVitals';
import setLocale from './utils/functions/setLocale';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import { msalConfig } from "./utils/authConfig";

/*IMPORTS END */

// const userManager = getUserManager();

const msalInstance = new PublicClientApplication(msalConfig);

setLocale();

/*Content */
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <MsalProvider instance={msalInstance}>
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          element={
            <App />
          }
        />
      </Routes>
    </BrowserRouter>
  </MsalProvider>
);

const { host } = window.location;
const useDev = host.indexOf('localhost') > -1 || host.indexOf('kjederegisteretadmin-dev') > -1 || host.indexOf('kjederegisterspadev') > -1 || host.indexOf('kjederegisteretadmin-temp') > -1;
const appInsightsKey = useDev ? process.env.REACT_APP_DEV_INSIGHTS_KEY : process.env.REACT_APP_PROD_INSIGHTS_KEY

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: appInsightsKey,
  },
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer(envelope => {
  envelope.tags = envelope.tags || []; // eslint-disable-line no-param-reassign
  envelope.tags.push({ 'ai.cloud.role': 'KjederegisteretSPA' });
});

appInsights.trackPageView();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
