/*IMPORTS */

/*React & React module dependencies */
import { useContext } from 'react';

/*Custom context */
import { ToastContext } from '../contexts/ToastProvider';

/*IMPORTS END */

/** 
 * hook useToaster
 * 
 * Used to retrieve hooks for which to show toast messages
 * @returns hooks for the different message types: info, success, error
 */
export default function useToaster() {
  const toasterContext = useContext(ToastContext);

  return {
    info: (title: string, message: string) => {
      toasterContext.info(title, message);
    },

    success: (title: string, message: string) => {
      toasterContext.success(title, message);
    },
    error: (title: string, message: string | { key: string; errors: string[] }[]) => {
      toasterContext.error(title, message);
    },
  };
}
