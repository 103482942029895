import DatePicker from "components/DatePicker";
import { useState } from "react";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import EnhetsgrupperingApi from "services/KjederegisteretAdminApi/EnhetsgrupperingApi";
import { Enhetgruppering } from "services/KjederegisteretAdminApi/EnhetsgrupperingApi/types";
import useToaster from "utils/hooks/useToaster";
import HttpErrorHelper from "utils/HttpErrorHelper";

interface Props {
  gruppering: Enhetgruppering;
  antallAktiveEnheter: number;
  onSuccess?: () => void;
}

const MeldUtAlleEnhetsMedlemmer = (props: Props) => {
  const toaster = useToaster();

  const [utmeldingsDato, setUtmeldingsDato] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = async () => {
    setIsLoading(true);
    try {
      const result = await EnhetsgrupperingApi.meldUtAktiveEnhetsMedlemmer(props.gruppering.enhetgrupperingID, utmeldingsDato);
      toaster.success('Success', `${result.antallNedlagteMedlemskap} ${result.antallNedlagteMedlemskap > 1 ? 'enheter' : 'enhet'} ble utmeldt.`);
      if (props.onSuccess) {
        props.onSuccess();
      }
    }
    catch (err) {
      toaster.error('Utmelding feilet', HttpErrorHelper.handleErrorMessage(err));
    }
    setIsLoading(false);
  };

  return (
    <Card style={{ marginBottom: "1rem" }}>
      <Card.Body>
        <Card.Title>Meld ut alle aktive enhetsmedlemmer ({props.antallAktiveEnheter})</Card.Title>
        <Form>
          <Form.Group>
            <Form.Label>Utmeldingsdato</Form.Label>
            <InputGroup>
              <DatePicker selected={utmeldingsDato} onChange={setUtmeldingsDato} adjustTimeForTimezoneOffset={true} />
              <Button disabled={isLoading || props.antallAktiveEnheter == 0} onClick={handleButtonClick}>Utfør</Button>
            </InputGroup>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default MeldUtAlleEnhetsMedlemmer;
