import { useEffect, useRef, useState } from 'react';
import ConfirmButton from 'components/ConfirmButton';
import { Alert, Button, ListGroup } from 'react-bootstrap';
import BransjeApi from 'services/KjederegisteretAdminApi/BransjeApi';
import { Bransje } from 'services/KjederegisteretAdminApi/BransjeApi/types';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import ReactPlayer from 'react-player';

interface Props {
  bransje: Bransje;
  onDeleted: () => void;
  onNedlagt: (bransje: Bransje) => void;
  isTabSelected: boolean;
}

const DangerzoneTab = (props: Props) => {
  const toaster = useToaster();
  const [antallAktivemedlemmerforbransje, setAntallAktivemedlemmerforbransje] = useState<number>(0);

  useEffect(() => {
    const get = async () => {
      const count = await BransjeApi.getAntallaktivemedlemmerforbransjeplussunderbransjer(
        props.bransje.bransjeID,
      );
      setAntallAktivemedlemmerforbransje(count);
    };
    get();
  }, [props.bransje.bransjeID]);

  const handleDeleteClicked = async (bransje: Bransje) => {
    try {
      await BransjeApi.del(bransje.bransjeID);
      toaster.success('Suksess', 'Bransje slettet!');
      props.onDeleted();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  };

  const handleLeggNedClicked = async (id: number) => {
    try {
      var response = await BransjeApi.leggnedBransje(id);
      toaster.success('Suksess', 'Bransjen er nedlagt!');
      props.onNedlagt(response);
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  };

  return (
    <ListGroup>
      <ListGroup.Item>
        <Alert variant="info">
          {antallAktivemedlemmerforbransje > 0 && antallAktivemedlemmerforbransje
            ? `Bransjen kan ikke legges ned fordi det er ${antallAktivemedlemmerforbransje} medlemmer som er registrert i bransjen eller underbransjer`
            : `Bransjen kan legges ned fordi det finnes ingen aktive medlemmer`}
        </Alert>
        <ConfirmButton
          disabled={antallAktivemedlemmerforbransje > 0}
          onClick={() => handleLeggNedClicked(props.bransje.bransjeID)}
          customtext="Legg ned bransjen"
        />
      </ListGroup.Item>

      <ListGroup.Item>
        <Alert variant="info">
          {antallAktivemedlemmerforbransje > 0 && antallAktivemedlemmerforbransje
            ? `Bransjen kan ikke slettes fordi det er ${antallAktivemedlemmerforbransje} medlemmer som er registrert i bransjen eller underbransjer`
            : `Bransjen kan slettes fordi det finnes ingen aktive medlemmer`}
        </Alert>

        
          <ConfirmButton
            disabled={antallAktivemedlemmerforbransje > 0}
            onClick={() => handleDeleteClicked(props.bransje)}
            customtext="Slett bransjen"
          />
      </ListGroup.Item>

      {props.isTabSelected && (
        <ListGroup.Item>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=siwpn14IE7E"
            playing={true}
            volume={0.1}
          />
        </ListGroup.Item>
      )}
    </ListGroup>
  );
};

export default DangerzoneTab;
