import React, { useEffect, useCallback } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';

import BransjePicker, { PickedBransje } from 'components/BransjePicker';

import AbonnementApi from 'services/KjederegisteretAdminApi/AbonnementApi';
import { Abonnement, AbonnementContent, UpdateAbonnementBransjerCommand } from 'services/KjederegisteretAdminApi/AbonnementApi/types';

import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';

interface Props {
  abonnement: Abonnement;
  onUpdated: () => void;
}

const EditBransjer: React.FC<Props> = props => {

  const toaster = useToaster();

  const initialVals: UpdateAbonnementBransjerCommand = {
    bransjer: props.abonnement.bransjer
  }

  const validate = (values: UpdateAbonnementBransjerCommand) => {
    let errors: any = {};

    return errors;
  }

  const onSubmit = async(command: UpdateAbonnementBransjerCommand) => {
    try {
      await AbonnementApi.updateAbonnementBransjer(props.abonnement.id, command);
      toaster.success('Abonnement oppdatert', `Abonnement med id '${props.abonnement.id}', ble oppdatert.`,);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, loading, handleChange, handleSubmit }  = useForm(initialVals, validate, onSubmit);


  const handleBransjeSelected = useCallback((selected: PickedBransje[]) => {
    var bransjer: AbonnementContent[] = selected.map(x => {
      var bransje: AbonnementContent = {
        id: x.id,
        name: "",
        number: "",
        code: "",
        isEkskluderende: false
      }
      return bransje;
    });
    handleChange('bransjer', bransjer);
  }, []);

  useEffect(() => {
    handleChange('bransjer', props.abonnement.bransjer);
  }, [props.abonnement.id])

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Bransjer</Form.Label>
        <BransjePicker
          onSelectionChanged={handleBransjeSelected}
          selected={values.bransjer.map(o => o.id)} />
      </Form.Group>
      <Form.Group>
        <Button variant="primary" type="submit" disabled={loading || JSON.stringify(values) === JSON.stringify(initialVals)}>
          {
            loading && 
            <Spinner
              size="sm"
              style={{ marginRight: '.3em' }}
              animation="border"
              role="status" />
          }
          <span>Lagre</span>
        </Button>
      </Form.Group>
    </Form>
  )
}

export default EditBransjer;