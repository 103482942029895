import React, { useState, useRef } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { Button } from 'react-bootstrap';
import { Coordinates } from 'models/Coordinates';

interface Props {
  markedLocation: Coordinates;
  title: string;
  editable?: boolean;
  onLatLngChanged?: (coordinates: Coordinates) => void;
}

const LocationMarker: React.FC<Props> = ({ markedLocation, title, editable, onLatLngChanged }) => {
  const { google } = window;
  const locationMarkerRef = useRef(null);
  const [infoOpen, setInfoOpen] = useState(false);
  const [markerDraggable, setMarkerDraggable] = useState(false);

  function onMoveMarkerClicked() {
    if (!editable) return;
    setMarkerDraggable(true);
    setInfoOpen(false);
  }

  function onCancelMoveMarkerClicked() {
    if (!editable) return;
    setMarkerDraggable(false);
    setInfoOpen(false);
  }

  function onMarkerMoved(e) {
    if (!editable) return;
    setInfoOpen(true);
    const latLng = new Coordinates(
      e.latLng.lat(),
      e.latLng.lng(),
    );
    onLatLngChanged(latLng);
  }

  function handleMarkedRemoved() {
    if (!editable) return;
    onLatLngChanged(undefined);
  }

  return (
    <>
      {infoOpen && (
        <InfoWindow
          options={{
            pixelOffset: new google.maps.Size(0, -45),
          }}
          position={locationMarkerRef?.current.props.position}
          onCloseClick={() => setInfoOpen(false)}
        >
          <div style={{ opacity: 0.75, padding: 5, width: 150 }}>
            {!markerDraggable ? (
              <Button
                size="sm"
                style={{ width: '100%' }}
                variant="primary"
                onClick={onMoveMarkerClicked}
              >
                Flytt markør
              </Button>
            ) : (
              <Button
                size="sm"
                style={{ width: '100%' }}
                variant="primary"
                onClick={onCancelMoveMarkerClicked}
              >
                Bruk koordinater
              </Button>
            )}
            <Button
              size="sm"
              style={{ width: '100%', marginTop: '0.3em' }}
              variant="danger"
              onClick={handleMarkedRemoved}
            >
              Fjern
            </Button>
          </div>
        </InfoWindow>
      )}
      <Marker
        ref={locationMarkerRef}
        position={markedLocation}
        title={title}
        onClick={() => setInfoOpen(editable)}
        draggable={markerDraggable}
        onDragStart={() => setInfoOpen(false)}
        onDragEnd={e => onMarkerMoved(e)}
        animation={markerDraggable ? google.maps.Animation.BOUNCE : google.maps.Animation.DROP}
      />
    </>
  );
}

export default LocationMarker;
